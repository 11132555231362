import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import Button from 'components/buttons/Button'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import reportError from 'lib/reportError'
import Text from 'components/typography/Text'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useDashboard from 'hooks/useDashboard'
import { CheckboxField, ColorField, DateTimeField, DropdownField, TextField } from 'components/contentEditors/generic/fields'
import { useMockFieldProps } from './InputElementView'
import type { ElementType } from 'components/views/AddElementView'

const InputElement = (
  { id, name, identifier, type, size, label, blockId, ...props }:
  { id: string, name: string, identifier: string, type: string, size: 'small' | 'normal' | 'large', label: string, blockId: string }
) => {
  const { createElement } = useDashboard()

  const { inputProps, ...mockFieldProps } = useMockFieldProps(type, identifier, '')
  useComponentDidMount(() => {
    createElement(
      identifier,
      { id,
        name,
        identifier,
        type: 'InputElement',
        blockId,
        properties: { type, size, label, ...props },
        ...inputProps }
    )
  })

  switch (type) {
    case 'text-input':
      return <TextField name={identifier} type="text" size={size} {...props} {...inputProps} {...mockFieldProps} />
    case 'checkbox-input':
      return (
        <CheckboxField
          name={identifier}
          label={label}
          {...mockFieldProps}
          {...props}
          {...inputProps}
        />
      )
    case 'select-input':
      return (
        <DropdownField
          isClearable
          name={identifier}
          size={size}
          {...props}
          {...inputProps}
          {...mockFieldProps}
        />
      )
    case 'date-time-input':
      return (
        <DateTimeField
          isClearable
          name={identifier}
          label={label}
          size={size}
          {...props}
          {...inputProps}
          {...mockFieldProps as any}
        />
      )
    case 'color-input':
      return (
        <ColorField
          isClearable
          name={identifier}
          size={size}
          {...props}
          {...inputProps}
          {...mockFieldProps as any}
        />
      )
    case 'button-group-input':
      return (
        <ButtonGroupInput
          meta={mockFieldProps.meta as any}
          input={mockFieldProps.input as any}
          label={label}
          size={size}
          {...props as any}
        />
      )
    default:
      return (
        <TextField
          isClearable
          name={identifier}
          size={size}
          {...mockFieldProps}
          {...props}
          {...inputProps}
        />
      )
  }
}

const RenderElement = (
  { id, identifier, name, type, properties }:
  {id: string, identifier: string, name: string, type: ElementType, properties: Record<string, any>}
) => {
  const { push } = useHistory()
  const { text, action, url, operation, view, ...props } = properties

  switch (type) {
    case 'TextElement':
      return <Text {...props}>{text}</Text>
    case 'ButtonElement':
      // handle operations
      if (action === 'operation') return <Button {...props} />
      if (action === 'view') return <Button onClick={() => push(view.path)} {...props} />
      if (action === 'url') return <Button href={url} {...props} />
      return <Button {...props} />
    case 'InputElement':
      return <InputElement id={id} identifier={identifier} name={name} {...props as any} />
    default:
      return <></>
  }
}

const ErrorFallback = () => (
  <Text>
    An unexpected error occured, our engineering team is looking into it
  </Text>
)

const GenericElement = (
  { elements }: {
    elements: {
      id: string,
      name: string,
      identifier: string,
      type: ElementType,
      blockId: string,
      properties: Record<string, any>
    }[] }
) => (elements ? (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
    <Form
      onSubmit={() => {}}
      render={() => (
        <>
          {elements.map((element) => <RenderElement {...element} />) }
        </>
      )}
    />
  </ErrorBoundary>
) : null)

export default GenericElement
