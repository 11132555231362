import React, { Ref, useCallback, useImperativeHandle, useState } from 'react'
import type { DraggableProvided } from 'react-beautiful-dnd'
import type { ReactElement, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Block from 'components/blocks/Block'
import Box from 'components/layout/Box'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import useMounted from 'hooks/useMounted'
import useResizeObserver from 'hooks/useResizeObserver'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { BlockProps } from 'components/blocks/Block'

const DRAWER_CONTENT_MARGIN = 20
const DRAWER_CONTENT_PADDING = 40
const DRAWER_CONTENT_PADDING_SMALL = 20
const DRAWER_HEADER_PADDING = 32
const DRAWER_HEADER_PADDING_SMALL = 20
const DRAWER_HEIGHT_CLOSED = 80
const DRAWER_HEIGHT_CLOSED_SMALL = 55

type DrawerBlockRenderProps = {
  closeDrawer: () => void,
  opened: boolean
}

type DrawerHandlerAction = {
  closeDrawer: () => void,
  opened: boolean
}

type BaseDrawerBlockProps = BlockProps & {
  children: (drawerBlockRenderProps: DrawerBlockRenderProps) => ReactNode,
  contentPadding?: 'normal' | 'none' | 'small',
  defaultOpened?: boolean,
  dragHandleProps?: Pick<DraggableProvided, 'dragHandleProps'>,
  drawerAction?: ReactNode,
  handlerRef?: Ref<any>,
  headerPadding?: 'normal' | 'small',
  icon?: string,
  onDrawerOpen?: () => void,
  onDrawerClose?: () => void,
  openedClassName?: string,
  summary?: ReactElement | string,
  title: string,
  subtitle?: string
}

type DrawerBlockProps = BaseDrawerBlockProps & {
  isOpen?: boolean,
  setIsOpened?: (open: boolean) => void
}

type UncontrolledDrawerBlockProps = BaseDrawerBlockProps & {
  defaultOpened: boolean
}

type ControlledDrawerBlockProps = BaseDrawerBlockProps & {
  opened: boolean,
  setOpened: (open: boolean) => void
}

const StyledDrawerIcon = styled(Icon, {
  color: 'dark100'
})

const StyledChevron = styled(Icon, {
  ...mixins.transition('simple'),

  color: 'dark200'
})

const StyledDrawerAction = styled(Box, {
  ...mixins.transition('fluid'),

  color: 'dark100'
})

const StyledSummary = styled(Text, {
  ...mixins.transition('fluid')
})

const StyledSubtitle = styled(Text, {
  ...mixins.transition('fluid'),

  width: 0
})

const StyledTitleWrapper = styled(Flex, {
  variants: {
    opened: {
      true: {
        [`& > ${StyledSubtitle}`]: {
          opacity: 0
        }
      }
    }
  }
})

const StyledHeader = styled(Flex, {
  cursor: 'pointer',
  height: DRAWER_HEIGHT_CLOSED,
  paddingRight: DRAWER_HEADER_PADDING,
  paddingLeft: DRAWER_HEADER_PADDING,
  userSelect: 'none',
  width: '100%',

  [`&:hover ${StyledChevron}, &:focus ${StyledChevron}`]: {
    color: 'dark900'
  },

  [`&:hover ${StyledSummary}, &:focus ${StyledSummary}`]: {
    color: 'dark900'
  },

  [`&:hover ${StyledDrawerAction}, &:focus ${StyledDrawerAction}`]: {
    color: 'dark900'
  },

  variants: {
    headerPadding: {
      normal: {},
      small: {
        paddingLeft: DRAWER_HEADER_PADDING_SMALL,
        paddingRight: DRAWER_HEADER_PADDING_SMALL
      }
    },
    headerHeight: {
      normal: {},
      small: {
        height: DRAWER_HEIGHT_CLOSED_SMALL
      }
    }
  }
})

const StyledDrawer = styled(Box, {
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),
  ...mixins.transition('simple'),

  backgroundColor: 'light100',
  borderRadius: '$6',
  overflow: 'hidden',

  '&:hover': {
    ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.2)
  },

  variants: {
    opened: {
      true: {
        ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

        backgroundColor: 'light400',

        '&:hover': {
          ...mixins.shadow('medium', colorVars.dark600rgb, 0.3)
        },

        [`& > ${StyledHeader} > ${Flex} > ${StyledSummary}`]: {
          opacity: 0
        },

        [`& > ${StyledHeader} > ${Flex} > ${StyledChevron}`]: {
          transform: 'rotate(180deg)'
        }
      }
    }
  }
})

const StyledContent = styled(Flex, {
  ...mixins.transition('simple'),

  paddingLeft: DRAWER_CONTENT_PADDING,
  paddingRight: DRAWER_CONTENT_PADDING,
  visibility: 'hidden',
  variants: {
    opened: {
      true: {
        marginTop: DRAWER_CONTENT_MARGIN,
        marginBottom: DRAWER_CONTENT_PADDING,
        visibility: 'visible'
      }
    },
    contentPadding: {
      none: {
        padding: 0,
        margin: 0
      },
      normal: {},
      small: {
        paddingLeft: DRAWER_CONTENT_PADDING_SMALL,
        paddingRight: DRAWER_CONTENT_PADDING_SMALL
      }
    }
  }
})

const DrawerBlock = ({
  isOpen, setIsOpened, defaultOpened = false, ...props
}: DrawerBlockProps) => {
  if ((typeof isOpen !== 'undefined') && (typeof setIsOpened !== 'undefined')) return <ControlledDrawerBlock opened={isOpen} setOpened={setIsOpened} {...props} />
  return <UnControlledDrawerBlock defaultOpened={defaultOpened} {...props} />
}

const UnControlledDrawerBlock = ({ defaultOpened, ...props }: UncontrolledDrawerBlockProps) => {
  const [ opened, setOpened ] = useState(defaultOpened)

  return <ControlledDrawerBlock opened={opened} setOpened={setOpened} {...props} />
}

const ControlledDrawerBlock = ({
  as = Block,
  children,
  contentPadding = 'normal',
  opened,
  setOpened,
  dragHandleProps,
  drawerAction,
  handlerRef,
  headerHeight = 'normal',
  headerPadding = 'normal',
  icon,
  onDrawerClose,
  onDrawerOpen,
  openedClassName,
  summary,
  title,
  width,
  subtitle,

  ...other
}: ControlledDrawerBlockProps) => {
  const [ drawerContentEl, { height } ] = useResizeObserver<HTMLDivElement>({ paused: !opened })

  const mounted = useMounted(opened)

  const toggleDrawer = () => {
    if (!opened && onDrawerOpen) {
      onDrawerOpen()
    }
    if (opened && onDrawerClose) {
      onDrawerClose()
    }

    setOpened(!opened)
  }

  const closeDrawer = useCallback(() => {
    if (onDrawerClose) {
      onDrawerClose()
    }

    setOpened(false)
  }, [ onDrawerClose, setOpened ])

  useImperativeHandle(handlerRef, () => ({
    closeDrawer,
    opened
  }), [ closeDrawer, opened ])

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleDrawer()
    }
  }

  return (
    <StyledDrawer
      name="Drawer"
      as={as}
      direction="column"
      opened={opened}
      width={{ md: '100%' }}
      className={opened ? openedClassName : ''}
      {...other}
    >

      <StyledHeader
        alignItems="center"
        gap={24}
        headerHeight={headerHeight}
        headerPadding={headerPadding}
        justifyContent="space-between"
        onClick={toggleDrawer}
        onKeyUp={handleKeyUp}
        role="button"
        tabIndex={0}
      >
        <Flex gap={12} alignItems="center" grow={1}>
          {dragHandleProps && <IconButton hideTooltip description="Drag handle" name="drag" variant="dark" {...dragHandleProps} />}

          <Flex gap={24} grow={1}>
            {icon && <StyledDrawerIcon name={icon} />}
            <StyledTitleWrapper gap={12} opened={opened} grow={1}>
              <Text
                color="dark900"
                fontWeight="bold"
                fontSize={14}
              >
                {title}
              </Text>
              {subtitle && (
                <>
                  <Divider orientation="vertical" />
                  <StyledSubtitle
                    color="dark900"
                    fontSize={14}
                    grow={1}
                    truncate
                  >
                    {subtitle}
                  </StyledSubtitle>
                </>
              )}
            </StyledTitleWrapper>
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={24}>
          {summary && (
            <StyledSummary
              as="div"
              color="dark200"
              fontSize={12}
            >
              {summary}
            </StyledSummary>
          )}
          <StyledChevron
            name="arrow-down"
            size={10}
          />
          {drawerAction && (
            <StyledDrawerAction>
              {drawerAction}
            </StyledDrawerAction>
          )}
        </Flex>
      </StyledHeader>

      {mounted && (
        <StyledContent
          style={{ height: opened ? height : 0 }}
          opened={opened}
          contentPadding={contentPadding}
          direction="column"
        >
          <Box ref={drawerContentEl}>
            {children({ closeDrawer, opened })}
          </Box>
        </StyledContent>
      )}
    </StyledDrawer>
  )
}

export default DrawerBlock

export { DRAWER_HEIGHT_CLOSED_SMALL }

export type { DrawerBlockRenderProps, DrawerHandlerAction }
