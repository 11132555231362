import pick from 'lodash/pick'
import React, { useCallback, useMemo, useRef } from 'react'

import { useHistory } from 'react-router-dom'

import BooleanRenderer from 'components/renderers/BooleanRenderer'
import Button from 'components/buttons/Button'
import ColorRenderer from 'components/renderers/ColorRenderer'
import ContentModel, { contentVersionStatusVariants } from 'models/Content'
import DataTableBlock, { DataTableBlockProps } from 'components/blocks/DataTableBlock'
import DateRenderer from 'components/renderers/DateRenderer'
import FieldModel, { contentListRenderableFields, FieldIdentifier } from 'models/Field'
import MediaRenderer from 'components/renderers/MediaRenderer'
import NumberRenderer from 'components/renderers/NumberRenderer'
import TextRenderer from 'components/renderers/TextRenderer'
import TitleBlock from 'components/blocks/TitleBlock'
import useActiveLocales from 'hooks/useActiveLocales'
import useConfirmation from 'hooks/useConfirmation'
import useInfinitePager from 'hooks/useInfinitePager'
import useJumpToView from 'hooks/useJumpToView'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useVersionPublishing, { PublishingAction } from 'hooks/useVersionPublishing'
import { ContentsAggregateDocument, ContentsListDocument, useContentsAggregateQuery, useContentsListQuery, useDestroyContentMutation, useFieldsListQuery, useUpdateContentMutation } from 'generated/schema'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { generateStatusRenderer } from 'components/renderers/StatusRenderer'
import type { Column } from 'components/dataTable/types'
import type { CustomContent, ContentTypeFragmentFragment as ContentTypeFragment } from 'generated/schema'
import type { RowAction } from 'components/dataWidgets/RowActions'
import type { SelectionHandlerProps } from 'components/providers/DataManagerProvider'

type ContentViewProps = {
  name?: string,
  contentType?: ContentTypeFragment,
  isArray?: boolean,
  onContentPick?: (selection: string[]) => void,
  onContentCreate?: () => void,
  sidePaneMode?: boolean
}

const FIELD_TYPE_RENDERER_MAP: Partial<Record<FieldIdentifier, Column['renderer']>> = {
  // TODO:- Add support for more granularity
  [FieldIdentifier.DATE]: (props) => <DateRenderer full {...props} />,
  [FieldIdentifier.MEDIA]: MediaRenderer,
  [FieldIdentifier.FILE]: MediaRenderer,
  [FieldIdentifier.COLOR]: ColorRenderer,
  [FieldIdentifier.SWITCH]: BooleanRenderer,
  [FieldIdentifier.NUMBER]: NumberRenderer,
  [FieldIdentifier.TEXT]: TextRenderer
}

const ContentsListView = ({
  contentType, isArray = false, onContentPick, onContentCreate, sidePaneMode = false, name
}: ContentViewProps) => {
  const [ page, pageSize, handlePageChange ] = usePager({
    initialPageSize: 20
  })

  const selectionHandlerRef = useRef<SelectionHandlerProps>(null)
  const { push } = useHistory()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const contentTypeViewMenuElement = useJumpToView({
    type: 'app',
    id: 'cms',
    componentPath: 'cms/ContentTypesView'
  })

  const { defaultLocale } = useActiveLocales()
  const defaultLocaleIdentifier = defaultLocale?.identifier || ContentModel.fallbackLocale
  const {
    data: { fieldsList = [] } = {},
    loading: fieldsLoading,
    error: fieldsError
  } = useFieldsListQuery({
    variables: {
      filter: {
        contentTypeId: { eq: contentType?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: FIELDS_LIST_LIMIT
    },
    skip: !contentType?.id
  })

  const contentsListVariables = {
    filter: {
      contentTypeId: { eq: contentType?.id }
    },
    page,
    limit: pageSize
  }

  const {
    data,
    loading: contentLoading,
    error: contentError
  } = useContentsListQuery({
    variables: contentsListVariables,
    skip: !contentType?.id
  })

  const {
    data: { contentsAggregate = {} } = {}
  } = useContentsAggregateQuery({
    variables: {
      filter: contentsListVariables.filter
    },
    skip: !contentType?.id
  })

  const [ updateContent ] = useUpdateContentMutation()
  const handleUpdateContent = useSubmitHandler(updateContent)

  const [ contentsList, reorder ] = useInfinitePager({
    data: data?.contentsList,
    page,
    pageSize,
    totalSize: contentsAggregate?.count || 0,
    sortKey: contentType?.orderingStyle === 'BY_LINEAR_DRAG_AND_DROP' ? 'position' : undefined,
    onReorder: handleUpdateContent
  })

  const contentListWithPublishStatus = contentsList.map((content) => content && ({
    ...content,
    status: content?.currentContentVersionId === content?.publishedContentVersionId ? 'Published' : 'Draft'
  }))

  const [ destroyContent ] = useDestroyContentMutation({
    refetchQueries: [
      {
        query: ContentsAggregateDocument, variables: { filter: contentsListVariables.filter }
      }
    ]
  })
  const handleDestroyContent = useSubmitHandler(destroyContent, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyContent',
      typename: 'Content'
    },
    update: {
      strategy: 'REMOVE',
      query: ContentsListDocument,
      dataKey: 'contentsList',
      mutation: 'destroyContent',
      queryVariables: contentsListVariables,
      shouldEvictSingular: true
    }
  })

  const StatusRenderer = generateStatusRenderer<CustomContent>({
    statusVariants: contentVersionStatusVariants
  })

  const openEditContentView = useCallback((content: CustomContent) => {
    push({ search: `id=${content.id}` })
  }, [ push ])

  const openCreateContentView = (content: CustomContent) => push({ search: 'action=create', state: { content: pick(content, 'contentTypeId', 'data') } })

  const dataTableColumns = useMemo(() => {
    const { actualTitleField, potentialTitleField } = FieldModel.getTitleField(
      fieldsList, contentType
    )
    const titleField = actualTitleField || potentialTitleField

    const LinkRenderer = generateLinkRenderer<CustomContent>({ onClick: openEditContentView })

    const columns: Column[] = !sidePaneMode ? fieldsList
      .filter((field) => contentListRenderableFields.includes(field.fieldType as FieldIdentifier))
      .filter((field) => field.id !== titleField?.id)
      .map((field) => ({
        dataKey: `data.${field.id}.${defaultLocaleIdentifier}`,
        title: field.name,
        style: { width: 200 },
        isArray: field.isArray,
        renderer: FIELD_TYPE_RENDERER_MAP[field.fieldType as FieldIdentifier] || TextRenderer
      })) : []

    if (titleField) {
      columns.unshift({
        dataKey: `data.${titleField.id}.${defaultLocaleIdentifier}`,
        title: titleField.name,
        isArray: titleField.isArray,
        renderer: sidePaneMode ? undefined : LinkRenderer,
        style: { width: '25%' }
      })
    }

    columns.push({
      dataKey: 'identifier', title: 'Identifier', style: { width: 200 }
    })

    if (contentType?.isPublishingEnabled) {
      columns.push({
        dataKey: 'status', title: 'Status', style: { flexGrow: 1, justifyContent: 'flex-end' }, renderer: StatusRenderer
      })
    }

    return columns
  }, [
    fieldsList,
    openEditContentView,
    contentType,
    sidePaneMode,
    defaultLocaleIdentifier,
    StatusRenderer
  ])

  const openFieldsListView = () => {
    push({
      pathname: contentTypeViewMenuElement?.fullPath || '',
      state: {
        selectedContentType: contentType
      }
    })
  }

  const openDestroyContentView = (content: CustomContent) => {
    confirm({
      action: 'delete',
      onConfirmClick: () => handleDestroyContent({ id: content.id }),
      recordType: 'Content'
    })
  }

  const [ handlePublishing ] = useVersionPublishing()

  const onPublishingClick = (content: CustomContent) => {
    const isContentPublished = content?.publishedContentVersionId
      === content?.currentContentVersionId

    handlePublishing({
      action: isContentPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      contentId: content.id,
      contentVersionId: content?.currentContentVersionId
    })
  }

  const actions: RowAction<CustomContent>[] = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openEditContentView
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: openDestroyContentView
    },
    {
      icon: 'duplicate',
      title: 'Duplicate',
      onClick: openCreateContentView,
      isSecondary: true
    }
  ]

  if (contentType?.isPublishingEnabled) {
    actions.push({
      icon: (content: CustomContent) => {
        const isContentPublished = content?.publishedContentVersionId
          === content?.currentContentVersionId
        return isContentPublished ? 'cross-circle' : 'publish'
      },
      title: (content: CustomContent) => {
        const isContentPublished = content?.publishedContentVersionId
          === content?.currentContentVersionId
        return isContentPublished ? 'Unpublish' : 'Publish'
      },
      onClick: onPublishingClick,
      isSecondary: true
    })
  }

  const onSelectClick = () => {
    if (selectionHandlerRef?.current) {
      const selection = selectionHandlerRef.current.selection || []
      onContentPick?.(selection as string[])
    }
  }

  const submitButton = (
    <Button label="Edit Content Type" mode="subtle" variant="simple" size="small" onClick={openFieldsListView} />
  )

  const selectButton = <Button label="Select" size="small" onClick={onSelectClick} />
  const createButton = <Button label="Create" size="small" variant="outline" mode="subtle" onClick={onContentCreate} />

  const dataTableBlockProps = (() => {
    if (sidePaneMode) {
      const common = { action: [] }

      if (isArray) {
        return { ...common, selectionMode: 'multiple', secondaryElements: [ createButton, selectButton ] }
      }

      return {
        ...common,
        selectionMode: 'single',
        secondaryElements: createButton,
        onRowSelect: (content: CustomContent) => {
          onContentPick?.([ content.id ])
        }
      }
    }

    return {
      actions,
      selectionMode: 'none',
      secondaryElements: <Button to={{ search: 'action=create' }} icon="add-thin" size="small" />
    }
  })() as unknown as DataTableBlockProps

  return (
    <>
      {!sidePaneMode && (
        <TitleBlock
          heading={name!}
          secondaryElements={contentType?.id && submitButton}
        />
      )}
      <DataTableBlock
        {...dataTableBlockProps}
        columns={dataTableColumns}
        data={(contentType?.isPublishingEnabled
          ? contentListWithPublishStatus
          : contentsList) as any}
        error={contentError || fieldsError}
        fullWidth
        loading={contentLoading || fieldsLoading}
        selectionHandlerRef={selectionHandlerRef}
        onChangePage={handlePageChange}
        onRowDragEnd={
          contentType?.orderingStyle === 'BY_LINEAR_DRAG_AND_DROP'
            ? reorder
            : undefined
        }
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="infinite"
        totalRows={contentsAggregate?.count || 0}
      />
    </>
  )
}

export default ContentsListView
