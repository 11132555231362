import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import React from 'react'
import uniq from 'lodash/uniq'
import uuid from 'uuid-random'

import Card from 'components/card/Card'
import ContentModel from 'models/Content'
import convertToArray from 'lib/convertToArray'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import Text from 'components/typography/Text'
import useDeepMemo from 'hooks/useDeepMemo'
import { useContentsListQuery, useFieldsListQuery } from 'generated/schema'
import type { ComputeDiffWithOptionsProps } from 'components/contentVersion/VersionDiff'
import type { ContentTypeFragmentFragment as ContentTypeFragment, Field } from 'generated/schema'

type Options = {
  field: Field,
  activeLocale: string,
  defaultLocale: string
}

type Reference = {
  id: string
}

type ReferenceFieldProps = {
  options: Options,
  values: Reference[]
}

function ReferenceField({ values, options }: ReferenceFieldProps) {
  const memoizedKeyedValues = useDeepMemo(() => (
    values.map(({ id }) => ({ id, key: uuid() }))
  ), values)

  const { field, activeLocale, defaultLocale } = options || {}

  const contentIds = memoizedKeyedValues.map(({ id }) => id)

  const { data: { contentsList = [] } = {} } = useContentsListQuery({
    variables: {
      filter: {
        id: { in: contentIds }
      }
    },
    fetchPolicy: 'cache-first'
  })
  const contentTypeIds = uniq(
    field.fieldRestrictions
      .map(({ contentTypeId }) => contentTypeId)
      .filter(Boolean)
  )

  const { data: { fieldsList = [] } = {} } = useFieldsListQuery({
    variables: {
      filter: {
        contentTypeId: { in: contentTypeIds }
      }
    },
    skip: !contentTypeIds.length
  })

  if (!contentsList.length || !field || !fieldsList.length) {
    return null
  }

  const contentsListMap = getPropertyToElementMap(contentsList, 'id')
  const contentTypesMap = getPropertyToElementMap(field.fieldRestrictions, 'contentTypeId')
  const fieldsListMap = groupBy(fieldsList, 'contentTypeId')

  const renderReferenceContent = (contentId: string, key: string) => {
    const content = contentsListMap[contentId]
    const { contentType } = contentTypesMap[content?.contentTypeId] || {}
    const contentTypeFieldsList = fieldsListMap[contentType.id] || []

    const title = ContentModel.resolveTitle(
      contentTypeFieldsList,
      content.data,
      contentType as ContentTypeFragment,
      {
        activeLocale,
        defaultLocale
      }
    )

    return (
      <Card key={key} direction="row" gap={12}>
        <Text fontSize={12} fontWeight="bold">
          {contentType?.name}
        </Text>
        {title && (
          <>
            <Flex alignSelf="stretch">
              <Divider orientation="vertical" />
            </Flex>
            <Text fontSize={12}>
              {title}
            </Text>
          </>
        )}
      </Card>
    )
  }

  return (
    <Flex direction="column" gap={12}>
      {memoizedKeyedValues.map(({ id, key }) => renderReferenceContent(id, key))}
    </Flex>
  )
}

ReferenceField.computeDiff = <T extends Reference | Reference[]>({
  previousValue, currentValue, options
}: ComputeDiffWithOptionsProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  return {
    previousNode: <ReferenceField options={options} values={previous} />,
    currentNode: <ReferenceField options={options} values={current} />,
    isDiff: !isEqual(previous, current)
  }
}

export default ReferenceField
