import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay, useDraggable } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { DashboardsListDocument, GenerateMenuElementsInput, useGenerateMenuElementsMutation, useResourcesListQuery } from 'generated/schema'
import { RESOURCES_LIST_LIMIT } from 'models/Resource'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type InitialValues = GenerateMenuElementsInput

type Params = ViewParams[Views.ADD_MENU_RESOURCE]

const AddMenuResourceView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Resource Menu"
        heading="Add Menu"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>
          Provide easy access to Resources by
          {' '}<b>dragging</b> a Resource Menu onto the <b>sidebar</b> or <b>topbar</b>.
        </Text>
        <ResourcesGrid search={search} />
      </DashboardEditorBody>
    </>
  )
}

const DraggableResourceCard = ({ resource, onClick, isActive, isOverlay, mode = 'withArrow' }: any) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: resource.id,
    data: resource
  })

  return (
    <MediaCard
      compact
      onClick={onClick}
      key={resource.id}
      css={{
        opacity: isActive ? 0.5 : 1,
        border: isActive && !isOverlay ? '1px dashed gray' : '1px solid transparent'
      }}
      media={resource.icon || 'graph'}
      title={mode === 'withArrow' ? resource.name : `${resource.workspaceId && !resource.app ? 'Workspace' : resource.app?.name} → ${resource.name}`}
      height={64}
      width="full"
      isDraggable
      actions={[
        {
          description: '',
          icon: 'drag-block',
          isIconAlwaysVisible: true
        },
        ...(mode === 'withArrow' ? [ {
          description: '',
          icon: 'arrow-right',
          isIconAlwaysVisible: true
        } ] : [])
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

const ResourcesGrid = ({ search = '' }: { search: string }) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const {
    data: { resourcesList = [] } = {},
    loading: resourcesListLoading,
    error: resourcesListError
  } = useResourcesListQuery({
    variables: {
      filter: {
        workspaceId: { eq: currentWorkspace?.id },
        isReadOnly: { eq: false }
      },
      limit: RESOURCES_LIST_LIMIT,
      order: [ { createdAt: 'desc' } ]
    }
  })

  const { draggedResourceState, draggingOverMenuIdState } = useDashboard()

  const [ draggedResource, setDraggedResource ] = useRecoilState(draggedResourceState)
  const setDraggingOverMenuId = useSetRecoilState(draggingOverMenuIdState)

  useEffect(() => () => {
    setDraggedResource(null)
  }, [ setDraggedResource ])

  useHotKey({
    Escape: () => {
      setDraggedResource(null)
      setDraggingOverMenuId(null)
    }
  })

  const filteredResources = resourcesList?.filter(
    (resource) => resource.name.toLowerCase().includes(search.toLowerCase())
  ) || []

  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { currentDashboard } = useContext(InternalContext)!

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const { placement = 'SIDE', parentId } = initialValues as InitialValues

  const redirect = useRedirectToMenuElement()

  const dashboardId = currentDashboard?.id

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    onCompleted: (data) => {
      const menuElement = data.generateMenuElements[0]
      if (menuElement) redirect(menuElement)
      else stepBackDashboardEditor()
    },
    refetchQueries: [ { query: DashboardsListDocument } ]
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard, {
    successAlert: { message: 'Successfully added to dashboard.' }
  })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Resources
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={resourcesList}
        loading={resourcesListLoading}
        error={resourcesListError}
      >
        <Flex direction="column" gap={2}>
          {filteredResources.map((resource) => {
            const isActive = draggedResource?.id === resource.id

            const onClick = () => handleAddToDashboard({
              resourceMenuStubs: [ { id: resource.id } ],
              dashboardId,
              parentId,
              ...(parentId ? {} : { placement })
            })

            return (
              <DraggableResourceCard
                key={resource.id}
                resource={resource}
                onClick={onClick}
                isActive={isActive}
                mode="normal"
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
      {createPortal(
        <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
          {draggedResource?.id ? (
            <DraggableResourceCard
              resource={filteredResources.find((r) => r.id === draggedResource.id)}
              isActive
              isOverlay
              mode="normal"
            />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </Flex>
  )
}

export { DraggableResourceCard }

export default AddMenuResourceView
