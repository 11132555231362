import capitalize from 'lodash/capitalize'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import uuid from 'uuid-random'
import { klona } from 'klona'

import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import { parseAndRenderSync } from 'lib/templater'
import type { WorkspaceContextQuery, DashboardsListQuery, DashboardFragmentFragment as DashboardFragment, MenuElementFragmentFragment, MenuElement } from 'generated/schema'
import type { TMap } from 'lib/getPropertyToElementMap'
import parseViewUrn from 'hooks/parseViewUrn'

type ComputedMenuElement = MenuElementFragmentFragment & {
  concatenatedNames?: string,
  fullPath?: string,
  parentIds?: string[],
  viewUrn?: string,
  isVisible?: boolean,
  renderedName?: string,
  renderedIcon?: string
}

type GroupedMenuElementsMap = {
  [key: string]: ComputedMenuElement[]
}

type MenuElementMap = {
  [key: string]: ComputedMenuElement
}

const MENU_ELEMENT_OPTIMISTIC_PROPERTIES: MenuElement = {
  dashboardId: null,
  isRoot: false,
  isRepeated: false,
  isSticky: false,
  parentId: null,
  placement: 'SIDE',
  target: 'VIEW',
  kind: 'ITEM',
  icon: '',
  position: 999999,
  id: null,
  actions: [],
  createdAt: null,
  updatedAt: null,
  viewUrn: '',
  viewStyle: 'MAIN',
  name: '',
  path: '',
  url: '',
  query: ''
}

const WORKSPACE_MENU_ELEMENTS = [
  '',
  'projects',
  'apps',
  'integrations',
  'dashboards',
  'environments',
  'billing',
  ' ',
  '#team',
  'accounts',
  'groups',
  'roles',
  ' ',
  '#advanced',
  'graph',
  'developer',
  '~settings'
]

const PERSONAL_MENU_ELEMENTS = [
  '',
  'profile',
  'notifications',
  '~settings'
]

const WORKSPACE_MENU_ELEMENT_ICONS_MAP: Record<string, string> = {
  projects: 'projects',
  apps: 'apps',
  integrations: 'integration',
  accounts: 'accounts',
  groups: 'groups',
  roles: 'roles',
  dashboards: 'dashboard',
  environments: 'environment',
  billing: 'billing',
  interface: 'interface',
  graph: 'graph',
  developer: 'developer',
  settings: 'settings'
}

const PERSONAL_MENU_ELEMENT_ICONS_MAP: Record<string, string> = {
  profile: 'people',
  notifications: 'notification',
  settings: 'settings'
}

const PERSONAL_DASHBOARD_ID = 'e4354802-af8a-46f4-9c54-6201cb17cfea'
const WORKSPACE_DASHBOARD_ID = '70c3feef-b3de-466a-a8a0-767d4ab61907'
const APPS_MENU_ELEMENT_ID = '40c106b8-6bf9-430a-9a35-f33347246c7b'

const generateAppsMenuElements = (): ComputedMenuElement => ({
  dashboardId: WORKSPACE_DASHBOARD_ID,
  icon: 'apps',
  id: uuid(),
  path: 'apps',
  isRoot: true,
  isRepeated: false,
  isSticky: false,
  isVisible: false,
  parentId: undefined,
  parentIds: [],
  placement: 'SIDE',
  position: 999,
  target: 'VIEW',
  kind: 'ITEM'
})

const generateWorkspaceMenuElements = (): ComputedMenuElement[] => {
  const children = WORKSPACE_MENU_ELEMENTS.map((path, index) => {
    const isSeparator = path === ' ' || path === '-'
    const isSticky = path.startsWith('~')
    const isGroup = path.startsWith('#')
    const parsedPath = path.replace('#', '').replace('~', '')

    const commonElementProperties: ComputedMenuElement = {
      dashboardId: WORKSPACE_DASHBOARD_ID,
      icon: WORKSPACE_MENU_ELEMENT_ICONS_MAP[parsedPath],
      id: path === 'apps' ? APPS_MENU_ELEMENT_ID : uuid(),
      path: parsedPath === '' ? 'workspace' : parsedPath,
      isRoot: true,
      isRepeated: false,
      isVisible: true,
      isSticky,
      parentId: undefined,
      parentIds: [],
      placement: 'SIDE',
      position: index,
      target: 'VIEW',
      kind: 'ITEM'
    }

    if (isGroup) {
      return {
        ...commonElementProperties,

        name: capitalize(parsedPath),
        kind: 'GROUP'
      } as ComputedMenuElement
    }

    if (isSeparator) {
      return {
        ...commonElementProperties,

        kind: 'SEPARATOR',
        separatorStyle: parsedPath === ' ' ? 'WHITESPACE' : 'RULER'
      } as ComputedMenuElement
    }

    return {
      ...commonElementProperties,

      name: parsedPath === '' ? 'Workspace' : capitalize(parsedPath),
      target: 'VIEW',
      path: parsedPath
    } as ComputedMenuElement
  })

  return children
}

const generatePersonalMenuElements = (): ComputedMenuElement[] => {
  const children = PERSONAL_MENU_ELEMENTS.map((path, index) => {
    const isSeparator = path === ' ' || path === '-'
    const isSticky = path.startsWith('~')
    const isGroup = path.startsWith('#')
    const parsedPath = path.replace('#', '').replace('~', '')

    const commonElementProperties: ComputedMenuElement = {
      dashboardId: PERSONAL_DASHBOARD_ID,
      icon: PERSONAL_MENU_ELEMENT_ICONS_MAP[parsedPath],
      id: uuid(),
      path: parsedPath === '' ? 'personal' : parsedPath,
      isRoot: true,
      isRepeated: false,
      isVisible: true,
      isSticky,
      parentId: undefined,
      placement: 'SIDE',
      position: index,
      target: 'VIEW',
      kind: 'ITEM'
    }

    if (isGroup) {
      return {
        ...commonElementProperties,

        name: capitalize(parsedPath),
        kind: 'GROUP'
      } as ComputedMenuElement
    }

    if (isSeparator) {
      return {
        ...commonElementProperties,

        kind: 'SEPARATOR',
        separatorStyle: parsedPath === ' ' ? 'WHITESPACE' : 'RULER'
      } as ComputedMenuElement
    }

    return {
      ...commonElementProperties,

      name: parsedPath === '' ? 'Personal' : capitalize(parsedPath),
      target: 'VIEW',
      path: parsedPath
    } as ComputedMenuElement
  })

  return children
}

const PERSONAL_DASHBOARD: DashboardFragment = {
  id: PERSONAL_DASHBOARD_ID,
  identifier: 'personal',
  menuElements: generatePersonalMenuElements(),
  name: 'Personal',
  position: 1
}

const WORKSPACE_DASHBOARD: DashboardFragment = {
  id: WORKSPACE_DASHBOARD_ID,
  identifier: 'workspace',
  menuElements: generateWorkspaceMenuElements().concat(generateAppsMenuElements()),
  name: 'Workspace',
  position: -1
}

const DEFAULT_SUBMENU_ICON = 'menu-group'
const DEFAULT_VIEW_ICON = 'page-outline'
const DEFAULT_ROOT_ICON = 'app'
const DEFAULT_RESOURCE_ICON = 'graph'

const getDefaultIcon = (menuElement: ComputedMenuElement) => {
  if (menuElement.position === 0 && menuElement.isRoot) {
    return DEFAULT_ROOT_ICON
  } if (menuElement.target === 'SUBMENU') {
    return DEFAULT_SUBMENU_ICON
  } if (menuElement.viewUrn && parseViewUrn(menuElement.viewUrn)?.type === 'resource') {
    return DEFAULT_RESOURCE_ICON
  }
  return DEFAULT_VIEW_ICON
}

const getInjectRecursiveProperties = (idToMenuElementMap: TMap<ComputedMenuElement>, idToDashboardMap: TMap<DashboardsListQuery['dashboardsList'][number]>, currentWorkspace?: WorkspaceContextQuery['workspace']) => {
  // Let's not mutate idToMenuElementMap directly
  const menuElementMap = klona(idToMenuElementMap)

  return function injectRecursiveProperties(menuElement: ComputedMenuElement): ComputedMenuElement {
    const computedMenuElement = menuElementMap[menuElement.id]

    // Parse templated name
    if (menuElement.name) {
      computedMenuElement.renderedName = parseAndRenderSync(menuElement.name, {
        _dashboard: idToDashboardMap[menuElement.dashboardId],
        _workspace: currentWorkspace
      })
    }

    if (!menuElement.target) {
      // Early return
      return menuElement
    }

    // Set default icon for first-level elements
    if (!menuElement.parentId && !menuElement.icon) {
      computedMenuElement.renderedIcon = getDefaultIcon(menuElement)
    } else {
      computedMenuElement.renderedIcon = menuElement.icon
    }

    const parentElement = menuElementMap[menuElement.parentId]
    if (parentElement) {
      // Path - Recursive condition

      // if (parentElement.fullPath) {
      //   computedMenuElement.fullPath = `${parentElement.fullPath}/${menuElement.path}`
      // } else if (menuElement.target !== 'URL') {
      //   computedMenuElement.fullPath = `${injectRecursiveProperties(parentElement)
      // .fullPath}/${menuElement.path}`
      // }

      // Parents - Recursive condition

      if (parentElement.parentIds) {
        computedMenuElement.parentIds = [
          ...parentElement.parentIds!,
          menuElement.id
        ]
      } else {
        computedMenuElement.parentIds = [
          ...injectRecursiveProperties(parentElement).parentIds!,
          menuElement.id
        ]
      }

      // Concatenated names - Recursive condition

      if (parentElement.concatenatedNames) {
        computedMenuElement.concatenatedNames = (
          `${parentElement.concatenatedNames!}/${computedMenuElement.renderedName}`
        )
      } else {
        computedMenuElement.concatenatedNames = (
          `${injectRecursiveProperties(parentElement).concatenatedNames!}/${computedMenuElement.renderedName}`
        )
      }
    } else {
      // Path - Base condition

      // Parents - Base condition
      computedMenuElement.parentIds = [ menuElement.id ]

      // Concatenated names - Base condition
      computedMenuElement.concatenatedNames = computedMenuElement.renderedName
    }

    const { dashboardId } = menuElement
    if (dashboardId) {
      if (idToDashboardMap[dashboardId]) {
        if (menuElement.path) {
          computedMenuElement.fullPath = `/~${idToDashboardMap[dashboardId].identifier}/${menuElement.path}`
        } else if (menuElement.url) {
          computedMenuElement.fullPath = menuElement.url
        } else {
          computedMenuElement.fullPath = `/~${idToDashboardMap[dashboardId].identifier}`
        }
      }
    } else {
      computedMenuElement.fullPath = `/${menuElement.path}`
    }

    return computedMenuElement
  }
}

const generateDashboard = (dashboards: DashboardsListQuery['dashboardsList'] = [], currentWorkspace?: WorkspaceContextQuery['workspace']) => {
  const menuElements = dashboards
    .flatMap((dashboard) => dashboard.menuElements.map((ele) => ({ isVisible: true, ...ele })))

  const idToMenuElementMap = getPropertyToElementMap<ComputedMenuElement>(menuElements as ComputedMenuElement[], 'id')
  const idToDashboardMap = getPropertyToElementMap<DashboardsListQuery['dashboardsList'][number]>(dashboards, 'id')

  const injectRecursiveProperties = getInjectRecursiveProperties(
    idToMenuElementMap,
    idToDashboardMap,
    currentWorkspace
  )

  const allMenuElements = orderBy(menuElements.map((menuElement) => {
    idToMenuElementMap[menuElement.id] = injectRecursiveProperties(
      menuElement as ComputedMenuElement
    )
    return idToMenuElementMap[menuElement.id]
  }), [ 'isSticky', 'position' ])

  const parentIdToMenuElementsMap: GroupedMenuElementsMap = groupBy(allMenuElements, 'parentId')

  return {
    parentIdToMenuElementsMap,
    idToMenuElementMap,
    menuElements: allMenuElements
  }
}

const splitMenuElements = (dashboards: DashboardsListQuery['dashboardsList'] = [], dashboardIdentifier = '', menuElements: ComputedMenuElement[]) => {
  const currentDashboard = dashboards.find(
    (dashboard) => dashboard.identifier === dashboardIdentifier
  )

  const [ sideMenuElements, topMenuElements ] = (
    menuElements.reduce(([ sideElements, topElements ], menuElement) => {
      if ((menuElement.dashboardId === currentDashboard?.id || !menuElement.dashboardId) && menuElement.placement === 'SIDE') {
        sideElements.push(menuElement)
      } else if ((menuElement.dashboardId === currentDashboard?.id || !menuElement.dashboardId) && menuElement.placement === 'TOP') {
        topElements.push(menuElement)
      }
      return [ sideElements, topElements ]
    }, [ [] as typeof menuElements, [] as typeof menuElements ])
  )

  return {
    currentDashboard,
    sideMenuElements,
    topMenuElements
  }
}

const getRedirectionPath = (
  menuElement: MenuElementFragmentFragment,
  currentDashboard: DashboardFragment,
  currentWorkspace: WorkspaceContextQuery['workspace']
) => {
  if (menuElement.target && menuElement.target !== 'URL') {
    const idToDashboardMap = currentDashboard
      ? getPropertyToElementMap<typeof currentDashboard>([ currentDashboard ], 'id')
      : {}

    const idToMenuElementMap = getPropertyToElementMap<typeof menuElement>([ menuElement ], 'id')

    const injectRecursiveProperties = getInjectRecursiveProperties(
      idToMenuElementMap,
      idToDashboardMap,
      currentWorkspace
    )

    const computedMenuElement = injectRecursiveProperties(menuElement)

    return computedMenuElement.fullPath
  }

  return null
}

export {
  generateDashboard,
  getDefaultIcon,
  getInjectRecursiveProperties,
  MENU_ELEMENT_OPTIMISTIC_PROPERTIES,
  splitMenuElements,
  PERSONAL_DASHBOARD,
  WORKSPACE_DASHBOARD,
  APPS_MENU_ELEMENT_ID,
  getRedirectionPath
}

export type { ComputedMenuElement, MenuElementMap, GroupedMenuElementsMap }
