import React from 'react'

import TitleBlock from 'components/blocks/TitleBlock'
import type { TitleBlockProps } from 'components/blocks/TitleBlock/TitleBlock'

const TitleBlockWrapper: React.FC<TitleBlockProps> = (props) => (
  <TitleBlock {...props} />
)

export default TitleBlockWrapper
