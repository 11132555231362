import isEqual from 'lodash/isEqual'
import React from 'react'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import Loader from 'components/loaders/Loader'
import { styled } from 'styles/stitches'
import { useAssetQuery } from 'generated/schema'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type MediaFieldValue = {
  id: string
}

type MediaFieldProps = {
  value: MediaFieldValue[]
}

const size = 110

const StyledWrapper = styled(Flex, {
  size: [ size ],
  background: 'dark100',
  borderRadius: 6
})

const StyledImg = styled('img', {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  objectFit: 'cover'
})

const StyledField = ({ id }: {id: string}) => {
  const { data: { asset } = {}, loading } = useAssetQuery({
    variables: { id },
    skip: !id
  })

  return (
    <StyledWrapper key={id}>
      {loading ? (
        <Flex alignItems="center">
          <Loader loading />
        </Flex>
      ) : (
        <StyledImg src={asset?.url} alt={asset?.name} />
      )}
    </StyledWrapper>
  )
}

function MediaField({ value }: MediaFieldProps) {
  const ids = value.map(({ id }) => id)

  return (
    <Grid gap={10} alignItems="center" css={{ gridTemplateColumns: `repeat(auto-fit, ${size}px)` }}>
      {ids.map((id) => <StyledField id={id} />)}
    </Grid>
  )
}

MediaField.computeDiff = <T extends MediaFieldValue | MediaFieldValue[]>({
  previousValue, currentValue
}: ComputeDiffProps<T>) => ({
    previousNode: <MediaField value={convertToArray(previousValue)} />,
    currentNode: <MediaField value={convertToArray(currentValue)} />,
    isDiff: !isEqual(previousValue, currentValue)
  })

export default MediaField
