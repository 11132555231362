import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import StatBlock, { STAT_BLOCK_WIDTH, StatItemValueKind, defaultStatItem, defaultStatItemPrevious, defaultStatItemDelta } from 'components/blocks/StatBlock'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './useExecuteOperationQuery'
import { parseAndRenderSync } from 'lib/templater'
import type { BlockProps } from 'components/blocks/Block'
import type { ExecuteQueryOperationQuery } from 'generated/schema'
import type { StatBlockDef, StatBlockProps } from 'components/blocks/StatBlock'

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str, ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

const renderStatItem = (
  kind: StatItemValueKind,
  value: string | number,
  data?: ExecuteQueryOperationQuery | null
): number => {
  switch (kind) {
    case StatItemValueKind.FIXED:
      return value as number || 0

    case StatItemValueKind.LIQUID:
      return Number(safeParseLiquid(value as string || '', { record: data?.executeQueryOperation })) || 0

    default:
      return 0
  }
}

const StatBlockWrapper: React.FC<BlockProps & Partial<StatBlockProps>> = ({
  installationId, switcher, block, ...blockProps
}) => {
  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)

  const { properties, layout }: { properties: StatBlockDef, layout: any } = block
  const { width } = layout || { width: STAT_BLOCK_WIDTH }

  const {
    current = defaultStatItem,
    previous = defaultStatItemPrevious,
    delta = defaultStatItemDelta,
    data_source: dataSource,
    data_source_settings: dataSourceSettings = {},
    ...rest
  } = properties

  const {
    operation: operationId,
    parameters: paramProperties
  } = dataSourceSettings

  const { data, loading, error } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: paramProperties,
    targetEnvironment: switcher?.data.environment?.id
  })

  const valueRendered = useMemo(() => {
    if (loading) return 0

    return renderStatItem(current.kind, current.value, data)
  }, [ current.kind, current.value, data, loading ])

  const previousValueRendered = useMemo(() => {
    if (loading) return 0
    if (!previous) return 0

    return renderStatItem(previous.kind, previous.value, data)
  }, [ data, loading, previous ])

  const deltaValueRendered = useMemo(() => {
    if (loading) return 0
    if (!delta) return 0

    return renderStatItem(delta.kind, delta.value, data)
  }, [ data, delta, loading ])

  return (
    <StatBlock
      current={{ ...current, valueRendered }}
      previous={{ ...previous, valueRendered: previousValueRendered }}
      delta={{ ...delta, valueRendered: deltaValueRendered }}
      width={width}
      loading={loading}
      error={error}
      {...blockProps}
      {...rest}
    />
  )
}

export default StatBlockWrapper
