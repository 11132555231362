import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import set from 'lodash/set'
import React, { Suspense, useState } from 'react'

import AddRecordView from 'components/views/graph/AddRecordView'
import BooleanRenderer from 'components/renderers/BooleanRenderer'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import CodeRenderer from 'components/renderers/CodeRenderer'
import ColorRenderer from 'components/renderers/ColorRenderer'
import CurrencyRenderer from 'components/renderers/CurrencyRenderer'
import DataTableBlock from 'components/blocks/DataTableBlock'
import DateRenderer from 'components/renderers/DateRenderer'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import MediaRenderer from 'components/renderers/MediaRenderer'
import NumberRenderer from 'components/renderers/NumberRenderer'
import ReferenceRenderer from 'components/renderers/ReferenceRenderer'
import ResourceDetailsView from 'components/views/graph/ResourceDetailsView'
import SearchBar from 'components/searchbar/SearchBar'
import TextRenderer from 'components/renderers/TextRenderer'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSearchGenericRecords from './useSearchGenericRecords'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Attribute, CustomRecord, Parameter, Resource, InternalSearchRecordsDocument, InternalSummarizeRecordsDocument, useInternalDeleteRecordMutation, useInternalImportRecordsMutation, useOperationsListQuery, useParametersListQuery, useRelationshipsListQuery, useInternalSummarizeRecordsQuery } from 'generated/schema'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { FieldIdentifier } from 'models/Field'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { Kind } from 'models/Relationship'
import { OPERATIONS_LIST_LIMIT, PARAMETERS_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { SIDE_PANE_PADDING_X } from 'components/sidePane/constants'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { DashboardViewComponentProps } from 'components/pages/DashboardPage'
import type { RendererOptions } from 'components/dataList/DataList'

interface Renderer<T extends object> {
  renderer: ({ dataKey, rowData }: RendererOptions<T>) => JSX.Element
}

const ImportRecordsDialog = React.lazy(() => import('./ImportRecordsDialog'))

const FIELD_TYPE_TO_RENDERER_MAP: Record<FieldIdentifier, Renderer<any>> = {
  [FieldIdentifier.TEXT]: { renderer: TextRenderer },
  [FieldIdentifier.NUMBER]: { renderer: NumberRenderer },
  [FieldIdentifier.DATE]: { renderer: DateRenderer },
  [FieldIdentifier.BOOLEAN]: { renderer: BooleanRenderer },
  [FieldIdentifier.MEDIA]: { renderer: MediaRenderer },
  [FieldIdentifier.FILE]: { renderer: MediaRenderer },
  [FieldIdentifier.DROPDOWN]: { renderer: ChipRenderer },
  [FieldIdentifier.CHECKBOX]: { renderer: BooleanRenderer },
  [FieldIdentifier.COLOR]: { renderer: ColorRenderer },
  [FieldIdentifier.CURRENCY]: { renderer: CurrencyRenderer },
  [FieldIdentifier.DURATION]: { renderer: DateRenderer },
  [FieldIdentifier.EMAIL]: { renderer: TextRenderer },
  [FieldIdentifier.JSON]: { renderer: CodeRenderer },
  [FieldIdentifier.LOCATION]: { renderer: TextRenderer },
  [FieldIdentifier.PASSWORD]: { renderer: TextRenderer },
  [FieldIdentifier.PHONE]: { renderer: TextRenderer },
  [FieldIdentifier.RADIO]: { renderer: ChipRenderer },
  [FieldIdentifier.EMBEDDED]: { renderer: TextRenderer },
  [FieldIdentifier.REFERENCE]: { renderer: ReferenceRenderer },
  [FieldIdentifier.RELATIONSHIP]: { renderer: TextRenderer },
  [FieldIdentifier.SLUG]: { renderer: TextRenderer },
  [FieldIdentifier.SWITCH]: { renderer: BooleanRenderer },
  [FieldIdentifier.MARKDOWN]: { renderer: TextRenderer },
  [FieldIdentifier.UID]: { renderer: TextRenderer },
  [FieldIdentifier.CODE]: { renderer: CodeRenderer }
}

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [
  { createdAt: 'desc' }
]

const ImportButton = ({ resource, operationId, targetEnvironment }: any) => {
  const [ isOpen, setOpen ] = useState(false)

  const [ importRecords ] = useInternalImportRecordsMutation({
    refetchQueries: [
      InternalSearchRecordsDocument,
      InternalSummarizeRecordsDocument
    ]
  })

  const handleImportRecords = useSubmitHandler(importRecords)

  const { data } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationId
        }
      },
      limit: PARAMETERS_LIST_LIMIT
    }
  })

  return (
    <Suspense fallback={null}>
      <IconButton
        name="upload"
        description="Import"
        variant="dark"
        size={24}
        onClick={() => setOpen(true)}
      />
      <ImportRecordsDialog
        title="Import"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={({ headers, rows }) => handleImportRecords({
          headers,
          resourceId: resource.id,
          targetEnvironment,
          rows
        })}
        parameters={data?.parametersList as Parameter[]}
      />
    </Suspense>
  )
}

function GenericResourceRecordsList({
  resource, relationshipFilter, initialValues, switcher
}: Pick<DashboardViewComponentProps, 'resource'> & { relationshipFilter?: FilterType, initialValues?: any, switcher?: any }) {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const [ filters, setFilters ] = useState<FilterType>({})
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })
  const resourceId = resource?.id

  const {
    data: { operationsList = [] } = {},
    loading: operationsLoading
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: OPERATIONS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const {
    data: { relationshipsList = [] } = {},
    loading: relationshipsLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const operations = operationsList
  const relationships = relationshipsList
  const environmentId = switcher.data?.environment?.id

  const {
    attributesResult: {
      data: { attributesList: attributes = [] } = {},
      loading: attributesLoading
    },
    searchResult: [ {
      data: { internalSearchRecords: searchRecords = [] } = {},
      loading: listLoading,
      error: listError,
      variables: listQueryVariables
    }, onSearch ]
  } = useSearchGenericRecords({
    resourceId: resource?.id!,
    relationships,
    environmentId,
    page,
    limit: pageSize,
    order,
    filters: {
      ...relationshipFilter,
      ...filters
    },
    skip: !environmentId
  })

  const titleAttribute = attributes.find(
    (attr) => attr.id === resource?.titleAttributeId
  )

  const importOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.behaviorMethod === 'IMPORT')
  const createOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.behaviorMethod === 'CREATE')
  const updateOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.behaviorMethod === 'UPDATE')
  const destroyOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.behaviorMethod === 'DESTROY')

  const createOperationParams = createOperation?.parameters || []
  const updateOperationParams = updateOperation?.parameters || []

  const canImport = !!importOperation
  const canCreate = !!createOperation && createOperationParams.length > 0
  const canUpdate = !!updateOperation && updateOperationParams.length > 0
  const canDestroy = !!destroyOperation
  const canSearch = (attributes.some(
    (attr) => !attr.isArray && attr.fieldType === FieldIdentifier.TEXT
  ) || [])
  const canFilter = attributes.some(
    (attr) => attr.isFilterable
  ) || []

  const {
    data: { internalSummarizeRecords: summarizeRecords } = {}
  } = useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId,
        filter: listQueryVariables?.input.filter,
        targetEnvironment: environmentId
      }
    },
    skip: !environmentId
  })

  const [ deleteRecord ] = useInternalDeleteRecordMutation({
    refetchQueries: [
      InternalSearchRecordsDocument
    ]
  })

  const handleDelete = useSubmitHandler(deleteRecord, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'internalDeleteRecord',
      // need to figure how to get this
      typename: 'CustomRecord'
    },
    update: {
      strategy: 'REMOVE',
      dataKey: 'internalSearchRecords',
      mutation: 'internalDeleteRecord',
      query: InternalSearchRecordsDocument,
      queryVariables: listQueryVariables
    }
  })

  const openAddView = () => openView({
    title: `Add ${resource?.name}`,
    component: AddRecordView,
    params: {
      resourceId,
      initialValues,
      switcher,
      operationId: createOperation!.id,
      title: `Add ${resource?.name}`
    },
    style: 'PANEL'
  })

  const openEditView = (record: CustomRecord) => {
    openView({
      title: `Update ${resource?.name}`,
      component: AddRecordView,
      params: {
        resourceId,
        initialValues: record.data,
        switcher,
        operationId: updateOperation!.id,
        title: `Update ${resource?.name}`
      },
      style: 'PANEL'
    })
  }

  const openDeleteConfirmDialog = (record: any) => {
    const prefix = 'data'
    const currentLocale = 'en_US'

    confirm({
      action: 'delete',
      onConfirmClick: () => {
        const formParams = {}

        const requiredParams = destroyOperation?.parameters?.map(
          (param) => {
            const paramName = `${prefix}.${camelCase(param.identifier)}.${currentLocale}`
            const paramValue = get(record, paramName)
            if (paramValue) {
              return set(formParams, camelCase(param.identifier), paramValue)
            }
            return null
          }
        ).reduce((acc, curr) => ({ ...acc, ...curr }), {})

        return handleDelete({
          resourceId,
          targetEnvironment: environmentId,
          arguments: requiredParams
        })
      },
      recordDescription: titleAttribute?.identifier
        ? `${get(record, `${prefix}.${titleAttribute?.identifier}.${currentLocale}`)}`
        : `${get(record, `${prefix}.id.${currentLocale}`)}`
    })
  }

  const columns: Column[] = orderBy(
    attributes, 'position'
  )
    .map((attr) => {
      const renderer = FIELD_TYPE_TO_RENDERER_MAP[attr.fieldType as FieldIdentifier]

      const relationship = relationships.find((rel) => (
        rel.sourceId === resourceId && rel.sourceAttributeId === attr.id
      ))

      const oneToOneRelationship = relationships.find(
        (rel) => rel.sourceAttributeId === attr.id && rel.kind === (Kind.BELONGS_TO || Kind.HAS_ONE)
      )

      const OneToOneReferenceRenderer = ({
        rowData,
        dataKey,
        ...props
      }: RendererOptions) => {
        const titleAttribute = oneToOneRelationship?.target.attributes.find(
          (attr) => oneToOneRelationship?.target.titleAttributeId === attr.id
        )

        const LinkRenderer = generateLinkRenderer({
          as: TextRenderer,
          onClick: () => {
            const title = titleAttribute
              ? get(rowData, `data.${camelCase(oneToOneRelationship?.target.identifier)}.data.${camelCase(titleAttribute?.identifier)}.en_US`)
              : oneToOneRelationship!.target.name
            const key = camelCase(oneToOneRelationship?.sourceAttribute.identifier || '')

            openView({
              title,
              component: ResourceDetailsView,
              style: ResourceDetailsView.defaultStyle,
              params: {
                title,
                recordId: rowData?.data[key]?.en_US || rowData?.data[key]?.data?.id.en_US,
                switcher,
                resourceId: oneToOneRelationship?.targetId,
                prefix: 'data',
                suffix: 'en_US'
              }
            })
          }
        })

        return (
          <LinkRenderer
            {...props}
            // eslint-disable-next-line no-nested-ternary
            dataKey={titleAttribute ? camelCase(titleAttribute.identifier)
              : relationship ? oneToOneRelationship?.targetAttribute.identifier || dataKey
                : dataKey}
            rowData={rowData}
            prefix={`data.${camelCase(oneToOneRelationship?.target.identifier)}.data`}
            suffix="en_US"
          />
        )
      }

      const isTitleAttribute = attr.id === resource?.titleAttributeId
      const TitleRenderer = ({
        rowData,
        ...props
      }: RendererOptions) => {
        const LinkRenderer = generateLinkRenderer({
          as: TextRenderer,
          onClick: () => {
            const title = titleAttribute
              ? get(rowData, `data.${camelCase(titleAttribute.identifier)}.en_US`)
              : `${resource?.name} Details` || 'Resource Details'

            openView({
              title,
              component: ResourceDetailsView,
              style: ResourceDetailsView.defaultStyle,
              params: {
                title,
                recordId: rowData?.data.id.en_US,
                resourceId: resource?.id!,
                switcher,
                prefix: 'data',
                suffix: 'en_US'
              }
            })
          }
        })

        return <LinkRenderer rowData={rowData} {...props} />
      }

      const rendererProp = {
      // eslint-disable-next-line no-nested-ternary
        renderer: isTitleAttribute
          ? TitleRenderer
        // eslint-disable-next-line no-nested-ternary
          : relationship?.kind === (Kind.BELONGS_TO || Kind.HAS_ONE)
            ? OneToOneReferenceRenderer
            : (props: RendererOptions) => (
              renderer?.renderer
                ? renderer.renderer(props)
                : <TextRenderer {...props} prefix="data" suffix="en_US" />
            )
      }

      return ({
        dataKey: camelCase(attr.identifier),
        prefix: 'data',
        suffix: 'en_US',
        title: attr.fieldType === FieldIdentifier.REFERENCE
          ? (relationship?.target.name || attr.name) : attr.name,
        sortable: attr.isOrderable,
        hidden: false,
        fieldType: attr.fieldType,
        attributeProps: {
          resource: (relationship ? relationship?.target : resource) as Resource,
          attributes: (relationship ? relationship?.target.attributes : attributes) as Attribute[]
        },
        fieldProps: attr.dataType?.settings || {},
        displayTypeSettings: attr.displayTypeSettings || {},
        style: { width: '200px' },
        isArray: attr.isArray,
        ...rendererProp
      })
    }) || []

  const actions = []

  if (canUpdate) {
    actions.push({
      icon: 'edit',
      title: 'Edit',
      onClick: openEditView
    })
  }

  if (canDestroy) {
    actions.push({
      icon: 'trash',
      title: 'Delete',
      onClick: openDeleteConfirmDialog
    })
  }

  const filterProps = {
    filters,
    setFilters
  }

  const primaryElements = (
    <Flex gap={8}>
      {canSearch && <SearchBar placeholder="Search..." loading={listLoading} onChange={(e) => onSearch(e.target.value)} />}
    </Flex>
  )
  const secondaryElements = (
    <>
      {canImport && (
        <ImportButton
          resource={resource}
          operationId={createOperation!.id}
          targetEnvironment={environmentId}
        />
      )}
      {canCreate && <Button icon="add-thin" onClick={openAddView} size="small" />}
    </>
  )

  return (
    <DataTableBlock
      fullWidth
      actions={actions}
      columns={columns}
      loading={
        listLoading
        || attributesLoading
        || operationsLoading
        || relationshipsLoading
      }
      error={listError}
      data={searchRecords as any[]}
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      primaryElements={primaryElements}
      secondaryElements={secondaryElements}
      page={page}
      pageSize={pageSize}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      paginationMode="finite"
      totalRows={summarizeRecords?.count || 0}
      defaultOrder={order}
      setOrder={setOrder}
      {...(canFilter && filterProps)}
      {...(relationshipFilter && { asFragment: true, containerPadding: SIDE_PANE_PADDING_X })}
    />
  )
}

export default GenericResourceRecordsList

export { ImportButton }
