import React from 'react'
import isObject from 'lodash/isObject'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import ToggleInput from 'components/inputs/ToggleInput'
import Text from 'components/typography/Text'
import type { Attribute } from 'generated/schema'
import Card from 'components/card/Card'
import Label from 'components/typography/Label'
import Flex from 'components/layout/Flex'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const render = (data: any, label?: any): any => {
  if (Array.isArray(data)) {
    return data.map(render)
  }

  if (isObject(data)) {
    // @ts-ignore
    const embeddedData = data.data || data.en_US
    return embeddedData ? render(embeddedData, label) : (
      <Card direction="column" gap={16}>
        {Object.entries(data).map(([ key, value ]) => render(value, key))}
      </Card>
    )
  }

  return (
    <Flex direction="column" gap={10}>
      <Label>{label}</Label>
      <Text fontSize={14} fontWeight="bold">{data ?? '-'}</Text>
    </Flex>
  )
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings

  return render(data)
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}collapsible`}
      component={ToggleInput}
      label="Collapsible?"
      type="checkbox"
    />
    <FormField
      name={`${fieldPrefix}show_container`}
      component={ToggleInput}
      label="Show container?"
      type="checkbox"
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as EmbeddedRenderer }
