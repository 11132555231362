import React, { memo, useEffect, useRef } from 'react'
import type { ElementType, MouseEvent, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import scrollIntoViewIfNeeded from 'lib/scrollIntoViewIfNeeded'
import Text from 'components/typography/Text'
import { POPOVER_BORDER_RADIUS, usePopoverContext, LABEL_ATTRIBUTE, TEXT_ATTRIBUTE } from 'components/popover/Popover'
import { styled } from 'styles/stitches'
import type { BaseLinkProps } from 'components/links/BaseLink'
import type { Color } from 'styles/theme'
import type { FlexProps } from 'components/layout/Flex'
import type { IconProps } from 'components/icons/Icon'

const POPOVER_ITEM_ACTIVE_BORDER_WIDTH = 2
const POPOVER_ITEM_LEFT_PADDING = 15
const POPOVER_ITEM_RIGHT_PADDING = 25
const POPOVER_ITEM_HORIZONTAL_PADDING = 25
const POPOVER_ITEM_VERTICAL_PADDING = 15

const POPOVER_ITEM_ICON_COLORS: ColorMap = {
  normal: 'dark100',
  hover: 'primary300'
}

const POPOVER_ITEM_TEXT_COLORS: ColorMap = {
  normal: 'dark900',
  hover: 'primary300',
  negative: 'negative500'
}

const POPOVER_ITEM_LABEL_COLORS: ColorMap = {
  normal: 'dark500',
  hover: 'primary300'
}

const StyledPopoverItemIcon = styled(Icon, {
  color: POPOVER_ITEM_ICON_COLORS.normal
})

const StyledPopoverItemText = styled(Text, {
  color: POPOVER_ITEM_TEXT_COLORS.normal
})

const StyledPopoverItemLabel = styled(Text, {
  color: POPOVER_ITEM_LABEL_COLORS.normal
})

const StyledPopoverItem = styled(Flex, {
  ...mixins.transition('simple'),

  whiteSpace: 'nowrap',
  flexGrow: 1,
  paddingY: POPOVER_ITEM_VERTICAL_PADDING,

  '&:hover, &:focus': {
    backgroundColor: 'light400',
    cursor: 'pointer',

    '&:first-child': {
      borderTopRightRadius: POPOVER_BORDER_RADIUS,
      borderTopLeftRadius: POPOVER_BORDER_RADIUS
    },

    '&:last-child': {
      borderBottomRightRadius: POPOVER_BORDER_RADIUS,
      borderBottomLeftRadius: POPOVER_BORDER_RADIUS
    },

    [`${StyledPopoverItemIcon}`]: {
      color: POPOVER_ITEM_ICON_COLORS.hover
    },

    [`${StyledPopoverItemText}`]: {
      color: POPOVER_ITEM_TEXT_COLORS.hover
    },

    [`${StyledPopoverItemLabel}`]: {
      color: POPOVER_ITEM_LABEL_COLORS.hover
    }
  },
  variants: {
    hasIcon: {
      true: {
        paddingLeft: POPOVER_ITEM_LEFT_PADDING,
        paddingRight: POPOVER_ITEM_RIGHT_PADDING
      },
      false: {
        paddingX: POPOVER_ITEM_HORIZONTAL_PADDING
      }
    },
    active: {
      true: {
        borderLeftColor: 'primary300',
        borderLeftStyle: 'solid',
        borderLeftWidth: POPOVER_ITEM_ACTIVE_BORDER_WIDTH,

        '&:first-child': {
          borderTopLeftRadius: POPOVER_BORDER_RADIUS
        },

        '&:last-child': {
          borderBottomLeftRadius: POPOVER_BORDER_RADIUS
        }
      }
    },
    negative: {
      true: {
        [`${StyledPopoverItemText}`]: {
          color: POPOVER_ITEM_TEXT_COLORS.negative
        },

        '&:hover, &:focus': {
          [`${StyledPopoverItemText}`]: {
            color: POPOVER_ITEM_TEXT_COLORS.negative
          }
        }
      }
    }
  }
})

const DEFAULT_ELEMENT: PopoverItemElement = 'button'

type ColorMap = { [key: string]: Color }

type PopoverItemElement = typeof BaseLink | ElementType

type PopoverItemOwnProps = {
  className?: string,
  children?: ReactNode,
  icon?: string | ReactNode,
  forceActive?: boolean,
  label?: string,
  scrollActiveIntoView?: boolean,
  focusActive?: boolean,
  text?: string,
  size?: 'small' | 'normal',
  iconProps?: Partial<Omit<IconProps, 'css'>>
}

type PopoverItemProps =
  | FlexProps & BaseLinkProps & PopoverItemOwnProps
  | FlexProps & PopoverItemOwnProps

function PopoverItem({
  as,
  className,
  children,
  icon,
  forceActive = false,
  href,
  label,
  onClick,
  scrollActiveIntoView = false,
  focusActive = false,
  negative = false,
  text,
  to,
  iconProps,
  size = 'normal',
  ...others
}: PopoverItemProps) {
  const { closePopover } = usePopoverContext()

  const itemRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (forceActive && itemRef?.current) {
      if (focusActive) {
        itemRef.current.focus()
      }

      if (scrollActiveIntoView) {
        scrollIntoViewIfNeeded(itemRef.current)
      }
    }
  }, [ forceActive, scrollActiveIntoView, focusActive ])

  const elementProps = (href || to) ? { as: BaseLink, href, to } : { as: as || DEFAULT_ELEMENT }

  return (
    <StyledPopoverItem
      size={size}
      active={forceActive}
      className={className}
      alignItems="center"
      gap={14}
      hasIcon={!!icon}
      justifyContent="space-between"
      onClick={(e: MouseEvent<any>) => {
        onClick?.(e)
        closePopover?.()
      }}
      ref={itemRef}
      role="menuitem"
      tabIndex={-1}
      negative={negative}
      {...{
        [LABEL_ATTRIBUTE]: label?.toLocaleLowerCase(),
        [TEXT_ATTRIBUTE]: text?.toLocaleLowerCase()
      }}
      {...elementProps}
      {...others}
    >
      <Flex gap={14} alignItems="center">
        {typeof icon === 'string' ? (
          <StyledPopoverItemIcon name={icon} size={size === 'small' ? 16 : 24} {...iconProps} />
        ) : icon}
        <StyledPopoverItemText fontSize={size === 'small' ? 13 : 14} fontWeight="semibold" letterSpacing="condensed">
          {text || children}
        </StyledPopoverItemText>
      </Flex>
      {label && (
        <StyledPopoverItemLabel fontSize={12} fontWeight="semibold" letterSpacing="condensed">
          {label}
        </StyledPopoverItemLabel>
      )}
    </StyledPopoverItem>
  )
}

export default memo(PopoverItem)

export { POPOVER_ITEM_ICON_COLORS, POPOVER_ITEM_TEXT_COLORS, StyledPopoverItem }

export type { PopoverItemProps }
