import orderBy from 'lodash/orderBy'
import React, { Fragment, memo, useContext, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { DragOverlay } from '@dnd-kit/core'
import { createPortal } from 'react-dom'
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import AddTopbarItem from 'components/topbar/AddTopbarItem'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import GenericMenuElement from 'components/menuelements/GenericMenuElement'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import MenuElementPositionProvider from 'components/contexts/MenuElementPositionContext'
import useDashboard from 'hooks/useDashboard'
import { css } from 'styles/stitches'
import { TOPBAR_ITEM_SPACING_X } from 'components/topbar/TopbarItem'
import { Views } from 'components/dashboardEditor/constants'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { MenuElementKind } from 'generated/schema'

type TopbarElementsProps = {
  isTopbarActive?: boolean,
  isTopbarHovered?: boolean,
  isTopbarLocked?: boolean,
  menuElements: readonly ComputedMenuElement[]
}

type DraggableTopbarElementsProps = {
  menuElements: ComputedMenuElement[]
}

const classes = {
  menuElements: (isFilled: boolean) => css({
    paddingX: isFilled && TOPBAR_ITEM_SPACING_X
  }),
  placeholder: css({
    flexGrow: 1,
    height: 'inherit',
    paddingX: 0,
    paddingY: 15,
    position: 'relative',
    zIndex: 'below',

    '&::before': {
      borderColor: 'light700',
      borderRadius: 4,
      borderStyle: 'dashed',
      borderWidth: 2,
      content: "''",
      height: 'calc(100% - 30px)',
      position: 'absolute',
      width: '100%'
    }
  })
}

const Dropzone = ({ id, index }: any) => {
  const { idToMenuElementMap } = useContext(InternalContext)!
  const { draggingOverMenuIdState } = useDashboard()
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggingOverMenuElement = draggingOverMenuId && idToMenuElementMap[draggingOverMenuId]

  const isDraggingAccrossDroppables = draggedMenu?.parentId !== draggingOverMenuElement?.parentId
  || draggedMenu?.placement !== draggingOverMenuElement?.placement

  const isAddingMenuElement = draggedMenu && !draggedMenu.id
  const isAddingApp = draggedApp?.id
  const isDraggingResource = draggedResource?.id

  if (!(
    (isAddingMenuElement && index !== 0)
    || (draggedMenu?.id && isDraggingAccrossDroppables)
    || isAddingApp
    || isDraggingResource
  )) {
    return null
  }

  return (
    <Flex
      justifyContent="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: '100%',
        width: 32,
        marginLeft: -16,
        marginRight: -16,
        opacity: draggingOverMenuId === id ? 1 : 0,

        '&:hover': {
          opacity: draggingOverMenuId ? undefined : 1
        }
      }}
    >
      <Flex
        css={{
          height: '100%',
          borderRight: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

// TODO: All elements need to be rendered. Remove these when the design is available
const renderableMenuElementKinds: readonly MenuElementKind[] = [ 'ITEM', 'SEPARATOR' ]

const Overlay = () => {
  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  return createPortal(
    <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
      {draggedMenu?.id ? (
        <GenericMenuElement menuElement={draggedMenu} isDragging />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const DraggableTopbarElements = memo(({
  menuElements
}: DraggableTopbarElementsProps) => (
  <SortableContext
    id="TOPBAR_SORTABLE"
    items={menuElements.map((menuElement) => menuElement.id)}
    strategy={horizontalListSortingStrategy}
  >
    {menuElements.map((menuElement, index) => (
      <Fragment key={menuElement.id}>
        <Dropzone id={menuElement.id} index={index} />
        <GenericMenuElement
          alignItems="stretch"
          key={menuElement.id}
          menuElement={menuElement}
        />
      </Fragment>
    ))}
    <Overlay />
  </SortableContext>
))

function TopbarElements({
  isTopbarActive = false,
  isTopbarHovered = false,
  isTopbarLocked = false,
  menuElements
}: TopbarElementsProps) {
  const { idToMenuElementMap } = useContext(InternalContext)!

  const { dashboardEditorState } = useDashboard()
  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const renderableMenuElements = useMemo(() => (
    menuElements.filter((element) => (
      renderableMenuElementKinds.includes(element.kind) && !element.parentId
    ))
  ), [ menuElements ])

  const orderedMenuElements = useMemo(() => (
    orderBy(renderableMenuElements, 'position', 'desc')
  ), [ renderableMenuElements ])

  const isDraggingOverWithMenuGroup = (draggableId?: string) => draggableId && idToMenuElementMap[draggableId]?.kind === 'GROUP'
  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState
  } = useDashboard()

  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggedElementId = draggedMenu?.id || draggedApp?.id || draggedResource?.id

  return (
    <MenuElementPositionProvider
      nonStickyElements={orderedMenuElements}
      stickyElements={orderedMenuElements}
    >
      <Flex
        style={{
          cursor: isDraggingOverWithMenuGroup(draggedElementId) ? 'no-drop' : undefined
        }}
      >
        {!isTopbarLocked && !isInspecting && (
          <AddTopbarItem isVisible={isTopbarActive || isTopbarHovered} />
        )}

        {!!orderedMenuElements.length && <Divider orientation="vertical" />}

        <Flex alignItems="stretch" className={classes.menuElements(!!orderedMenuElements.length)}>
          <DraggableTopbarElements menuElements={orderedMenuElements} />
        </Flex>

        <Divider orientation="vertical" />
      </Flex>
    </MenuElementPositionProvider>
  )
}

export default memo(TopbarElements)
