import React, { useState, useEffect } from 'react'
import type { CSSProperties, MouseEvent } from 'react'

import * as mixins from 'styles/mixins'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import { css, styled } from 'styles/stitches'

const ROW_ACTION_BUTTON_PADDING = 6
const ROW_ACTION_BUTTON_SIZE = 16
const ROW_ACTION_DIVIDER_MARGIN_X = 10
const ROW_ACTION_DIVIDER_MAX_HEIGHT = 50

const ROW_ACTIONS_BORDER_RADIUS = 6
const ROW_ACTIONS_MARGIN_Y = 15
const ROW_ACTIONS_TOGGLE_WIDTH = 24

type RowActionsProps = {
  actions: RowAction[],
  children?: React.ReactNode,
  isHovered: boolean,
  record: any,
  style?: CSSProperties,
  offset?: number
}

type RowAction<T = any> = {
  icon: string | ((record: T) => string),
  title: string | ((record: T) => string),
  isSecondary?: boolean,
  onClick?: (record: T, e: React.FormEvent<any>) => void,
  visibilityFilter?: (arg0: T) => boolean
}

const getVisibleActions = ({ actions, record }: Pick<RowActionsProps, 'actions' | 'record'>) => {
  const primaryActions = [] as RowAction[]
  const secondaryActions = [] as RowAction[]

  actions.forEach((action) => {
    const { visibilityFilter } = action

    if (!visibilityFilter || visibilityFilter(record)) {
      if (action.isSecondary) {
        secondaryActions.push(action)
      } else {
        primaryActions.push(action)
      }
    }
  })

  const visibleActions = [ ...primaryActions, ...secondaryActions ]

  return {
    primaryActions,
    secondaryActions,
    visibleActions
  }
}

const getDimensions = (
  { actions, record }: Pick<RowActionsProps, 'actions' | 'record'>
) => {
  const { primaryActions, visibleActions } = getVisibleActions({ actions, record })
  const hasSecondaryAction = actions.some((action) => action.isSecondary)

  const hoveredWidth = (
    (ROW_ACTION_BUTTON_SIZE * primaryActions.length)
    + (2 * ROW_ACTION_BUTTON_PADDING * primaryActions.length)
    + (2 * ROW_ACTIONS_MARGIN_Y)
    + (hasSecondaryAction ? ROW_ACTIONS_TOGGLE_WIDTH : 0)
  )

  const expandedWidth = (
    (ROW_ACTION_BUTTON_SIZE * visibleActions.length)
    + (2 * ROW_ACTION_BUTTON_PADDING * visibleActions.length)
    + (2 * ROW_ACTIONS_MARGIN_Y)
    + (hasSecondaryAction ? ROW_ACTIONS_TOGGLE_WIDTH : 0)
  )

  return {
    hoveredWidth,
    expandedWidth
  }
}

const divider = css({
  maxHeight: ROW_ACTION_DIVIDER_MAX_HEIGHT,
  marginBottom: ROW_ACTION_DIVIDER_MARGIN_X,
  marginLeft: ROW_ACTIONS_MARGIN_Y,
  marginTop: ROW_ACTION_DIVIDER_MARGIN_X
})

const RowActionWrapper = styled(Flex, {
  ...mixins.transition('simple'),

  height: '100%',
  overflow: 'hidden',
  flexShrink: 0,

  variants: {
    hovered: {
      true: {
        justifyContent: 'flex-end'
      }
    }
  }
})

const ToggleButton = styled(Flex, {
  ...mixins.transition('simple'),

  bottom: 0,
  cursor: 'pointer',
  marginRight: ROW_ACTIONS_MARGIN_Y,
  position: 'absolute',
  right: 0,
  top: 0,
  width: ROW_ACTIONS_TOGGLE_WIDTH,

  '&:hover [data-icon], &:focus [data-icon]': {
    color: 'dark700'
  },

  '& [data-icon]': {
    ...mixins.transition('fluid'),

    cursor: 'pointer',
    color: 'dark100'
  },

  variants: {
    expanded: {
      true: {
        '&:hover [data-icon], &:focus [data-icon]': {
          color: 'light100',
          opacity: 1
        }
      }
    }
  }
})

const StyledRowActions = styled('div', {
  ...mixins.transition('simple', 'width transform'),

  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: ROW_ACTIONS_BORDER_RADIUS,
  opacity: 0,

  '& [data-row-actions-inner]': {
    alignItems: 'center',
    display: 'flex',
    marginLeft: ROW_ACTIONS_MARGIN_Y
  },

  variants: {
    hovered: {
      true: {
        opacity: 1,
        transform: 'translateX(0)',

        [`& ${ToggleButton}`]: {
          transform: 'translateX(0)'
        }
      }
    },
    expanded: {
      true: {
        backgroundColor: 'dark200',
        opacity: 1
      }
    }
  }
})

const getRowActionClass = (
  expandedWidth: number,
  hoveredWidth: number,
  isExpanded: boolean,
  isHovered: boolean
) => {
  let transform
  if (!isHovered) {
    transform = `translateX(${hoveredWidth}px)`
  }

  let width
  if (isExpanded) {
    width = expandedWidth
  } else if (isHovered) {
    width = hoveredWidth
  } else {
    width = 0
  }

  return css({ transform, width })
}

const getRowActionInnerClass = (isHovered: boolean, hoveredWidth: number) => css({
  transform: !isHovered ? `translateX(${-hoveredWidth}px)` : undefined
})

function RowActions({
  actions = [],
  children,
  isHovered = false,
  record,
  style,
  offset
}: RowActionsProps) {
  const [ isExpanded, setIsExpanded ] = useState(false)

  const { hoveredWidth, expandedWidth } = getDimensions({ actions, record })
  const { primaryActions, secondaryActions } = getVisibleActions({ actions, record })

  const toggle = () => setIsExpanded(!isExpanded)

  const collapse = () => setIsExpanded(false)

  const hasActions = actions.length > 0
    && actions.filter((action) => action.visibilityFilter?.(record)).length > 0

  const renderActionButton = (action: RowAction) => (
    <IconButton
      description={typeof action.title === 'function' ? action.title(record) : action.title}
      variant={isExpanded ? 'light' : 'dark'}
      key={typeof action.title === 'function' ? action.title(record) : action.title}
      name={typeof action.icon === 'function' ? action.icon(record) : action.icon}
      size={ROW_ACTION_BUTTON_SIZE}
      disabled={!action.onClick}
      {...(action.onClick && {
        onClick: (e: MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          action.onClick!(record, e)
        }
      })}
    />
  )

  const handleToggleButtonClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    toggle()
  }

  useEffect(() => {
    if (!isHovered) {
      const timeout = setTimeout(collapse, 300)

      return () => clearTimeout(timeout)
    }

    return undefined
  }, [ isHovered ])

  return (
    <>
      {isExpanded && (
        <div
          style={{
            width: hoveredWidth
          }}
        />
      )}
      <RowActionWrapper
        hovered={isHovered}
        alignItems="stretch"
        style={style}
        css={{
          ...(offset && isHovered && { marginRight: -offset }),
          ...(isExpanded && { position: 'absolute', right: offset })
        } as any}
      >
        {children}

        {isHovered && hasActions && (
          <Flex alignSelf="stretch" className={divider}>
            <Divider orientation="vertical" />
          </Flex>
        )}

        <StyledRowActions
          className={getRowActionClass(expandedWidth, hoveredWidth, isExpanded, isHovered)}
          expanded={isExpanded}
          hovered={isHovered}
          onMouseLeave={collapse}
        >
          <div data-row-actions-inner>
            {primaryActions.map(renderActionButton)}
            {isExpanded && secondaryActions.map(renderActionButton)}
          </div>

          {secondaryActions.length > 0 && (
            <ToggleButton
              as="button"
              aria-label="More Actions"
              alignItems="center"
              className={getRowActionInnerClass(isHovered, hoveredWidth)}
              expanded={isExpanded}
              justifyContent="center"
              onClick={handleToggleButtonClick}
              role="button"
              tabIndex={0}
            >
              <Icon
                data-icon
                name="vertical-ellipsis"
                size={ROW_ACTION_BUTTON_SIZE}
              />
            </ToggleButton>
          )}
        </StyledRowActions>
      </RowActionWrapper>
    </>
  )
}

export default RowActions

export type { RowActionsProps, RowAction }
