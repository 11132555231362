import React, { useState } from 'react'

import AppPage from 'components/pages/AppPage'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CreateAppView from 'components/views/CreateAppView'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import SearchBar from 'components/searchbar/SearchBar'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import { App, Installation, useAppListQuery, useInstallationsListQuery } from 'generated/schema'
import { AppCards, APP_LIST_LIMIT } from './AppsPage'
import { useViewDispatch } from 'hooks/useViewContext'

type AppsProps = {apps: App[], installations?: Installation[]}

const AppsGrid = ({ apps, installations }: AppsProps) => (
  <Flex direction="column" gap={36}>
    <Flex
      direction="column"
      gap={14}
    >
      <Flex
        direction="column"
      >

        <AppCards
          appList={apps}
          installations={installations || []}
        />

      </Flex>
      <Divider variant="whitespace" spacing="4" />
    </Flex>
  </Flex>
)

function ProjectsPage() {
  const queryParams = useQueryParams()
  const id = queryParams.get('id')

  const {
    data: { installationsList } = {},
    error: installationsListError,
    loading: installationsListLoading
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        or: [
          { appKind: { eq: 'PROJECT' } }
        ]
      }
    }
  })

  const {
    data: { appsList } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: 'PROJECT' } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })
  const [ searchText, setSearchText ] = useState('')
  const { openView } = useViewDispatch()

  const onCreateApp = () => openView({
    title: 'New Project',
    component: CreateAppView,
    params: {
      initialValues: {
        name: '',
        identifier: '',
        icon: 'app-custom',
        kind: 'PROJECT'
      },
      queryVariables: {
        limit: APP_LIST_LIMIT,
        order: [ { position: 'asc' } ]
      }
    },
    style: 'PANEL'
  })

  const filteredApps = appsList?.filter(
    (app) => app.name.toLowerCase().includes(searchText.toLowerCase())
  )

  if (id) return <AppPage />

  const data = installationsList && appsList
  const loading = installationsListLoading || appsListLoading
  const error = installationsListError || appsListError

  return (
    <>
      <TitleBlock heading="Projects" />
      <Block direction="column" width={{ md: '100%' }}>
        <HintBox>
          Projects can be a website, mobile app, or even a backend server.
          {' '}
          <TextLink href="https://docs.dashx.com/platform/projects">Learn more</TextLink>.
        </HintBox>
      </Block>
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Flex justifyContent="space-between" gap={16}>
          <SearchBar onChange={(e) => setSearchText(e.target.value)} placeholder="Search..." css={{ maxWidth: 240 }} />
          <Button
            onClick={onCreateApp}
            icon="add-thin"
            size="small"
          />
        </Flex>
        <SectionLoader
          empty={{
            variant: 'neutral',
            element: (
              <Flex alignItems="center" direction="column" gap={16}>
                <Flex alignItems="center" direction="column" gap={8}>
                  <Text fontWeight="bold">There are no projects.</Text>
                  <Text fontSize={14}>Nothing to show here.</Text>
                </Flex>
                <Button label="Create Project" size="small" mode="distinct" onClick={onCreateApp} />
              </Flex>
            )
          }}
          data={data}
          loading={loading}
          error={error}
        >
          <AppsGrid
            apps={(filteredApps || []) as App[]}
            installations={(installationsList || []) as Installation[]}
          />
        </SectionLoader>
      </Block>
    </>
  )
}

export default ProjectsPage
