import React, { Fragment, useContext } from 'react'

import * as mixins from 'styles/mixins'
import ContactUsView from 'components/views/ContactUsView'
import Flex from 'components/layout/Flex'
import Icon, { withPreload } from 'components/icons/Icon'
import Text from 'components/typography/Text'
import TopbarItem from './TopbarItem'
import WelcomeTour from 'components/views/tour/WelcomeTour'
import { Popover, PopoverBody, PopoverContainer, PopoverDivider, PopoverItem, PopoverSubheader } from 'components/popover'
import { styled } from 'styles/stitches'
import { TourGuideContext, TourType } from 'components/providers/TourProvider'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Color } from 'styles/theme'

const POPOVER_MIN_WIDTH = 250
const POPOVER_ITEM_HORIZONTAL_PADDING = 25
const POPOVER_ITEM_VERTICAL_PADDING = 15

type ColorMap = { [key: string]: Color }

const POPOVER_ITEM_ICON_COLORS: ColorMap = {
  normal: 'dark300',
  hover: 'primary300'
}

const POPOVER_ITEM_TEXT_COLORS: ColorMap = {
  normal: 'dark900',
  hover: 'primary300'
}

const StyledDashboardPopover = styled(Popover, {
  minWidth: POPOVER_MIN_WIDTH
})

const StyledIcon = styled(Icon, {
  color: POPOVER_ITEM_ICON_COLORS.normal
})

const StyledText = styled(Text, {
  color: POPOVER_ITEM_TEXT_COLORS.normal
})

const StyledPopoverItem = styled(PopoverItem, {
  ...mixins.transition('simple'),
  width: '100%',
  paddingY: POPOVER_ITEM_VERTICAL_PADDING,
  paddingLeft: POPOVER_ITEM_HORIZONTAL_PADDING,
  cursor: 'pointer',

  '&:hover, &:focus': {

    [`${StyledIcon}`]: {
      color: POPOVER_ITEM_ICON_COLORS.hover
    },

    [`${StyledText}`]: {
      color: POPOVER_ITEM_TEXT_COLORS.hover
    }
  }
})

const HelpMenuItem = styled(TopbarItem, {
  paddingX: 20
})

const PlatformIcon = withPreload(StyledIcon, 'platform-guide')
const MessagingIcon = withPreload(StyledIcon, 'app-messaging')
const WelcomeIcon = withPreload(StyledIcon, 'film')
const ContactIcon = withPreload(StyledIcon, 'email-alt')

const HelpMenuTopbarItem: React.FC = () => {
  const currentAccount = useCurrentAccountContext()!
  const { startTour, skipTour, endTour } = useContext(TourGuideContext)!

  const { openView } = useViewDispatch()
  const onContactUs = () => openView({
    title: 'Contact us',
    component: ContactUsView,
    params: { initialValues: { message: '' } },
    style: 'DIALOG'
  })

  const handleRestart = () => {
    const welcomeTour = Object.keys(currentAccount.tours)
      .find((key) => (
        [ TourType.WELCOME_OWNER_TOUR, TourType.WELCOME_MEMBER_TOUR ].includes(key as any)
      )) as TourType
    const handleSkip = () => {
      skipTour(welcomeTour as TourType)
    }

    const handleStart = () => {
      startTour(welcomeTour as TourType || TourType.WELCOME_MEMBER_TOUR)
    }

    openView({
      title: 'Welcome Tour',
      component: WelcomeTour,
      params: {
        onSkip: handleSkip,
        onStart: handleStart
      },
      style: 'DIALOG'
    })
  }

  const HELP_MENU_ELEMENTS = [
    { name: 'Platform Guide', icon: PlatformIcon, href: 'https://docs.dashx.com/', target: '_blank' },
    { name: 'Messaging Guide', icon: MessagingIcon, href: 'https://docs.dashx.com/messaging', target: '_blank' },
    { name: 'Welcome Tour', icon: WelcomeIcon, onClick: handleRestart },
    { name: 'Contact us', icon: ContactIcon, onClick: onContactUs }
  ]

  return (
    <PopoverContainer
      onPopoverOpen={endTour}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -14, 0 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <HelpMenuItem
          active={isActive}
          {...otherToggleProps}
          className="tg--help-menu"
        >
          <Icon name="alert-question" size={24} />
        </HelpMenuItem>
      )}
      {(popoverProps) => (
        <StyledDashboardPopover withArrow {...popoverProps}>
          <PopoverSubheader title="Help" />
          <PopoverDivider />
          <PopoverBody>
            {HELP_MENU_ELEMENTS.map(({ name, icon: IconComponent, ...props }, index) => (
              <Fragment key={name}>
                {index < HELP_MENU_ELEMENTS.length && index !== 0 && <PopoverDivider />}
                <StyledPopoverItem
                  as="button"
                  {...props}
                >
                  <Flex gap={12} alignItems="center">
                    <IconComponent size={16} />
                    <StyledText
                      fontSize={14}
                      fontWeight="semibold"
                      letterSpacing="condensed"
                    >
                      {name}
                    </StyledText>
                  </Flex>
                </StyledPopoverItem>
              </Fragment>
            ))}
          </PopoverBody>
        </StyledDashboardPopover>
      )}
    </PopoverContainer>
  )
}

export default HelpMenuTopbarItem
