import dayjs from 'dayjs'
import React, { useState } from 'react'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import CodeViewer from 'components/dataWidgets/CodeViewer'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import useHover from 'hooks/useHover'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'

type ActivityItem = {
  name: string,
  data: Record<any, any>,
  status?: (o: boolean, h: boolean) => React.ReactNode,
  timestamp: string
}

interface ActivityListItemProps {
  activity: ActivityItem,
  isLast?: boolean
}

const ACTIVITY_ICON_SIZE = 48

const Activity = styled(Flex, {
  ...mixins.transition('fluid')
})

const ActivityIcon = styled(Flex, {
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.2),

  size: [ ACTIVITY_ICON_SIZE ],
  backgroundColor: '#fff',
  borderRadius: ACTIVITY_ICON_SIZE,
  '& [data-icon]': {
    color: 'dark100'
  }
})

const ActivityDivider = styled(Divider, {
  minHeight: ACTIVITY_ICON_SIZE / 2,
  flexGrow: 1
})

const DetailsContainer = styled(Flex, {
  cursor: 'pointer',
  height: ACTIVITY_ICON_SIZE
})

const StyledChevron = styled(IconButton, {
  variants: {
    open: {
      true: {
        '& [data-icon]': {
          transform: 'rotate(180deg)'
        }
      },
      false: {
        '& [data-arrow]': {
          transform: 'rotate(0deg)'
        }
      }
    }
  }
})

const CodeViewerContainer = styled(Box, {
  marginBottom: 16,

  variants: {
    open: {
      true: {
        height: 'auto',
        opacity: 1,
        transition: 'opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)'
      },
      false: {
        height: '0',
        opacity: 0
      }
    }
  }
})

const ActivityListItem = ({ activity, isLast = false }: ActivityListItemProps) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ hoverProps, isHovered ] = useHover()
  return (
    <Activity gap={16}>
      <Flex gap={10} alignItems="center" direction="column">
        <ActivityIcon shrink={0} alignItems="center" justifyContent="center">
          <Icon data-icon name="show" size={16} />
        </ActivityIcon>
        {!isLast && <ActivityDivider color="dark100" orientation="vertical" variant="ruler" />}
      </Flex>
      <Flex grow={1} direction="column" gap={8} style={{ minWidth: 0 }}>
        <DetailsContainer alignItems="center" justifyContent="space-between" gap={10} onClick={() => setIsOpen(!isOpen)} {...hoverProps}>
          <Flex alignItems="center" justifyContent="space-between" gap={10} grow={1}>
            <Flex direction="column" gap={4}>
              <Text fontWeight="bold" fontSize={14}>{activity.name}</Text>
              <Text color="dark400" fontSize={12}>{dayjs(activity.timestamp?.split('+')[0]).utc(true).local().format('MMM D YYYY h:mma')}</Text>
            </Flex>
            {activity.status?.(isOpen, isHovered)}
          </Flex>
          <StyledChevron open={isOpen} name="arrow-down" description={isOpen ? 'Hide Data' : 'Show Data'} variant="dark" />
        </DetailsContainer>
        {isOpen && activity.data && (
          <CodeViewerContainer open={isOpen}>
            <CodeViewer data={JSON.stringify(activity.data[activity.name], null, 2)} language="json" />
          </CodeViewerContainer>
        )}
      </Flex>
    </Activity>
  )
}

export default ActivityListItem
