import React from 'react'

import * as mixins from 'styles/mixins'
import BackLink from 'components/links/BackLink'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Space } from 'styles/theme'

const TOP_MENU_HEADER_SPACING_X: Space = 24
const TOP_MENU_HEADER_SPACING_Y: Space = 20

const StyledPopoverSubheader = styled(Flex, {
  ...mixins.transition('simple'),

  variants: {
    spacing: {
      none: {},
      horizontal: {
        paddingX: TOP_MENU_HEADER_SPACING_X
      },
      vertical: {
        paddingY: TOP_MENU_HEADER_SPACING_Y
      },
      both: {
        paddingY: TOP_MENU_HEADER_SPACING_Y,
        paddingX: TOP_MENU_HEADER_SPACING_X
      }
    }
  }
})

StyledPopoverSubheader.defaultProps = {
  spacing: 'both'
}

type PopoverSubheaderProps = {
  onBackClick? : () => void,
  showBack? : boolean,
  title : string
} & StyledProps<typeof StyledPopoverSubheader>

function PopoverSubheader({ title, showBack, onBackClick, ...rest }: PopoverSubheaderProps) {
  return (
    <StyledPopoverSubheader justifyContent="center" direction="column" gap={20} {...rest}>
      {showBack && (
        <BackLink label="Back" onClick={onBackClick} />
      )}
      <Text
        color="dark700"
        fontSize={12}
        fontWeight="semibold"
        textTransform="uppercase"
      >
        {title}
      </Text>
    </StyledPopoverSubheader>
  )
}

export default PopoverSubheader
