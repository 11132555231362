import React, { useContext } from 'react'
import { useRecoilValue } from 'recoil'
import { useSortable } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { colorVars } from 'styles/theme'
import {
  SIDEBAR_ELEMENT_HORIZONTAL_PADDING,
  SIDEBAR_ELEMENT_VERTICAL_PADDING
} from 'components/menuelements/SidebarPrimaryElement'
import { styled } from 'styles/stitches'
import { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { Views } from 'components/dashboardEditor/constants'

const StyledSidebarAddElement = styled(Flex, {
  ...mixins.transition('simple'),

  paddingY: SIDEBAR_ELEMENT_VERTICAL_PADDING,
  paddingX: SIDEBAR_ELEMENT_HORIZONTAL_PADDING,
  pointerEvents: 'none',
  whiteSpace: 'nowrap',
  width: '100%',

  '& [data-icon]': {
    ...mixins.transition('fastIn'),

    color: 'light100',
    display: 'flex',
    opacity: 0
  },

  '& [data-name]': {
    ...mixins.transition('fastIn'),

    color: 'light100',
    opacity: 0
  },

  '& [data-icon] + [data-name]': {
    paddingLeft: 14
  },

  '&:hover': {
    backgroundColor: rgba(colorVars.secondary400rgb, 0.3),
    cursor: 'pointer',

    '& [data-icon]': {
      opacity: 1
    },

    '& [data-name]': {
      opacity: 1
    }
  },
  variants: {
    active: {
      true: {
        backgroundColor: rgba(colorVars.secondary400rgb, 0.3),

        '& [data-icon]': {
          ...mixins.dropShadow('icon', colorVars.secondary300rgb),

          opacity: 1
        },

        '& [data-name]': {
          fontFamily: 'normal',
          opacity: 1
        }
      }
    },
    visible: {
      true: {
        opacity: 1,
        pointerEvents: 'all',

        '& [data-icon]': {
          ...mixins.transition('fastOut'),

          opacity: 0.2
        },

        '& [data-name]': {
          ...mixins.transition('fastOut'),

          opacity: 0.5
        }
      }
    },
    variant: {
      primary: {},
      secondary: {
        backgroundColor: 'light100',

        '& [data-icon]': {
          color: 'dark700',
          filter: 'none'
        },

        '& [data-name]': {
          color: 'dark700',
          fontWeight: 'bold',
          paddingLeft: 8
        },

        '&:hover': {
          backgroundColor: 'light100',
          cursor: 'pointer',
          opacity: 1
        }
      }
    },
    draggingOver: {
      true: {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          inset: 8,
          border: '2px dashed light100',
          borderRadius: 4
        }
      },
      false: {}
    }
  }
})

StyledSidebarAddElement.compoundVariant({
  variant: 'secondary',
  draggingOver: true
}, {
  '&::after': {
    border: '2px dashed dark700'
  }
})

type SidebarAddElementProps = {
  isVisible?: boolean,
  onAddElement: () => void,
  parentId?: string,
  variant: 'primary' | 'secondary'
}

function SidebarAddElement({
  isVisible = false,
  onAddElement,
  parentId,
  variant
}: SidebarAddElementProps) {
  const { currentDashboard } = useContext(InternalContext)!
  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}

  const { openDashboardEditor } = useContext(DashboardContext)!
  const { openDashboardEditorView } = useDashboard()

  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    draggingOverMenuIdState
  } = useDashboard()

  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)

  const currentId = parentId ? 'ADD_MENU_SECONDARY' : 'ADD_MENU_PRIMARY'
  const containerId = parentId ? 'SIDEBAR_SECONDARY' : 'SIDEBAR_PRIMARY'

  const isMenuDragStarted = !!draggedMenu && !draggedMenu.id && !draggingOverMenuId
  const isAppDragStarted = !!draggedApp && !draggedApp.id && !draggingOverMenuId
  const isResourceDragStarted = !!draggedResource
    && !draggedResource.id && !draggingOverMenuId

  const isDragStarted = isMenuDragStarted || isAppDragStarted || isResourceDragStarted

  const isDraggingOverAddMenu = draggingOverMenuId === currentId
    || draggingOverMenuId === containerId

  const { setNodeRef } = useSortable({
    id: currentId,
    data: {
      parentId,
      placement: 'SIDE',
      isSticky: false
    }
  })

  const initialValues = { dashboardId, parentId, placement: 'SIDE', newNonStickyPosition, newStickyPosition }

  const handleClick = () => {
    openDashboardEditorView({
      target: Views.ADD_MENU,
      params: { initialValues }
    })
    openDashboardEditor()
    onAddElement?.()
  }

  return (
    <StyledSidebarAddElement
      onClick={handleClick}
      alignItems="center"
      variant={variant}
      visible={isVisible || isDragStarted || isDraggingOverAddMenu}
      draggingOver={isDragStarted || isDraggingOverAddMenu}
      ref={setNodeRef}
    >
      <Icon name="add-outline-round" data-icon size={variant === 'primary' ? 20 : 16} />
      <Text
        fontSize={12}
        data-name
        data-collapsible
      >
        Add Menu Item
      </Text>
    </StyledSidebarAddElement>

  )
}

export default SidebarAddElement
