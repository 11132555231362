import React, { Children, useMemo } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

type SlideTransitionProps = React.PropsWithChildren<{
  activeIndex: number
}>

const StyledWrapper = styled(Flex, {
  ...mixins.transition('fastIn', 'transform'),

  left: 0,
  position: 'absolute',
  size: [ '100%' ],
  top: 0
})

const StyledContainer = styled(Flex, {
  position: 'relative'
})

function SlideTransition({ children, activeIndex }: SlideTransitionProps) {
  const childrenCount = Children.count(children)

  const stylesList = useMemo(() => (
    Array(childrenCount).fill({}).map((_, index) => {
      const depth = (index - activeIndex)

      return {
        transform: `translateX(${depth * 100}%)`
      }
    })
  ), [ activeIndex, childrenCount ])

  return (
    <StyledContainer grow={1}>
      {Children.map(children, (child, index) => (
        <StyledWrapper direction="column" grow={1} style={stylesList[index]}>
          {child}
        </StyledWrapper>
      ))}
    </StyledContainer>
  )
}

export default SlideTransition
