import { useEffect, useState } from 'react'

import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE
} from 'components/dataWidgets/Pager'

type UsePagerProps = {
  initialPage?: number,
  initialPageSize?: number,
  pageSizeOptions?: number[]
}

type UsePagerResult = [
  number,
  number,
  (nextPage: number) => void,
  (nextPage: number) => void
]

function usePager({
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  initialPage = DEFAULT_PAGE,
  initialPageSize = pageSizeOptions[0]
}: UsePagerProps = {}): UsePagerResult {
  const [ page, setPage ] = useState<number>(initialPage)
  const [ pageSize, setPageSize ] = useState<number>(initialPageSize)

  useEffect(() => {
    setPage(1)
    setPageSize(initialPageSize)
  }, [ initialPageSize ])

  const handlePageChange = (nextPage: number) => {
    setPage(nextPage)
  }

  const handlePageSizeChange = (nextPageSize: number) => {
    setPage(1)
    setPageSize(nextPageSize)
  }

  return [ page, pageSize, handlePageChange, handlePageSizeChange ]
}

export type {
  UsePagerResult
}

export default usePager
