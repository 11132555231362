import React from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import type { HTMLAttributes } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { css, styled } from 'styles/stitches'
import { SIDEBAR_SECONDARY_BORDER_COLOR, SIDEBAR_SECONDARY_HORIZONTAL_PADDING } from 'components/sidebar/constants'
import { Views } from 'components/dashboardEditor/constants'
import type useLongPress from 'hooks/useLongPress'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PopoverToggleProps } from 'components/popover'

const SECONDARY_ELEMENT_HEIGHT = 46
const SECONDARY_FOOTER_ELEMENT_HEIGHT = 36

const highlightedStyle = {
  backgroundColor: 'light300',

  '& [data-text]': {
    color: 'primary300'
  }
}

const highlightedFooterStyle = {
  backgroundColor: 'light100',

  '& [data-text]': {
    color: 'dark700'
  }
}

const SidebarSecondaryItem = styled(Flex, {
  ...mixins.transition('simple'),

  color: 'dark900',
  cursor: 'pointer',
  height: SECONDARY_ELEMENT_HEIGHT,
  paddingX: SIDEBAR_SECONDARY_HORIZONTAL_PADDING,

  '& [data-text]': {
    fontSize: 14
  },

  '& [data-icon]': {
    color: 'dark100'
  },

  '&:hover': highlightedStyle,

  variants: {
    isFooter: {
      true: {
        height: SECONDARY_FOOTER_ELEMENT_HEIGHT,

        '& [data-text]': {
          ...mixins.transition('simple'),

          borderBottomStyle: 'solid',
          borderBottomWidth: 2,
          color: 'dark500',
          fontSize: 12,
          fontWeight: 'bold',
          lineHeight: 'compact'
        },

        '&:hover': highlightedFooterStyle
      }
    },
    isFooterActive: {
      true: {
        backgroundColor: 'light100',

        '& [data-text]': {
          color: 'dark700'
        }
      }
    },
    isHighlighted: {
      true: highlightedStyle,
      false: {}
    }
  }
})

SidebarSecondaryItem.compoundVariant({
  isFooter: true,
  isHighlighted: true
}, highlightedFooterStyle)

const SidebarSecondaryItemName = styled(Text, {
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const SidebarSecondaryHeader = styled(Text, {
  alignItems: 'center',
  color: 'dark500',
  display: 'flex',
  height: SECONDARY_ELEMENT_HEIGHT,
  paddingX: SIDEBAR_SECONDARY_HORIZONTAL_PADDING
})

const classes = {
  sidebarSecondaryItem_active: css({
    backgroundColor: 'light300',

    '& [data-text]': {
      color: 'primary300',
      fontWeight: 'bold'
    }
  })
}

type SidebarSecondaryElementProps = HTMLAttributes<HTMLElement> & {
  isFooter?: boolean,
  menuElement: ComputedMenuElement,
  onMenuElementClick?: (menuElement: ComputedMenuElement, e: React.MouseEvent<HTMLElement>) => void,
  popoverToggleProps?: PopoverToggleProps,
  longPressProps?: ReturnType<typeof useLongPress>
}

const SidebarSecondaryElement = ({
  isFooter = false,
  menuElement,
  onMenuElementClick,
  popoverToggleProps,
  longPressProps
}: SidebarSecondaryElementProps) => {
  const { pathname } = useLocation()

  const { dashboardEditorState } = useDashboard()
  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  if (menuElement.kind === 'ITEM') {
    const linkProps = menuElement.target === 'URL' ? {
      href: menuElement.url
    } : {
      activeClassName: classes.sidebarSecondaryItem_active,
      to: menuElement.fullPath!
    }

    const opensSubmenu = menuElement.target === 'SUBMENU'
    const opensExternalURL = menuElement.target === 'URL'

    return (
      <SidebarSecondaryItem
        alignItems="center"
        as={isInspecting ? 'div' : BaseLink}
        isFooter={isFooter}
        isFooterActive={Boolean(menuElement.isSticky && menuElement.fullPath === pathname)}
        isHighlighted={isActive}
        justifyContent="space-between"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onMenuElementClick?.(menuElement, e)}

        {...contextMenuProps}
        {...(isInspecting ? {} : linkProps)}
        {...longPressProps}
      >
        <SidebarSecondaryItemName as="span" data-text>
          {menuElement.renderedName || menuElement.name}
        </SidebarSecondaryItemName>
        {opensSubmenu && <Icon name="arrow-right" size={10} data-icon />}
        {opensExternalURL && <Icon name="external-link" size={10} data-icon />}
      </SidebarSecondaryItem>
    )
  }

  if (menuElement.kind === 'SEPARATOR') {
    if (menuElement.separatorStyle === 'RULER') {
      return (
        <Divider
          onClick={isInspecting
            ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
            : undefined}
          color={SIDEBAR_SECONDARY_BORDER_COLOR}
          spacing={20}
          {...contextMenuProps}
          {...longPressProps}
        />
      )
    }

    return (
      <Divider
        onClick={isInspecting
          ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
          : undefined}
        variant="whitespace"
        spacing={14}
        {...contextMenuProps}
        {...longPressProps}
      />
    )
  }

  if (menuElement.kind === 'GROUP') {
    return (
      <SidebarSecondaryHeader
        onClick={isInspecting
          ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
          : undefined}
        fontSize={12}
        textTransform="uppercase"
        {...contextMenuProps}
        {...longPressProps}
      >
        {menuElement.name}
      </SidebarSecondaryHeader>
    )
  }

  return null
}

export default SidebarSecondaryElement
