import React, { useContext, useRef } from 'react'
import { useField } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import IconButton from 'components/buttons/IconButton'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard from 'hooks/useDashboard'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import { Operation, OperationFragmentFragment, OperationsListDocument, OperationsListQuery, OperationsListQueryVariables, ParametersListQuery, Resource, useParametersListQuery } from 'generated/schema'
import { Views } from './constants'
import type { SearchSelectFieldProps } from 'components/contentEditors/generic/fields/SearchSelectField'
import type { TMap } from 'lib/getPropertyToElementMap'

type OperationFieldProps =
  Partial<SearchSelectFieldProps<OperationsListQuery, OperationsListQueryVariables>> & {
    operation?: OperationFragmentFragment
  }

const getOptionIcon = (option: Resource) => {
  const isCustomApp = !!option.app?.workspaceId
  if (isCustomApp) {
    return 'app-custom'
  }

  return `app-${option.app?.identifier || 'bridge'}`
}

const OperationField = (
  { operation, ...props }: OperationFieldProps
) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  return (
    <SearchSelectField<OperationsListQuery, OperationsListQueryVariables>
      isClearable
      isSearchable
      preload
      name="operation"
      label="Operation"
      prependIcon="search"
      placeholder="Start typing to search"
      size="small"
      variant="light"
      labelKey="name"
      valueKey="id"
      iconKey="app.identifier"
      options={operation ? [ operation ] : []}
      getOptionLabel={(option: OperationFragmentFragment) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
      getOptionIcon={getOptionIcon}
      query={OperationsListDocument}
      queryOptions={{
        variables: {
          filter: {
            workspaceId: {
              eq: currentWorkspace.id
            }
          },
          order: [ {
            name: 'asc'
          } ]
        }
      }}
      dataKey="operationsList"
      keys={[ 'name', 'identifier' ]}
      {...props}
    />
  )
}

const FieldsList = ({ operation }: { operation: OperationFragmentFragment }) => {
  const operationId = operation.id
  const { openDashboardEditorView, selectedBlockState } = useDashboard()

  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'fields', fieldsRef, subscription: {} })
  const field = useField('fields')

  const onDragEnd = useReorderFieldArray(fieldsRef)
  let idToParametersMap = {} as TMap<ParametersListQuery['parametersList'][number]>

  const { data, loading, error } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationId
        }
      }
    },
    skip: !operationId,
    onCompleted: ({ parametersList }) => {
      idToParametersMap = getPropertyToElementMap(parametersList || [], 'id')
      const currentOperationId = idToParametersMap[field.input.value?.[0]?.parameter]?.operationId

      if (currentOperationId !== operationId) {
        field.input.onChange(parametersList.map((parameter) => ({
          parameter: parameter.id,
          kind: 'PARAMETER',
          is_hidden: false
        })))
      } else {
        field.input.onChange(parametersList.map((parameter) => ({
          parameter: parameter.id,
          kind: 'PARAMETER',
          is_hidden: (
            field.input.value.find((item: any) => item.parameter === parameter.id)?.is_hidden
          ) && true
        })))
      }
    }
  })

  idToParametersMap = data ? getPropertyToElementMap(data.parametersList, 'id') : idToParametersMap

  const selectedBlock = useRecoilValue(selectedBlockState)!

  /* const handleAddNewParameter = () => openDashboardEditorView({
    target: Views.CREATE_PARAMETER,
    params: {
      operation: operation as Operation,
      block: selectedBlock
    }
  }) */

  return (
    <Flex gap={16} direction="column">
      <Flex direction="column" gap={4}>
        <Flex justifyContent="space-between" gap={16}>
          <Text
            color="dark700"
            fontSize={14}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Fields
          </Text>
          {/* <TextLink
            as="button"
            type="button"
            fontSize={10}
            onClick={handleAddNewParameter}
            mode="distinct"
          >
            Add new
          </TextLink> */}
        </Flex>
        {/* <Text fontSize={12} color="dark500">Specify the inputs for your operation.</Text> */}
      </Flex>
      <DataList
        loading={loading}
        error={error}
        contents={[
          {
            dataKey: 'parameter',
            slot: 'primary',
            renderer: ({ index }) => (
              <Text>
                {field.input.value?.[index!].label
                  || idToParametersMap[field.input.value?.[index!].parameter]?.name || ''}
              </Text>
            )
          },
          {
            dataKey: 'edit_column',
            slot: 'meta',
            renderer: ({ rowData, index }) => (
              <IconButton
                description="Edit"
                name="edit"
                disabled={field.input.value?.[index!].is_hidden}
                onClick={() => {
                  openDashboardEditorView({
                    target: Views.CREATE_PARAMETER,
                    params: {
                      initialValues: idToParametersMap[rowData.parameter],
                      operation: operation as Operation,
                      currentIndex: index,
                      block: selectedBlock
                    }
                  })
                }}
                size={16}
                variant="dark"
              />
            )
          },
          {
            dataKey: 'is_hidden',
            slot: 'toggle',
            renderer: ({ index }) => (
              <FormField
                defaultValue
                initialValue
                type="checkbox"
                component={ToggleInput}
                name={`fields[${index}].is_hidden`}
                invert
              />
            )
          }
        ]}
        data={field.input.value || []}
        onRowDragEnd={onDragEnd}
        selectionMode="none"
      />
    </Flex>
  )
}

export { getOptionIcon, FieldsList }

export default OperationField
