import React, { Fragment, memo, useCallback, useContext, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import type { Dispatch, SetStateAction, UIEvent } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { createPortal } from 'react-dom'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { DragOverlay, useDroppable } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import BackLink from 'components/links/BackLink'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import GenericMenuElement from 'components/menuelements/GenericMenuElement'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import MenuElementPositionProvider from 'components/contexts/MenuElementPositionContext'
import SidebarAddElement from 'components/sidebar/SidebarAddElement'
import SidebarFooter from 'components/sidebar/SidebarFooter'
import SidebarToggleButton from 'components/sidebar/SidebarToggleButton'
import Text from 'components/typography/Text'
import transitions from 'styles/primitives/transitions'
import useClientQuery from 'hooks/useClientQuery'
import useContainerScroll from 'hooks/useContainerScroll'
import useDashboard from 'hooks/useDashboard'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import { PREFERENCES_QUERY } from 'client/state/preferences'
import { SIDEBAR_BORDER_RADIUS, SIDEBAR_PRIMARY_WIDTH, SIDEBAR_PRIMARY_WIDTH_COLLAPSED, SIDEBAR_SECONDARY_BACKGROUND_COLOR, SIDEBAR_SECONDARY_BORDER_COLOR, SIDEBAR_SECONDARY_HORIZONTAL_PADDING, SIDEBAR_SECONDARY_VERTICAL_PADDING, SIDEBAR_SECONDARY_WIDTH, SIDEBAR_SECONDARY_WIDTH_COLLAPSED } from 'components/sidebar/constants'
import { Views } from 'components/dashboardEditor/constants'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PreferencesQuery } from 'client/state/preferences'

type SidebarSecondaryProps = {
  isPrimaryHovered: boolean,
  isSecondaryHovered: boolean,
  isSidebarLocked?: boolean,
  menuElements?: ComputedMenuElement[],
  parentMenuElement?: ComputedMenuElement,
  setIsSecondaryHovered: Dispatch<SetStateAction<boolean>>,
  toggleIsAnimating: (duration: number, delay: number) => void
}

type DraggableMenuElementsProps = {
  menuElements: ComputedMenuElement[],
  onMenuElementClick: (menuElement: ComputedMenuElement) => void,
  isSidebarPrimaryHovered: boolean
}

const SIDEBAR_SECONDARY_TRANSITION = 'simple'
const SIDEBAR_SECONDARY_TRANSITION_DELAY = 0.15
const getSidebarSecondaryCollapsedMinimizedPosition = () => (
  -SIDEBAR_SECONDARY_WIDTH + SIDEBAR_PRIMARY_WIDTH_COLLAPSED + SIDEBAR_SECONDARY_WIDTH_COLLAPSED
)
const getSidebarSecondaryCollapsedPosition = () => (
  -SIDEBAR_SECONDARY_WIDTH + SIDEBAR_PRIMARY_WIDTH + SIDEBAR_SECONDARY_WIDTH_COLLAPSED
)
const getSidebarSecondaryExpandedPosition = () => SIDEBAR_PRIMARY_WIDTH_COLLAPSED
const getSidebarSecondaryHiddenPosition = () => -SIDEBAR_SECONDARY_WIDTH

const StyledSidebarSecondary = styled(Flex, {
  marginBottom: 20,
  ...mixins.shadow('xxLarge', colorVars.dark1100rgb, 0.1),
  ...mixins.transition('simple'),
  size: [ SIDEBAR_SECONDARY_WIDTH, '100%' ],
  backgroundColor: SIDEBAR_SECONDARY_BACKGROUND_COLOR,
  borderBottomRightRadius: SIDEBAR_BORDER_RADIUS,
  borderTopRightRadius: SIDEBAR_BORDER_RADIUS,
  left: 0,
  paddingTop: SIDEBAR_SECONDARY_VERTICAL_PADDING,
  position: 'absolute',
  top: 0,
  transform: `translateX(${getSidebarSecondaryCollapsedMinimizedPosition()}px)`,
  zIndex: 'below',
  variants: {
    transitionDelayed: {
      true: {
        transitionDelay: `${SIDEBAR_SECONDARY_TRANSITION_DELAY}s`
      }
    },
    expanded: {
      true: {
        transform: `translateX(${getSidebarSecondaryExpandedPosition()}px)`
      }
    },
    collapsed: {
      true: {
        transform: `translateX(${getSidebarSecondaryCollapsedPosition()}px)`
      }
    },
    hidden: {
      true: {
        transform: `translateX(${getSidebarSecondaryHiddenPosition()}px)`
      }
    }
  }
})

const StyledSidebarSecondaryBody = styled(Scrollbars, {
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto'
})

const StyledHeader = styled(Flex, {
  borderBottomColor: 'transparent',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  color: 'dark900',
  paddingY: 15,
  paddingX: SIDEBAR_SECONDARY_HORIZONTAL_PADDING,

  '& [data-icon]': {
    marginRight: -8
  },
  variants: {
    bodyScrolled: {
      true: {
        borderBottomColor: SIDEBAR_SECONDARY_BORDER_COLOR
      }
    }
  }
})

const Dropzone = ({ id, index, length }: any) => {
  const { idToMenuElementMap } = useContext(InternalContext)!
  const { draggingOverMenuIdState } = useDashboard()
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggedElement = draggedMenu || draggedApp || draggedResource
  const draggingOverMenuElement = draggingOverMenuId && idToMenuElementMap[draggingOverMenuId]

  const isDraggingAccrossDroppables = draggedMenu?.parentId !== draggingOverMenuElement?.parentId
  || draggedMenu?.placement !== draggingOverMenuElement?.placement

  if (id === 'PREPEND_SIDEBAR_SECONDARY') {
    if (!draggedElement || ('id' in draggedElement && !isDraggingAccrossDroppables)) {
      return null
    }
  }

  const isAddingMenuElement = draggedMenu && !draggedMenu.id
  const isDraggingBeforeLastMenuElement = index < length - 1
  const isAddingApp = draggedApp?.id
  const isDraggingResource = draggedResource?.id

  if (!(
    (isAddingMenuElement && isDraggingBeforeLastMenuElement)
    || (draggedMenu?.id && isDraggingAccrossDroppables)
    || isAddingApp
    || isDraggingResource
  )) {
    return null
  }

  return (
    <Flex
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: draggingOverMenuId === id ? 1 : 0,
        zIndex: 1,

        '&:hover': {
          opacity: draggingOverMenuId ? undefined : 1
        }
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const Overlay = () => {
  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  return createPortal(
    <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
      {draggedMenu?.id ? (
        <GenericMenuElement menuElement={draggedMenu} isDragging />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const DraggableMenuElements = memo(({
  menuElements,
  onMenuElementClick,
  isSidebarPrimaryHovered
}: DraggableMenuElementsProps) => (
  <SortableContext
    id="SECONDARY_SIDEBAR_SORTABLE"
    items={menuElements.map((menuElement) => menuElement.id)}
    strategy={verticalListSortingStrategy}
    disabled={isSidebarPrimaryHovered}
  >
    {menuElements.map((menuElement, index, { length }) => (
      <Fragment key={menuElement.id}>
        <GenericMenuElement
          key={menuElement.id}
          menuElement={menuElement}
          onMenuElementClick={onMenuElementClick}
        />
        <Dropzone id={menuElement.id} index={index} length={length} />
      </Fragment>
    ))}
    <Overlay />
  </SortableContext>
))

const SidebarSecondaryHeader = ({ scrollY, parentMenuElement }: any) => {
  const { idToMenuElementMap } = useContext(InternalContext)!
  const canGoBack = parentMenuElement?.parentId
  const backPath = idToMenuElementMap?.[parentMenuElement?.parentId]?.fullPath

  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  const { setNodeRef } = useSortable({
    id: 'PREPEND_SIDEBAR_SECONDARY',
    data: {
      placement: 'SIDE',
      parentId: parentMenuElement.id,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  return (
    <div>
      <StyledHeader
        bodyScrolled={scrollY > 0}
        direction="column"
        gap={32}
        ref={setNodeRef}
      >
        {canGoBack && (
          <BackLink label="Back" to={backPath} />
        )}
        <Flex direction="column" gap={8}>
          <Text
            truncate
            as="div"
            fontSize={18}
            fontWeight="bold"
          >
            {parentMenuElement.name}
          </Text>
        </Flex>
      </StyledHeader>
      <Dropzone id="PREPEND_SIDEBAR_SECONDARY" />
    </div>
  )
}

function SidebarSecondary({
  toggleIsAnimating,
  isPrimaryHovered,
  isSecondaryHovered,
  isSidebarLocked = false,
  menuElements = [],
  parentMenuElement,
  setIsSecondaryHovered,
  ...other
}: SidebarSecondaryProps) {
  const {
    data: { preferences: { isSidebarMinimized } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)

  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    dashboardEditorState
  } = useDashboard()

  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggedElementId = draggedMenu?.id || draggedApp?.id || draggedResource?.id

  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const [ { scrollY }, updateScroll ] = useContainerScroll()

  const isTransitionDelayed = !isSidebarMinimized || isSecondaryHovered || isPrimaryHovered
  const isExpanded = (!isSidebarMinimized || isSecondaryHovered) && !isPrimaryHovered

  const sidebarTransitionDuration = transitions[SIDEBAR_SECONDARY_TRANSITION].duration || 0
  const sidebarTransitionDelay = isTransitionDelayed ? SIDEBAR_SECONDARY_TRANSITION_DELAY : 0

  const toggleMinimize = () => {
    if (!isSidebarMinimized) {
      setIsSecondaryHovered(false)
      toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
    }
  }

  const onAddMenuElement = useCallback(() => {
    setIsSecondaryHovered(false)
    toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
  }, [
    setIsSecondaryHovered, toggleIsAnimating, sidebarTransitionDuration, sidebarTransitionDelay
  ])

  const handleScroll = useCallback((event: UIEvent<HTMLElement>) => updateScroll({
    scrollX: event?.currentTarget.scrollLeft,
    scrollY: event?.currentTarget.scrollTop
  }), [ updateScroll ])

  const [ bodyMenuElements, footerMenuUnlockedElements, footerMenuLockedElements ] = useMemo(() => (
    menuElements
      .reduce(([
        bodyElements,
        footerUnlockedElements,
        footerLockedElements
      ]: Array<ComputedMenuElement[]>, menuElement) => {
        if (menuElement.isRoot && menuElement.isSticky) {
          footerLockedElements.push(menuElement)
        } else if (menuElement.isSticky) {
          footerUnlockedElements.push(menuElement)
        } else {
          bodyElements.push(menuElement)
        }
        return [ bodyElements, footerUnlockedElements, footerLockedElements ]
      }, [ [], [], [] ])
  ), [ menuElements ])

  const onMenuElementClick = useCallback(() => {
    if (isSidebarMinimized) {
      setIsSecondaryHovered(false)
      toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
    }
  }, [
    isSidebarMinimized,
    setIsSecondaryHovered,
    toggleIsAnimating,
    sidebarTransitionDuration,
    sidebarTransitionDelay
  ])

  const { setNodeRef } = useDroppable({
    id: 'SIDEBAR_SECONDARY',
    data: {
      placement: 'SIDE',
      parentId: parentMenuElement?.id,
      isSticky: false
    }
  })

  return (
    <StyledSidebarSecondary
      expanded={isExpanded}
      collapsed={isPrimaryHovered}
      transitionDelayed={isTransitionDelayed}
      hidden={!parentMenuElement}
      direction="column"
      {...other}
    >
      {parentMenuElement && (
        <SidebarSecondaryHeader
          scrollY={scrollY}
          parentMenuElement={parentMenuElement}
        />
      )}
      <SidebarToggleButton
        isMinimized={isSidebarMinimized}
        isVisible={isSecondaryHovered}
        onToggleMinimize={toggleMinimize}
        variant="secondary"
      />
      <MenuElementPositionProvider
        stickyElements={footerMenuUnlockedElements}
        nonStickyElements={bodyMenuElements}
      >

        <StyledSidebarSecondaryBody
          autoHide
          onScroll={handleScroll}
          renderTrackHorizontal={() => <div />}
          renderThumbHorizontal={() => <div />}
        >
          <div
            ref={setNodeRef}
            style={{
              height: '100%',
              cursor: MenuElement.isDescendantOf(draggedElementId, parentMenuElement)
                ? 'no-drop'
                : undefined
            }}
          >
            <DraggableMenuElements
              isSidebarPrimaryHovered={isPrimaryHovered}
              menuElements={bodyMenuElements}
              onMenuElementClick={onMenuElementClick}
            />
            {parentMenuElement
              && !parentMenuElement.isRoot
              && !isSidebarLocked
              && !isInspecting
              && (
                <SidebarAddElement
                  isVisible={isSecondaryHovered}
                  onAddElement={onAddMenuElement}
                  parentId={parentMenuElement.id}
                  variant="secondary"
                />
              )}
          </div>
        </StyledSidebarSecondaryBody>

        <div>
          <SidebarFooter>
            {(!!footerMenuUnlockedElements.length
                || !!footerMenuLockedElements.length
                // || snapshot.isDraggingOver
            ) && <Divider color="light700" spacing={12} />}
            {!!footerMenuUnlockedElements.length
                && (
                  <DraggableMenuElements
                    isSidebarPrimaryHovered={isPrimaryHovered}
                    menuElements={footerMenuUnlockedElements}
                    onMenuElementClick={onMenuElementClick}
                  />
                )}

            {footerMenuLockedElements.map((menuElement) => (
              <GenericMenuElement
                key={menuElement.id}
                menuElement={menuElement}
                onMenuElementClick={onMenuElementClick}
              />
            ))}
          </SidebarFooter>
        </div>
      </MenuElementPositionProvider>
    </StyledSidebarSecondary>
  )
}

DraggableMenuElements.displayName = 'DraggableMenuElements'

export default SidebarSecondary

export {
  getSidebarSecondaryCollapsedMinimizedPosition,
  getSidebarSecondaryCollapsedPosition,
  getSidebarSecondaryExpandedPosition,
  getSidebarSecondaryHiddenPosition
}
