import React from 'react'

import { styled } from 'styles/stitches'

type DisablerProps = React.PropsWithChildren<{
  disabled?: boolean
}>

const StyledDisabler = styled('div', {
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    }
  }
})

function Disabler({ children, disabled = true }: DisablerProps) {
  return (
    <StyledDisabler disabled={disabled}>
      {children}
    </StyledDisabler>
  )
}

export default Disabler
