import React from 'react'

import DetailsBlock from 'components/blocks/DetailsBlock'
import type { DetailsBlockProps } from 'components/blocks/DetailsBlock'

const DetailsBlockWrapper: React.FC<DetailsBlockProps> = ({ block, ...props }) => {
  const settings = block?.properties?.data_source_settings
  return (
    <DetailsBlock
      {...props}
      recordId={settings?.record}
      resourceId={settings?.resource}
      operationId={settings?.operation}
      parameters={settings?.parameters}
      {...block.properties}
    />
  )
}

export default DetailsBlockWrapper
