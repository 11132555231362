import React, { useContext } from 'react'

import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Content, { contentVersionStatusVariants, ContentVersionStatus } from 'models/Content'
import Flex from 'components/layout/Flex'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import VersionDiff from 'components/contentVersion/VersionDiff'
import VersionHeader from 'components/contentVersion/VersionHeader'
import { ContentVersionContext } from 'components/contentVersion/ContentVersionProvider'
import { PublishingAction } from 'hooks/useVersionPublishing'
import { styled } from 'styles/stitches'
import type { ViewStyleComponentRenderProps } from 'components/views'

type VersionCompareProps = ViewStyleComponentRenderProps & {
  onRequestClose: () => void,
  onRestoreContent: (id: string) => Promise<Record<string, any>>
}

const PUBLISHING_ELEMENTS_WRAPPER_MARGIN = 30

const PublishingElementsWrapper = styled(Flex, {
  marginLeft: PUBLISHING_ELEMENTS_WRAPPER_MARGIN
})

function VersionCompare({
  onRequestClose,
  onRestoreContent,
  Header,
  Body,
  SubHeader
}: VersionCompareProps) {
  const {
    activeLocales,
    defaultLocale,
    content,
    handlePublishing,
    isPublishingEnabled,
    currentVersion,
    setCurrentVersionId,
    setPreviousVersionId
  } = useContext(ContentVersionContext)

  if (!currentVersion) {
    return null
  }

  const isPublished = currentVersion.status === ContentVersionStatus.PUBLISHED

  const isCurrentVersion = (
    // publishing workflow enabled
    currentVersion.status === ContentVersionStatus.CURRENT_DRAFT
    // publishing workflow disabled
    || currentVersion.status === ContentVersionStatus.CURRENT
    // published && latest version
    || (currentVersion.status === ContentVersionStatus.PUBLISHED && currentVersion.index === 0)
  )

  const onBackClick = () => {
    setCurrentVersionId(undefined)
    setPreviousVersionId(undefined)
  }

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      contentId: content.id,
      contentVersionId: currentVersion.id
    })
  )

  const publishingElements = (() => {
    if (isPublishingEnabled) {
      return (
        <Flex alignItems="center" gap={20} grow={1}>
          <Chip
            label={currentVersion.status}
            variant={contentVersionStatusVariants[currentVersion.status]}
          />
          {isPublished && (
            <Button
              label={isPublished ? 'Unpublish' : 'Publish'}
              variant={isPublished ? 'simple' : 'filled'}
              size="small"
              onClick={() => onPublishingClick()}
            />
          )}
          <Flex grow={1} />
          <Button
            label="Restore as current draft"
            size="small"
            mode="subtle"
            disabled={isCurrentVersion}
            onClick={() => onRestoreContent(currentVersion.id)}
          />
        </Flex>
      )
    }

    return (
      <Flex alignItems="center" justifyContent="space-between" grow={1}>
        <Chip
          label={currentVersion.status}
          variant={contentVersionStatusVariants[currentVersion.status]}
        />

        <Button
          label="Restore"
          size="small"
          disabled={isCurrentVersion}
          onClick={() => onRestoreContent(currentVersion.id)}
        />
      </Flex>
    )
  })()

  return (
    <>
      <Header
        backLinkLabel="Back to History"
        onBackClick={onBackClick}
        onCloseClick={onRequestClose}
        title={currentVersion.name}
        wide
      >
        <PublishingElementsWrapper grow={1} alignItems="stretch" justifyContent="space-between">
          {publishingElements}
        </PublishingElementsWrapper>
      </Header>
      <SubHeader wide>
        <VersionHeader />
      </SubHeader>
      <Body wide>
        {activeLocales.length ? (
          <Tabs>
            {activeLocales.map((activeLocale, index) => (
              <Tab key={activeLocale.identifier} label={activeLocale.name} index={index}>
                <VersionDiff
                  activeLocale={activeLocale.identifier}
                  defaultLocale={defaultLocale}
                />
              </Tab>
            ))}
          </Tabs>
        ) : (
          <VersionDiff
            activeLocale={Content.fallbackLocale}
            defaultLocale={Content.fallbackLocale}
          />
        )}
      </Body>
    </>
  )
}

export default VersionCompare
