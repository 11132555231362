import orderBy from 'lodash/orderBy'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useRef } from 'react'
import uuid from 'uuid-random'
import { BeforeCapture, DragDropContext, Draggable, DraggableChildrenFn, DraggableProvided, Droppable } from 'react-beautiful-dnd'
import { Field as FormField, FieldRenderProps, useField, useForm } from 'react-final-form'
import { object } from 'yup'

import DrawerBlock, { DRAWER_HEIGHT_CLOSED_SMALL } from 'components/blocks/DrawerBlock'
import FieldArray from 'components/form/FieldArray'
import FieldError from 'components/form/FieldError'
import FieldWrapper from 'components/contentEditors/generic/fields/FieldWrapper'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import GenericContentEditor from 'components/contentEditors/generic'
import HintBox from 'components/hints/HintBox'
import IconButton from 'components/buttons/IconButton'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import SectionLoader from 'components/loaders/SectionLoader'
import TextLink from 'components/links/TextLink'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { ContentTypeFragmentFragment as ContentTypeFragment, useContentTypeQuery, useFieldsListQuery } from 'generated/schema'
import { css, styled } from 'styles/stitches'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { FieldsListQuery } from 'generated/schema'
import type { TextInputProps } from 'components/inputs/TextInput'
import type { Locale } from 'hooks/useActiveLocales'

type Field = FieldsListQuery['fieldsList'][number]

type EmbeddedFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'text'> & {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  fieldRestrictions?: Field['fieldRestrictions']
}
type FieldData = { contentTypeId: string, position?: number, data: object }

const EMBEDDED_ACTION_VERTICAL_MARGIN = 10
const EMBEDDED_CONTAINER_VERTICAL_MARGIN = 5
const EMBEDDED_DRAWER_EXPANDED_MARGIN = 10

const StyledDeleteIcon = styled(IconButton, {
  color: 'dark200'
})

const StyledAddEmbeddedFieldContainer = styled(Flex, {
  whiteSpace: 'nowrap',
  width: '100%',
  flexWrap: 'wrap',
  marginTop: EMBEDDED_CONTAINER_VERTICAL_MARGIN,

  '& [data-icon]': {
    color: 'primary400',
    display: 'flex'
  },

  '& [data-name]': {
    color: 'dark500',
    marginBottom: EMBEDDED_ACTION_VERTICAL_MARGIN
  }
})

type NestedFieldContainerProps = FieldRenderProps<Record<string, any>, any> & {
  contentType?: ContentTypeFragment,
  contentTypesList: ContentTypeFragment[],
  currentLocale?: Locale,
  defaultLocale?: Locale,
  dragHandleProps: Pick<DraggableProvided, 'dragHandleProps'>,
  index?: number,
  isDragging?: boolean,
  isArray?: boolean,
  onRemove: () => void
}

const getDrawerOpenedClassName = (index?: number) => css({
  marginBottom: EMBEDDED_DRAWER_EXPANDED_MARGIN,
  marginTop: index ? EMBEDDED_DRAWER_EXPANDED_MARGIN : 0
})

// To ensure that drawer placeholder height is DRAWER_HEIGHT_CLOSED_SMALL
// even if the drawer being dragged is open
const onBeforeCapture = ({ draggableId }: BeforeCapture) => {
  const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement

  element.style.setProperty('height', `${DRAWER_HEIGHT_CLOSED_SMALL}px`, 'important') //
}

const getEmbeddedFieldSchema = (settings: EmbeddedFieldProps['settings'] = {}) => {
  let schema = object()
    // happens when converting from not-repeated > repeated
    .typeError('must be an object')

  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const initialData = {}

const NestedFieldContainer = ({
  contentType,
  contentTypesList,
  currentLocale,
  defaultLocale,
  dragHandleProps,
  index,
  input,
  isDragging,
  isArray,
  meta,
  onRemove
}: NestedFieldContainerProps) => {
  const { contentTypeId } = input.value
  const { initial } = meta
  const { change } = useForm()

  useEffect(() => {
    isArray && change(`${input.name}.position`, index)
  }, [ change, index, input.name, isArray ])

  const currentContentType = contentType || contentTypesList.find(({ id }) => id === contentTypeId)
  const currentTitleFieldId = currentContentType?.titleFieldId

  const {
    data: fieldsData,
    loading: fieldsLoading,
    error: fieldsError
  } = useFieldsListQuery({
    variables: {
      filter: {
        contentTypeId: { eq: currentContentType?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: FIELDS_LIST_LIMIT
    },
    skip: !currentContentType?.id,
    nextFetchPolicy: 'cache-and-network'
  })
  const { fieldsList = [] } = fieldsData || {}

  const titleField = fieldsList.find(({ id }) => currentTitleFieldId === id)
  const titleFieldId = titleField?.id
  const titleFieldKey = `${input.name}.data.${titleFieldId}.${titleField?.isTranslatable ? currentLocale?.identifier || '' : defaultLocale?.identifier || ''}`

  const removeField = () => {
    if (isArray) onRemove()
    else {
      input.onChange(undefined)
    }
  }

  const drawerAction = (
    <StyledDeleteIcon
      onClick={removeField}
      variant="dark"
      description="remove"
      name="trash"
      size={16}
    />
  )

  const error = FieldError.getError(meta)

  return (
    <>
      {currentContentType && (
        <FormValuesField fieldNames={[ titleFieldKey ]}>
          {(values) => (
            <DrawerBlock
              as={Flex}
              dragHandleProps={dragHandleProps}
              drawerAction={drawerAction}
              headerHeight="small"
              headerPadding="small"
              defaultOpened={!isDragging && !initial}
              title={currentContentType?.name!}
              openedClassName={getDrawerOpenedClassName(index)}
              subtitle={values[titleFieldKey]}
            >
              {() => (
                <Flex direction="column">
                  <FormField name={`${input.name}.contentTypeId`} component="input" type="hidden" initialValue={currentContentType?.id} />
                  <FormField name={`${input.name}.data`} size="small" component="input" type="hidden" alwaysDirty initialValue={initialData} />
                  <FormField name={`${input.name}.isEmbedded`} size="small" component="input" type="hidden" alwaysDirty initialValue="true" />

                  <Flex gap={20} direction="column">
                    <SectionLoader
                      empty={{ title: 'There are no fields.' }}
                      loading={fieldsLoading}
                      error={fieldsError}
                      data={fieldsList}
                    >
                      <GenericContentEditor
                        currentLocale={currentLocale}
                        defaultLocale={defaultLocale}
                        fieldsList={fieldsList}
                        fieldPrefix={input.name}
                      />
                    </SectionLoader>
                  </Flex>
                </Flex>
              )}
            </DrawerBlock>
          )}
        </FormValuesField>
      )}
      {error && typeof error === 'string' && <HintBox size="small" variant="error" icon="error">{error}</HintBox>}
    </>
  )
}

const EmbeddedField = ({
  currentLocale,
  defaultLocale,
  label,
  name,
  fieldRestrictions = [],
  helpText,
  isArray,
  isTranslatable,
  settings,
  shouldValidate
}: EmbeddedFieldProps) => {
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  const { input } = useField(name)
  const { change } = useForm()
  const { contentTypeId } = input.value

  const validate = useCallback((value) => getEmbeddedFieldSchema(settings)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message),
  [ settings ])

  // The embedded field value from backend isn't sorted by position
  // Need to remove this when we get values in order of position
  useComponentDidMount(() => {
    if (Array.isArray(input.value)) {
      const sortedInputValue = orderBy(input.value, [ 'position' ], [ 'asc' ])
      change(input.name, sortedInputValue)
    }
  })

  const {
    data: contentTypeData
  } = useContentTypeQuery({
    variables: {
      id: contentTypeId
    },
    skip: !contentTypeId
  })

  const currentContentType = contentTypeData?.contentType

  const isPolymorphic = fieldRestrictions.length > 1

  const contentTypesList = fieldRestrictions.map(({ contentType }) => contentType)

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const addEmbeddedField = (contentType: ContentTypeFragment) => {
    if (isArray) {
      fieldsRef.current?.fields.push({ contentTypeId: contentType.id, data: {} })
    } else change(name, { contentTypeId: contentType.id })
  }

  const showAddEmbeddedFieldAction = isArray || !currentContentType

  const renderDraggableChildren: DraggableChildrenFn = (provided, snapshot, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FormField
        currentLocale={currentLocale}
        defaultLocale={defaultLocale}
        component={NestedFieldContainer}
        dragHandleProps={provided.dragHandleProps}
        isArray
        index={rubric.source.index}
        contentTypesList={contentTypesList}
        isDragging={snapshot.isDragging}
        name={FieldArray.getFieldName(name, rubric.source.index)}
        onRemove={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        {...(shouldValidate && { validate })}
      />
    </div>
  )

  return (
    <FieldWrapper label={label} isTranslatable={isTranslatable}>
      {helpText && <InputHelpText helpText={helpText} />}
      {!isArray && (
        <FormField
          currentLocale={currentLocale}
          defaultLocale={defaultLocale}
          name={name}
          component={NestedFieldContainer}
          contentType={currentContentType}
          contentTypesList={contentTypesList}
          {...(shouldValidate && { validate })}
        />
      )}

      {isArray && (
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable
            renderClone={renderDraggableChildren}
            droppableId={droppableId.current}
          >
            {(droppableProvided) => (
              <Flex
                direction="column"
                gap={2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={name}
                  fieldsRef={fieldsRef}
                  settings={settings}
                  shouldValidate={shouldValidate}
                >
                  {({ keys }) => keys.map((key, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={key}
                      index={index}
                      key={key}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                </FieldArray>
                {droppableProvided.placeholder}
                <div />
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {showAddEmbeddedFieldAction && (
        <>
          <StyledAddEmbeddedFieldContainer
            alignItems="center"
            gap={10}
          >
            {contentTypesList.map((contentType) => (
              <TextLink
                fontSize={12}
                data-name
                as="button"
                type="button"
                variant="underlined"
                mode="subtle"
                fontWeight="bold"
                onClick={() => addEmbeddedField(contentType)}
                key={contentType.name}
              >
                {isArray ? 'Add' : 'Enter'} {pluralize.singular((!isPolymorphic ? label : contentType.name) || '')}
              </TextLink>
            ))}
          </StyledAddEmbeddedFieldContainer>

          {/* TODO: Revamp this popover once design is finalized}
          {/* <PopoverContainer
            placement="bottom-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [ 0, 5 ]
                }
              }
            ]}
            strategy="absolute"
          >
            {({ isActive, closePopover, openPopover, ...rest }) => (
              <StyledAddEmbeddedFieldContainer
                {...rest}
                alignItems="center"
                gap={10}
              >
                <Text
                  fontSize={12}
                  data-name
                >
                  Add New {title}
                </Text>
                <Icon name="arrow-down" data-icon size={12} />
              </StyledAddEmbeddedFieldContainer>
            )}
            {(popoverProps) => (
              <Popover {...popoverProps} className={classes.contentTypesPopover}>
                {contentTypesList.map((contentType) => (
                  <Fragment key={contentType.name}>
                    <PopoverItem
                      onClick={() => addEmbeddedField(contentType)}
                      text={contentType.name}
                    />
                    <PopoverDivider />
                  </Fragment>
                ))}
              </Popover>
            )}
          </PopoverContainer> */}
        </>
      )}
    </FieldWrapper>
  )
}

export type { EmbeddedFieldProps }
export default EmbeddedField
