import React from 'react'

import * as mixins from 'styles/mixins'
import AccountAvatar from 'components/avatars/AccountAvatar'
import Divider from 'components/divider/Divider'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import { Popover, PopoverDivider, PopoverItem } from 'components/popover'
import type { Account } from 'generated/schema'
import type { PopoverProps } from 'components/popover'

const MENU_BORDER_RADIUS = 9
const MENU_WIDTH = 194

const classes = {
  accountAvatar: css({
    borderTopLeftRadius: MENU_BORDER_RADIUS,
    size: [ MENU_WIDTH ]
  }),
  popover: css({
    background: `linear-gradient(0deg, ${colorVars.primary400} 0%, ${colorVars.primary300} 100%)`,
    borderRadius: 0,
    borderTopLeftRadius: MENU_BORDER_RADIUS,
    borderBottomLeftRadius: MENU_BORDER_RADIUS,
    width: MENU_WIDTH,
    filter: 'blur(0px)'
  }),
  popoverItem: css({
    paddingY: 20,
    paddingX: 36,
    [`${Text}`]: {
      ...mixins.transition('simple'),
      color: 'light100',
      opacity: 0.6
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      [`${Text}`]: {
        color: 'light100',
        fontWeight: 'bold',
        opacity: 1
      }
    }
  }),
  divider: css({
    '::before': {
      backgroundColor: rgba(colorVars.primary100rgb, 0.1)
    }
  })
}

type AccountMenuProps = PopoverProps & {
  account: Pick<Account, 'firstName' | 'lastName' | 'email'>
}

const AccountMenuItem = ({ text, to }: { text: string, to: string }) => (
  <PopoverItem className={classes.popoverItem} data-menu-item to={to}>
    <Text as="span">{text}</Text>
  </PopoverItem>
)

const Spacer = () => <Divider variant="whitespace" spacing="6" />

const AccountMenu = React.forwardRef<HTMLDivElement, AccountMenuProps>(
  ({ account, ...menuProps }, ref) => (
    <Popover className={classes.popover} ref={ref} {...menuProps}>
      <AccountAvatar className={classes.accountAvatar} account={account} variant="full" />
      <Spacer />
      <AccountMenuItem text="Profile" to="/~personal/profile" />
      <AccountMenuItem text="Notifications" to="/~personal/notifications" />
      <AccountMenuItem text="Settings" to="/~personal/settings" />
      <PopoverDivider className={classes.divider} spacing="12" />
      <AccountMenuItem text="LOGOUT" to="/logout" />
      <Spacer />
    </Popover>
  )
)

AccountMenu.displayName = 'AccountMenu'

export default AccountMenu
