import classNames from 'classnames'
import React, { createContext, useMemo, useReducer } from 'react'
import type { ReactElement, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import { BaseModal } from 'components/modal'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import { SIDE_PANE_WIDTH } from './constants'
import { SidePaneBody, SidePaneDivider, SidePaneFooter, SidePaneHeader, SidePaneSearchBar, SidePaneSubHeader } from 'components/sidePane'
import type { BaseModalProps } from 'components/modal'

type SidePaneRenderProps = {
  Body: typeof SidePaneBody,
  Divider: typeof SidePaneDivider,
  Footer: typeof SidePaneFooter,
  Header: typeof SidePaneHeader,
  SearchBar: typeof SidePaneSearchBar,
  SubHeader: typeof SidePaneSubHeader
}

type SidePaneProps = BaseModalProps & {
  children: ((props: SidePaneRenderProps) => ReactNode) | ReactElement | ReactElement[]
}

type Measurements = typeof initialMeasurements

type Dispatch = (measurements: Measurements) => void

const classes = {
  contentBase: css({
    ...mixins.shadow('xxLarge', colorVars.dark1100rgb, 0.1),

    backgroundColor: 'light400',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    transform: 'translateX(100%)',
    width: SIDE_PANE_WIDTH
  }),
  contentAfterOpen: css({
    '&&': { transform: 'translateX(0)' }
  }),
  contentBeforeClose: css({
    '&&&': { transform: 'translateX(100%)' }
  })
}

const initialMeasurements = {
  offsetHeight: 0,
  scrollHeight: 0,
  scrollTop: 0
}

const SidePaneStateContext = createContext(initialMeasurements)
const SidePaneDispatchContext = createContext<Dispatch | undefined>(undefined)

const reducer = (state: Measurements, action: Measurements) => action

function SidePaneContextProvider({ children }: Pick<SidePaneProps, 'children'>) {
  const [ measurements, updateMeasurements ] = useReducer(reducer, initialMeasurements)

  // We don't wanna re-render entire subtree on measurements change.
  const memoizedChildren = useMemo(() => {
    if (typeof children !== 'function') {
      return children
    }

    return children({
      Body: SidePaneBody,
      Divider: SidePaneDivider,
      Footer: SidePaneFooter,
      Header: SidePaneHeader,
      SearchBar: SidePaneSearchBar,
      SubHeader: SidePaneSubHeader
    })
  }, [ children ])

  return (
    <SidePaneStateContext.Provider value={measurements}>
      <SidePaneDispatchContext.Provider value={updateMeasurements}>
        {memoizedChildren}
      </SidePaneDispatchContext.Provider>
    </SidePaneStateContext.Provider>
  )
}

function SidePane({ children, ...other }: SidePaneProps) {
  return (
    <BaseModal
      {...other}
      contentBaseClassName={classNames(
        classes.contentBase,
        other.contentBaseClassName
      )}
      contentAfterOpenClassName={classNames(
        classes.contentAfterOpen,
        other.contentAfterOpenClassName
      )}
      contentBeforeCloseClassName={classNames(
        classes.contentBeforeClose,
        other.contentBeforeCloseClassName
      )}
    >
      <SidePaneContextProvider>
        {children}
      </SidePaneContextProvider>
    </BaseModal>
  )
}

export default SidePane

export {
  SidePaneContextProvider,
  SidePaneDispatchContext,
  SidePaneStateContext
}

export type { SidePaneRenderProps }
