import React, { forwardRef, Fragment } from 'react'

import * as mixins from 'styles/mixins'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import { css, styled } from 'styles/stitches'
import { INPUT_HEIGHT, INPUT_PADDING_X } from 'components/inputs/TextInput'
import Divider from 'components/divider/Divider'

type InputType =
  | 'MM'
  | 'DD'
  | 'YY'
  | 'YYYY'
  | 'WW'
  | 'hh'
  | 'HH'
  | 'mm'
  | 'ss'
  | 'A'

type InputFieldValue = Record<InputType, string | undefined>

const INPUT_WRAPPER_BORDER_RADIUS = 4
const INPUT_WRAPPER_BORDER_WIDTH = 1
const WIDTH_PER_LETTER = 15
const SEPARATORS = [ '/', '.', '-', ':' ]

const inputLengths: Record<InputType, number> = {
  MM: 2,
  DD: 2,
  YY: 2,
  YYYY: 4,
  WW: 2,
  hh: 2,
  HH: 2,
  mm: 2,
  ss: 2,
  A: 2
} as const

const StyledContainer = styled(Flex, {
  width: '100%'
})

const StyledInputWrapper = styled(Flex, {
  ...mixins.transition('simple'),

  borderRadius: INPUT_WRAPPER_BORDER_RADIUS,
  borderStyle: 'solid',
  borderWidth: INPUT_WRAPPER_BORDER_WIDTH,
  fontSize: 0,
  position: 'relative',
  width: '100%',

  '&:focus-within > [data-icon]': {
    color: 'dark700'
  },

  variants: {
    size: {
      small: {
        height: INPUT_HEIGHT.small,
        paddingLeft: INPUT_PADDING_X.small,
        paddingRight: INPUT_PADDING_X.small
      },
      normal: {
        height: INPUT_HEIGHT.normal,
        paddingLeft: INPUT_PADDING_X.normal,
        paddingRight: INPUT_PADDING_X.normal
      },
      large: {
        height: INPUT_HEIGHT.large,
        paddingLeft: INPUT_PADDING_X.large,
        paddingRight: INPUT_PADDING_X.large
      }
    },
    disabled: {
      true: {
        backgroundColor: 'light400',
        borderColor: 'dark100',

        '&:hover, &:focus': {
          borderColor: 'dark100'
        }
      }
    },
    error: {
      true: {
        backgroundColor: 'negative100',
        borderColor: 'negative300',

        '&:hover': {
          borderColor: 'negative300'
        }
      }
    },
    normal: {
      true: {
        backgroundColor: 'light100',
        borderColor: 'dark100',

        '&:hover, &:focus': {
          borderColor: 'dark300'
        }
      }
    }
  }
})

const StyledIcon = styled('div', {
  ...mixins.transition('simple'),

  color: 'dark100',
  pointerEvents: 'none',
  variants: {
    error: {
      true: {
        '&&': {
          color: 'negative500'
        }
      }
    }
  }
})

const StyledClearIndicator = styled('div', {
  cursor: 'pointer',
  padding: 8,
  '& [data-icon]': {
    ...mixins.transition('fluid'),

    color: 'dark300'
  },

  '&:hover [data-icon]': {
    color: 'dark700'
  },
  variants: {
    hasError: {
      true: {
        '& [data-icon]': {
          color: 'negative300'
        },

        '&:hover [data-icon]': {
          color: 'negative500'
        }
      }
    }
  }

})

const StyledDurationInput = styled('input', {
  ...mixins.transition('simple'),

  backgroundColor: 'transparent',
  border: 'none',
  fontFamily: 'normal',
  fontSize: 14,
  fontWeight: 'regular',
  lineHeight: 'normal',
  letterSpacing: 2,
  padding: 0,
  textAlign: 'center',

  '&[disabled]': {
    color: 'dark500'
  },

  '&::placeholder': {
    fontWeight: 'regular',
    textAlign: 'center'
  },
  variants: {
    error: {
      true: {
        color: 'negative500',

        '&::placeholder': {
          color: 'negative400'
        },

        '&:focus': {
          '&::placeholder': {
            color: 'negative400'
          }
        }
      }
    },
    normal: {
      true: {
        color: 'dark900',

        '&::placeholder': {
          color: 'dark500'
        },

        '&:focus': {
          '&::placeholder': {
            color: 'dark100'
          }
        }
      }
    }
  }
})

const StyledSeparator = styled('span', {
  color: 'dark100',
  fontSize: 14,
  lineHeight: 0,
  paddingRight: 5,
  paddingLeft: 5,
  pointerEvents: 'none',
  verticalAlign: 'middle',
  variants: {
    error: {
      true: {
        color: 'negative300'
      }
    }
  }
})

const spacer = css({
  paddingX: 4
})

const inferDateTimeComponents = (inputFormat: string) => {
  if (inputFormat.includes(' ')) {
    if (inputFormat.includes('/')) {
      return inputFormat.split(' ')
    }

    return [ '', ...inputFormat.split(' ') ]
  }

  if (inputFormat.includes('/')) {
    return [ inputFormat, '' ]
  }

  return [ '', inputFormat ]
}

const initialFieldValues = {
  MM: '',
  DD: '',
  YYYY: '',
  YY: '',
  WW: '',
  HH: '',
  hh: '',
  mm: '',
  ss: '',
  A: ''
}

const BaseDurationInput = forwardRef<any, any>(({
  children,
  disabled,
  input,
  meta,
  helpText,
  isTranslatable,
  checkRequired = false,
  isClearable,
  label,
  size = 'normal',
  inputFormat = 'HH:mm:ss', // | YYYY/MM/DD HH:mm,
  icon = 'clock', // | "calendar",

  onInputBlur,
  inputsRef,
  inputValue,
  padInputValue,
  shouldEarlyReturn,
  setInputValue,
  getInputPlaceholder = (inputType: InputType) => inputType.toLocaleUpperCase(),

  ...others
}, ref) => {
  const hasError = !!FieldError.getError(meta)

  const [ dateComponent, timeComponent, meridiemComponent ] = inferDateTimeComponents(inputFormat)

  const dateFormatInputCount = dateComponent.split('/').length
  const timeFormatInputCount = timeComponent.split(':').length

  const focusPreviousDateInput = (currentEl: HTMLInputElement) => {
    const currentIndex = inputsRef.current.indexOf(currentEl)

    if (currentIndex > 0) {
      const targetElement = inputsRef.current[currentIndex - 1]

      if (!targetElement) {
        return
      }

      const { length } = targetElement.value

      currentEl.tabIndex = -1

      targetElement.focus()
      setTimeout(() => targetElement.setSelectionRange(length, length))
    }
  }

  const focusNextDateInput = (currentEl: HTMLInputElement) => {
    const currentIndex = inputsRef.current.indexOf(currentEl)

    const inputTypes = [
      ...dateComponent.split('/'),
      ...(timeComponent?.split(':') || []),
      ...(meridiemComponent || [])
    ]

    if (inputTypes && currentIndex < inputTypes.length - 1) {
      const targetElement = inputsRef.current[currentIndex + 1]

      if (!targetElement) {
        return
      }

      currentEl.tabIndex = -1

      targetElement.focus()
      setTimeout(() => targetElement.setSelectionRange(0, 0))
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const targetElement = e.currentTarget

    if (targetElement.value.length > 0) {
      setInputValue((val: any) => ({
        ...val,
        [targetElement.dataset.inputType!]: targetElement.value.padStart(targetElement.maxLength, '0')
      }))
    }

    onInputBlur?.(e)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const targetElement = e.target
    if (!targetElement) {
      return
    }

    inputsRef.current.forEach((el: any) => { if (el) el.tabIndex = -1 })

    targetElement.tabIndex = 0
    targetElement.setSelectionRange(2, 2)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const targetElement = e.currentTarget
    const disallowedKeys = [
      'ArrowUp', 'ArrowDown',
      '+', '-', '.', 'e'
    ]

    if (disallowedKeys.indexOf(e.key) !== -1) {
      e.preventDefault()
      return
    }

    if (e.key === 'ArrowLeft'
      && (targetElement.selectionStart === 0 || targetElement.value.length === 0)) {
      e.preventDefault()
      focusPreviousDateInput(targetElement)
    } else if (e.key === 'ArrowRight'
      && (targetElement.selectionEnd === targetElement.value.length || !targetElement.value.length)
    ) {
      e.preventDefault()
      focusNextDateInput(targetElement)
    } else if (e.key === 'Backspace' && targetElement.value.length === 0) {
      e.preventDefault()
      focusPreviousDateInput(targetElement)
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (SEPARATORS.indexOf(e.key) !== -1 && e.currentTarget.value.length > 0) {
      e.preventDefault()
      focusNextDateInput(e.currentTarget)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const targetElement = e.currentTarget
    const allowedKeys = [
      '1', '2', '3', '4', '5',
      '6', '7', '8', '9', '0'
    ]
    const allowedMeridiemKeys = [
      'a', 'p', 'A', 'P', 'm', 'M'
    ]

    if (targetElement.dataset.inputType === 'A') {
      if (allowedMeridiemKeys.indexOf(e.key) === -1) {
        e.preventDefault()
      }
    } else if (allowedKeys.indexOf(e.key) === -1) {
      e.preventDefault()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const type = e.target.dataset.inputType as keyof InputFieldValue

    let value = e.target.value.toUpperCase()
    if (type === 'A') {
      switch (value) {
        case 'A':
        case 'P':
          break
        case 'M':
          value = ''
          break
        case 'AP':
          value = 'PM'
          break
        case 'PA':
          value = 'AM'
          break
      }
    }

    const newInputValue = {
      ...inputValue,
      [type]: value
    }

    if (
      newInputValue[type]
      // Do not pad when backspace pressed
      && (newInputValue[type]!.length > inputValue[type]!.length)
    ) {
      padInputValue(newInputValue, type, inputFormat)
    }

    if (shouldEarlyReturn(newInputValue, type, inputFormat)) {
      return
    }

    setInputValue(newInputValue)

    if ((newInputValue[type]?.length || 0) >= type.length) {
      focusNextDateInput(e.currentTarget)
    }

    e.preventDefault()
  }

  const renderInput = (inputType: InputType, index: number, length?: number) => {
    const inputCharLength = length ?? inputType.length
    return (
      <StyledDurationInput
        data-input-type={inputType}
        disabled={disabled}
        error={hasError}
        maxLength={inputLengths[inputType]}
        minLength={0}
        normal={!(disabled || hasError)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
        onKeyUp={handleKeyUp}
        placeholder={getInputPlaceholder(inputType)}
        ref={(el: HTMLInputElement) => { inputsRef.current[index] = el }}
        style={{ width: inputCharLength > 3
          ? (inputCharLength - 1) * WIDTH_PER_LETTER
          : inputCharLength * WIDTH_PER_LETTER }}
        tabIndex={index === 0 ? 0 : -1}
        type="text"
        value={inputValue?.[inputType] || ''}
      />
    )
  }

  const renderDurationInputs = (format: string) => {
    const inputTypes = format.split('/') as (InputType)[]

    return inputTypes.map((inputType, index) => (
      <Fragment key={inputType}>
        {renderInput(inputType, index)}
        {index < inputTypes.length - 1 && (
          <StyledSeparator error={hasError}>
            /
          </StyledSeparator>
        )}
      </Fragment>
    ))
  }

  const renderTimeInputs = (format: string, startIndex: number) => {
    const inputTypes = format.split(':') as (InputType)[]
    let index = startIndex

    return inputTypes.map((inputType) => {
      index += 1

      return (
        <Fragment key={inputType}>
          {renderInput(inputType, index)}
          {index < startIndex + inputTypes.length && (
            <StyledSeparator error={hasError}>
              :
            </StyledSeparator>
          )}
        </Fragment>
      )
    })
  }

  const renderMeridiemComponent = (index: number) => (
    <>
      {renderInput('A', index, 2)}
    </>
  )

  return (
    <StyledContainer
      as="label"
      direction="column"
      gap={10}
      onFocus={input.onFocus}
      onBlur={(e: React.FocusEvent<HTMLLabelElement>) => {
        const currentTarget = e.currentTarget as HTMLElement
        setTimeout(() => {
          if (currentTarget.contains(document.activeElement)) {
            return
          }
          input.onBlur()
        })
      }}
      {...(!icon && { ref })}
    >
      {label && (
        <FieldLabel isTranslatable={isTranslatable}>
          {label}{checkRequired && <span> *</span>}
        </FieldLabel>
      )}
      <StyledInputWrapper
        {...others}
        alignItems="center"
        disabled={disabled}
        error={hasError}
        normal={!(disabled || hasError)}
        justifyContent="space-between"
        size={size}
        gap={8}
      >
        <Flex alignItems="center">
          {dateComponent && renderDurationInputs(dateComponent)}
          {dateComponent && timeComponent && <span className={spacer} />}
          {timeComponent && renderTimeInputs(timeComponent, dateFormatInputCount - 1)}
          {timeComponent && meridiemComponent && <span className={spacer} />}
          {timeComponent
            && meridiemComponent
            && renderMeridiemComponent(dateFormatInputCount + timeFormatInputCount)}
        </Flex>
        {(isClearable || icon) && (
        <Flex alignSelf="stretch" alignItems="center">
          {isClearable && input.value && (
          <>
            <StyledClearIndicator
              hasError={meta.error}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                // To prevent opening popup on clearing date
                e.preventDefault()
                input.onChange(null)
                setInputValue(initialFieldValues)
              }}
            >
              <Icon data-icon name="cross" size={8} />
            </StyledClearIndicator>
            <Divider spacing={8} variant="ruler" orientation="vertical" />
          </>
          )}
          {icon && (
            <StyledIcon
              ref={ref}
              data-icon
              error={hasError}
            >
              <Icon name={icon} size={16} />
            </StyledIcon>
          )}
        </Flex>
        )}
        <input {...input} type="hidden" />
        <FieldError error={FieldError.getError(meta)} />
      </StyledInputWrapper>
      {helpText && (<InputHelpText helpText={helpText} />)}
    </StyledContainer>
  )
})

export type { InputType, InputFieldValue }

export { inferDateTimeComponents, initialFieldValues }

export default BaseDurationInput
