import arrayMutators from 'final-form-arrays'
import capitalize from 'lodash/capitalize'
import createDecorator from 'final-form-calculate'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { atom, useSetRecoilState } from 'recoil'
import { Form } from 'react-final-form'
import { string } from 'yup'
import type { Decorator, FormApi } from 'final-form'
import type { FormProps } from 'react-final-form'

import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import componentLoader from 'lib/componentLoader'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import isIdentifier from 'lib/formValidators/isIdentifier'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import pascalCase from 'lib/pascalCase'
import Text from 'components/typography/Text'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { ContentTypeDocument, FieldsListDocument, FieldsListQuery, useCreateFieldMutation, useFieldTypesListQuery, useUpdateFieldMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { DATE_FORMATS, NUMBER_MODE, NUMBER_RANGE } from 'components/contentEditors/generic/fields/fieldProps'
import { DEFAULT_MIN_ROWS } from 'components/inputs/TextAreaInput'
import { FieldIdentifier } from 'models/Field'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import type { CreateFieldInput, FieldType, UpdateFieldInput } from 'generated/schema'
import type { ViewStyleComponentRenderProps, ViewProps } from 'components/views'

type FormValues = CreateFieldInput | UpdateFieldInput
type Field = FieldsListQuery['fieldsList'][number]
type Restrictions = Field['fieldRestrictions'][number]['contentType'][]

type Params = {
  initialValues?: Partial<Field> | { contentTypeId: string, position: number },
  installationId?: string,
  onCreateFieldCompleted?: (field: Field) => void,
  onUpdateFieldCompleted?: (field: Field) => void
}

const blacklistedContentTypesState = atom<string[]>({
  key: 'blacklisted-content-types',
  default: []
})

const defaultFormValues: Partial<Record<FieldIdentifier, any>> = {
  [FieldIdentifier.TEXT]: {
    settings: {
      multilineVisibleRows: DEFAULT_MIN_ROWS
    }
  },
  [FieldIdentifier.NUMBER]: {
    settings: {
      mode: NUMBER_MODE.INTEGER,
      valueComparator: NUMBER_RANGE.BETWEEN
    }
  },
  [FieldIdentifier.DATE]: {
    settings: {
      format: DATE_FORMATS.MM_DD_YYYY
    }
  }
}

const setIdentifierDecorator = createSetIdentifier<FormValues>('name', 'identifier')

const validate = (values: any) => BaseModel.validateSchema({
  name: string().required(),
  identifier: isIdentifier()
}, values)

const customDecorator = createDecorator(
  {
    field: 'isArray',
    updates: {
      'settings.checkUniqueness': (isArray, allValues) => {
        // `undefined` so it gets stripped
        if (isArray) return undefined
        return get(allValues || {}, 'settings.checkUniqueness')
      }
    }
  },
  {
    field: 'restrictions',
    updates: {
      defaultValue() {
        return undefined
      }
    }
  },
  {
    field: 'isArray',
    updates: {
      defaultValue() {
        return undefined
      }
    }
  }
) as unknown as Decorator<FormValues>

const fieldTypeFactoryWithFallback = (fieldTypeName: string) => () => componentLoader(`fieldViews/${fieldTypeName}`, { suppressAlert: true })
  .catch(() => componentLoader('fieldViews/GenericFieldView'))

const getFieldViewName = (fieldType?: FieldType) => {
  if (!fieldType) return ''

  const { identifier } = fieldType
  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

function AddFieldView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const initialValues = params?.initialValues || {}
  const installationId = params?.installationId
  const isUpdating = 'id' in initialValues
  const initialFieldType = 'fieldType' in initialValues ? initialValues.fieldType : undefined

  const [ step, setStep ] = useState(isUpdating ? 2 : 1)
  const [ selectedFieldType, setSelectedFieldType ] = useState<FieldType>()

  const setBlacklistedContentType = useSetRecoilState(blacklistedContentTypesState)

  const title = (() => {
    const operation = isUpdating ? 'Edit' : 'New'
    const selectedFieldTypeName = step === 2 && selectedFieldType?.name

    return `${operation} ${selectedFieldTypeName || ''} Field`
  })()

  const { data, error, loading } = useFieldTypesListQuery()

  const { fieldTypes } = data || {}

  /**
   * - This block helps avoiding circular dependency when Reference/Embedded fields are used.
   *
   * How circular dependency can arise from reference and embedded fields?
   * 1. Reference Field (assume the current contentType is CT1)
   *   - Choose the CT1 from the restriction list. (this can be avoided easily)
   *   - Create an embedded field -> Create new content type -> Add a reference field to it
   *        -> Choose CT1 from the list
   *     This can happen at any level of the embedded field
   *
   * 2. Embedded Field
   *   - Create an embedded field -> Add new content type (EM1) -> add a new embedded field to it
   *      -> choose EM1 from the list
   *     This EM1 is available to every level and any other embedded contentType created in the
   *     tree would also be available to all the child embedded fields thus creating a
   *     circular dependency.
   *
   * Solution
   * When we try to create a new Field, we add the contentTypeId in a recoil state to
   * keep track of all the contentTypes that the child embedded / reference field
   * should avoid showing in their list.
   *
   * Here the blacklistedContentTypes stores all the contentTypes that should be
   * avoided down the embedded / reference tree structure path.
   *
   * Eg -
   *  CT -> Add Field F1 (embedded) -> Add content type (EM1) -> Add Field EM1-F1 (embedded)
   *        -> Add content type (EM2) -> Add Field EM2-F2 (embedded)
   *  At EM2-F2 the blacklist array would have - [ CT, EM1, EM2 ]
   *  so the list EM2-F2 would show all the embedded content types except the above.
   *
   * Upon unmounting of this component, we remove the associated contentType
   * Reason - Any embedded content type created deep down should be available to
   *          parent embedded fields
   */
  useComponentDidMount(() => {
    const { contentTypeId } = initialValues
    setBlacklistedContentType((prevState) => [ ...prevState, contentTypeId ])

    return () => (
      setBlacklistedContentType((prevState) => prevState.filter((id) => id !== contentTypeId))
    )
  })

  useEffect(() => {
    const fieldType = fieldTypes?.find(
      (fieldTypeCurrent) => {
        const fieldViewName = getFieldViewName(fieldTypeCurrent)
        // preload fieldViews
        fieldTypeFactoryWithFallback(fieldViewName)()

        return fieldTypeCurrent.identifier === initialFieldType
      }
    )

    if (fieldType) {
      setSelectedFieldType(fieldType)
    }
  }, [ fieldTypes, initialFieldType ])

  const groupedFieldTypes = useMemo(() => {
    const fieldsWithoutRelationship = fieldTypes?.filter((field) => field.identifier !== 'relationship-field')
    const orderedFields = orderBy(fieldsWithoutRelationship, 'position')
    return groupBy(orderedFields, 'category')
  }, [ fieldTypes ])

  const { contentTypeId } = initialValues
  const refetchQueries = [ { query: ContentTypeDocument, variables: { id: contentTypeId } } ]

  const [ createField ] = useCreateFieldMutation({
    onCompleted: ({ createField: createFieldData }) => {
      params.onCreateFieldCompleted?.(createFieldData)
      onRequestClose()
    },
    refetchQueries
  })

  const [ updateField ] = useUpdateFieldMutation({
    onCompleted: ({ updateField: updateFieldData }) => {
      params.onUpdateFieldCompleted?.(updateFieldData)
      onRequestClose()
    },
    refetchQueries
  })

  const handleCreateFieldSubmit = useSubmitHandler(createField, {
    successAlert: {
      message: 'Field successfully created!'
    },
    update: {
      strategy: 'APPEND',
      query: FieldsListDocument,
      dataKey: 'fieldsList',
      mutation: 'createField',
      queryVariables: {
        filter: {
          contentTypeId: { eq: initialValues?.contentTypeId }
        },
        order: [ {
          position: 'asc'
        } ],
        limit: FIELDS_LIST_LIMIT
      }
    }
  })

  const handleUpdateFieldSubmit = useSubmitHandler(updateField, {
    successAlert: {
      message: 'Field successfully updated!'
    },
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateField',
      typename: 'Field',
      override: (values: UpdateFieldInput) => ({
        ...initialFormValues,
        ...values
      })
    }
  })

  const handleFieldFormSubmit = (formValues: FormValues, form: FormProps<FormValues>['form']) => {
    // TODO: Don't strip defaultValue once backend support is
    // introduced for non-string defaultValue field
    const valuesWithoutDefaultValueField = omit(formValues, 'defaultValue')
    const valuesWithoutEmbeddedField = omit(valuesWithoutDefaultValueField, 'embeddedFields')
    // we are populating restrictions with fiedsRestriction,
    // hence restrictions needs to be transformed
    const {
      restrictions,
      ...valuesWithoutFieldReferenceRestictions
    } = valuesWithoutEmbeddedField

    const valuesWithReferenceRestictions = {
      ...valuesWithoutFieldReferenceRestictions,
      // If user clears restrictions we want to send `null` so backend knows there's no restriction
      // `undefined` gets stripped out at stringify stage
      restrictions: restrictions?.map((restriction) => restriction?.id) || null
    }

    if (isUpdating) {
      return handleUpdateFieldSubmit(
        valuesWithReferenceRestictions as unknown as UpdateFieldInput,
        form as FormApi<UpdateFieldInput>
      )
    }
    return handleCreateFieldSubmit(valuesWithReferenceRestictions as CreateFieldInput)
  }

  const renderMediaCard = (field: FieldType, groupIndex: number, typesIndex: number) => (
    <MediaCard
      autoFocus={
        (groupIndex === 0 && typesIndex === 0) || selectedFieldType?.identifier === field.identifier
      }
      key={field.identifier}
      media={field.icon}
      onClick={() => {
        setSelectedFieldType(field)
        setStep(step + 1)
      }}
      title={field.name}
      width="full"
    />
  )

  const groupTypes = [ 'BASIC', 'ADVANCED', 'OTHER' ]

  const fieldTypeView = ({ Body, Header, SubHeader, Footer }: ViewStyleComponentRenderProps) => (
    <>
      <Header
        onCloseClick={onRequestClose}
        title={title}
      />
      <SubHeader>
        <Text fontWeight="bold">Step 1: Select a template</Text>
      </SubHeader>

      <Body>
        <PageLoader
          data={fieldTypes}
          error={error}
          loading={loading}
        >
          <Flex direction="column" gap={14}>
            {groupTypes.map((TYPE, groupIndex) => {
              if (!groupedFieldTypes?.[TYPE]?.length) return null

              return (
                <div key={TYPE}>
                  <Text fontWeight="bold">{capitalize(TYPE)} fields</Text>
                  <Divider spacing={10} variant="whitespace" />
                  <Grid columnGap={20} rowGap={20} columns={2}>
                    {groupedFieldTypes[TYPE].map(
                      (field, typesIndex) => renderMediaCard(field, groupIndex, typesIndex)
                    )}
                  </Grid>
                  <Divider spacing={10} variant="whitespace" />
                </div>
              )
            })}
          </Flex>
        </PageLoader>
      </Body>
      <Footer>
        <Flex alignItems="center" grow={1} justifyContent="space-between">
          <Chip label="Step 1" icon="arrow-right" iconPlacement="right" variant="light" />
          <Flex gap={14}>
            <Button disabled icon="arrow-left" />
            <Button
              disabled={!selectedFieldType}
              icon="arrow-right"
              onClick={() => setStep(step + 1)}
            />
          </Flex>
        </Flex>
      </Footer>
    </>
  )

  const fieldViewName = getFieldViewName(selectedFieldType)
  const FieldViewComponent = useMemo(() => (
    selectedFieldType?.name
      ? React.lazy(fieldTypeFactoryWithFallback(fieldViewName))
      : () => null
  ), [ selectedFieldType, fieldViewName ])

  let restrictions: Restrictions | undefined
  if ('fieldRestrictions' in initialValues) {
    restrictions = initialValues.fieldRestrictions?.map((f) => f.contentType)
  }

  const initialFormValues = merge(
    {},
    selectedFieldType?.identifier
      ? defaultFormValues[selectedFieldType.identifier as FieldIdentifier]
      : {},
    {
      id: undefined,
      fieldType: selectedFieldType?.identifier,
      settings: {},
      isArray: false,
      isTranslatable: false,
      ...initialValues,
      restrictions
    }
  )

  const fieldDetailsView = ({ Body, Header, SubHeader, Footer }: ViewStyleComponentRenderProps) => (
    <>
      <Header
        title={title}
        onCloseClick={onRequestClose}
      />
      {!isUpdating && (
        <SubHeader>
          <Text fontWeight="bold">Step 2: Enter Details</Text>
        </SubHeader>
      )}

      <PageLoader
        data={fieldTypes}
        error={error}
        loading={loading}
      >
        <Form
          decorators={[
            setIdentifierDecorator,
            customDecorator
          ]}
          onSubmit={handleFieldFormSubmit}
          initialValues={initialFormValues}
          mutators={{
            ...arrayMutators
          }}
          keepDirtyOnReinitialize
          validate={async (values) => {
            try {
              const res = await fieldTypeFactoryWithFallback(fieldViewName)()
              return res.default.validate(values)
            } catch (err) {
              return validate(values)
            }
          }}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <Body>
                <form onSubmit={handleSubmit}>
                  <FormField name="settings" alwaysDirty component="input" type="hidden" />
                  <Suspense fallback={<PageLoader loading />}>
                    <FieldViewComponent
                      selectedFieldType={selectedFieldType}
                      installationId={installationId}
                    />
                  </Suspense>
                  <input type="submit" style={{ display: 'none' }} />
                </form>
              </Body>
              <Footer>
                <Flex alignItems="center" direction={isUpdating ? 'row-reverse' : 'row'} grow={1} justifyContent="space-between">
                  {!isUpdating && <Chip label="Step 2" icon="arrow-right" iconPlacement="right" variant="light" />}
                  <Flex gap={14}>
                    {!isUpdating && <Button icon="arrow-left" onClick={() => setStep(step - 1)} />}
                    <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Flex>
                </Flex>
              </Footer>
            </>
          )}
        />
      </PageLoader>
    </>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {step === 2 ? fieldDetailsView : fieldTypeView}
    </View>
  )
}

export {
  blacklistedContentTypesState,
  defaultFormValues
}

export default AddFieldView
