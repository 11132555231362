import camelCase from 'lodash/camelCase'
import React from 'react'
import startCase from 'lodash/startCase'
import { ErrorBoundary } from 'react-error-boundary'

import EmbeddedField from 'components/contentEditors/generic/fields/EmbeddedFieldV2'
import FieldModel, { FieldIdentifier } from 'models/Field'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import JsonField from 'components/contentEditors/generic/fields/JsonField'
import ReferenceAttribute from 'components/contentEditors/generic/fields/ReferenceAttribute'
import RepeatedAttribute from 'components/resource/RepeatedAttribute'
import reportError from 'lib/reportError'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import {
  CheckboxField,
  ColorField,
  DateTimeField,
  DropdownField,
  DurationField,
  EmailField,
  MarkdownField,
  MediaField,
  NumberField,
  PasswordField,
  PhoneField,
  RadioField,
  SwitchField,
  TextField
} from 'components/contentEditors/generic/fields'
import { MEDIA_TYPE_OPTIONS } from 'components/contentEditors/generic/fields/fieldProps'
import { RESERVED_ATTRIBUTES } from 'models/Attribute'
import { Attribute, Parameter, useDataTypesListQuery } from 'generated/schema'
import type { Field } from 'components/contentEditors/generic/GenericContentEditor'
import type { Locale } from 'hooks/useActiveLocales'

type GenericParamFieldsProps = {
  prefix?: string,
  suffix?: string,
  currentLocale?: Locale,
  defaultLocale?: Locale,
  parameters: Parameter[],
  resourceId?: string,
  targetEnvironmentId?: string
}

type RenderAttributeOptions = {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  prefix?: string,
  suffix?: string,
  isFirstField?: boolean,
  index?: number,
  resourceId?: string,
  targetEnvironmentId?: string
}

const getAttributeName = ({
  prefix,
  suffix,
  identifier: _identifier,
  index,
  isArray = false,
  resourceId
}: Attribute & RenderAttributeOptions) => {
  const identifier = resourceId ? camelCase(_identifier) : _identifier

  if (isArray) return [ prefix, identifier, suffix, index?.toString() ].filter(Boolean).join('.')

  return [ prefix, identifier, suffix ].filter(Boolean).join('.')
}

const getDateTimeSettings = (attribute: Attribute) => {
  const dateFormat = attribute.fieldTypeSettings?.date_format || 'YYYY/MM/DD'
  const timeFormat = attribute.fieldTypeSettings?.time_format || 'HH:MM A'

  if (attribute.dataType.kind === 'TIMESTAMP') {
    return {
      format: dateFormat === 'AUTO'
        ? undefined
        : `${dateFormat} ${timeFormat}`,
      saveFormat: attribute.dataType.kind,
      withTime: true
    }
  }

  if (attribute.dataType.kind === 'TIME') {
    return {
      format: dateFormat === 'AUTO'
        ? undefined
        : timeFormat,
      saveFormat: attribute.dataType.kind,
      withTime: true
    }
  }

  return {
    format: dateFormat === 'AUTO'
      ? undefined
      : dateFormat,
    saveFormat: attribute.dataType.kind
  }
}

const getCommonProps = (
  attribute: Attribute,
  options?: RenderAttributeOptions
) => {
  const name = getAttributeName({
    ...attribute,
    ...options
  })

  const {
    currentLocale,
    // defaultLocale,
    isFirstField
  } = options || {}

  const {
    identifier,
    name: label,
    id,
    settings,
    fieldTypeSettings,
    isNullable
  } = attribute

  return {
    alwaysDirty: true,
    autoFocus: isFirstField,
    defaultValue: attribute.defaultValue ?? undefined,
    isTranslatable: attribute.isTranslatable,
    key: id,
    name,
    label: attribute.fieldTypeSettings?.label || label || startCase(identifier),
    settings: {
      ...settings,
      ...fieldTypeSettings,
      ...attribute.dataType?.settings,
      hasFallbackLocale: currentLocale?.allowFallback
    },
    ...({
      checkRequired: fieldTypeSettings?.checkRequired,
      placeholder: fieldTypeSettings?.placeholder,
      helpText: fieldTypeSettings?.helpText || fieldTypeSettings?.help_text
    }),
    isNullable,
    size: 'small',
    shouldValidate: false,
    disabled: RESERVED_ATTRIBUTES.includes(attribute.identifier)
      || [
        attribute?.resource?.creationTimestampAttributeId,
        attribute?.resource?.updationTimestampAttributeId,
        attribute?.resource?.deletionTimestampAttributeId
      ].includes(attribute.id)
  }
}

const renderField = (
  attribute: Attribute,
  options?: RenderAttributeOptions
) => {
  const commonProps = getCommonProps(attribute, options)

  switch (attribute.fieldType) {
    case FieldIdentifier.DROPDOWN:
      return (
        <DropdownField
          {...commonProps}
          field={{
            id: attribute.id,
            settings: {
              ...attribute.settings,
              ...attribute.fieldTypeSettings
            }
          } as Field}
          isArray={attribute.isArray}
          isClearable
          metaKey="value"
        />
      )

    case FieldIdentifier.MEDIA:
    case FieldIdentifier.FILE:
      return (
        <MediaField
          {...commonProps}
          settings={{
            ...commonProps.settings,
            fileTypeCategory: (
              commonProps.settings.fileTypeCategory
              // @ts-ignore
              || MEDIA_TYPE_OPTIONS[attribute.dataType.kind]
            )
          }}
          setToUndefinedOnDelete
          isArray={attribute.isArray}
          resource={options?.resourceId || attribute.resourceId}
          targetEnvironment={options?.targetEnvironmentId}
          // @ts-ignore
          attribute={attribute.__typename === 'Attribute' ? attribute.id : attribute.attributeId}
        />
      )

    case FieldIdentifier.REFERENCE:
      return (
        <ReferenceAttribute
          attribute={attribute}
          currentLocale={options?.currentLocale?.identifier}
          environmentId={options?.targetEnvironmentId!}
          {...commonProps}
        />
      )

    case FieldIdentifier.CHECKBOX:
      return <CheckboxField {...commonProps} />

    case FieldIdentifier.COLOR:
      return <ColorField {...commonProps} />

    case FieldIdentifier.DATE:
      return (
        <DateTimeField
          {...commonProps}
          settings={{
            ...commonProps.settings,
            ...getDateTimeSettings(attribute)
          }}
        />
      )

    case FieldIdentifier.EMAIL:
      return <EmailField {...commonProps} />

    case FieldIdentifier.JSON:
      return <JsonField {...commonProps} />

    case FieldIdentifier.MARKDOWN:
      return <MarkdownField {...commonProps} />

    case FieldIdentifier.NUMBER:
      return <NumberField {...commonProps} />

    case FieldIdentifier.PASSWORD:
      return <PasswordField {...commonProps} />

    case FieldIdentifier.RADIO:
      return <RadioField {...commonProps} />

    case FieldIdentifier.SWITCH:
      return <SwitchField {...commonProps} />

    case FieldIdentifier.DURATION:
      return <DurationField {...commonProps} />

    case FieldIdentifier.PHONE:
      return <PhoneField {...commonProps} />

    case FieldIdentifier.EMBEDDED:
      return (
        <EmbeddedField
          {...commonProps}
          defaultLocale={options?.defaultLocale!}
          currentLocale={options?.currentLocale!}
          dataType={attribute.dataType}
          targetEnvironmentId={options?.targetEnvironmentId!}
          isArray={attribute.isArray}
        />
      )

    default:
      return <TextField {...commonProps} />
  }
}

const ErrorFallback = () => (
  <Text>
    An unexpected error occured, our engineering team is looking into it
  </Text>
)

const ParameterField = ({
  prefix = 'data', suffix, parameter, index, currentLocale, defaultLocale, resourceId, targetEnvironmentId
}: Omit<GenericParamFieldsProps, 'parameters'> & { parameter: GenericParamFieldsProps['parameters'][number], index: number}) => {
  const attribute = parameter.attribute || parameter

  if (parameter.isArray
    && !FieldModel.hasCustomRepeatedBehavior(parameter.fieldType as FieldIdentifier)) {
    return (
      <RepeatedAttribute
        key={parameter.id}
        attribute={(attribute) as any}
        name={parameter.identifier}
        shouldValidate
        prefix={prefix}
        renderField={renderField}
        currentLocale={currentLocale}
        defaultLocale={defaultLocale}
        resourceId={resourceId}
        targetEnvironmentId={targetEnvironmentId}
      />
    )
  }

  return renderField((attribute) as any, {
    currentLocale,
    defaultLocale,
    prefix,
    suffix,
    isFirstField: index === 0,
    resourceId,
    targetEnvironmentId
  })
}

const useInjectDataTypes = (parameters: Parameter[]) => {
  const dataTypeIds = parameters.map((p) => p.attribute?.dataTypeId || p.dataTypeId)
  const shouldQuery = parameters.some((p) => !p.attribute?.dataType || !p.dataType)
  const { data, loading, error } = useDataTypesListQuery({
    skip: !shouldQuery,
    variables: {
      filter: {
        id: {
          in: dataTypeIds
        }
      }
    }
  })

  if (!shouldQuery) {
    return {
      parameters
    }
  }

  const idToDataTypeMap = getPropertyToElementMap(data?.dataTypesList || [], 'id')

  return {
    error,
    loading,
    parameters: data && parameters.map((p) => ({
      ...p,
      ...(p.attribute ? {
        attribute: {
          ...p.attribute,
          dataType: p.attribute.dataType || idToDataTypeMap[p.attribute.dataTypeId]
        }
      } : {}),
      dataType: p.dataType || idToDataTypeMap[p.dataTypeId]
    }))
  }
}

const GenericParamFields = ({ parameters, ...rest }: GenericParamFieldsProps) => {
  const { error, loading, parameters: injectedParameters } = useInjectDataTypes(parameters)

  return (
    <SectionLoader error={error} loading={loading} data={injectedParameters} empty={{ title: 'There are no data types.' }}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
        {injectedParameters && injectedParameters.map((parameter, index) => (
          <ParameterField
            key={parameter.id}
            parameter={parameter}
            index={index}
            {...rest}
          />
        ))}
      </ErrorBoundary>
    </SectionLoader>
  )
}

export { renderField }

export type { GenericParamFieldsProps, RenderAttributeOptions }

export default GenericParamFields
