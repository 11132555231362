import classNames from 'classnames'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Flex from 'components/layout/Flex'
import MasonryItem from 'components/layout/MasonryItem'
import reportError from 'lib/reportError'
import spaces from 'styles/primitives/spaces'
import Text from 'components/typography/Text'
import withOnMount from 'hoc/withOnMount'
import { css } from 'styles/stitches'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { useMasonryContext } from 'components/layout/Masonry'
import type { FlexProps } from 'components/layout/Flex'
import type { Space } from 'styles/primitives/spaces'
import type { MasonryItemProps } from 'components/layout/MasonryItem'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'

enum BlockDataSourceKind {
  RESOURCE = 'RESOURCE',
  OPERATION = 'OPERATION'
}

type Size = Record<string, string>

type BlockStyleProps = {
  name?: string,
  containerPadding?: Space,
  hideActionCard?: boolean,
  fullHeight?: boolean,
  fullWidth?: boolean,
  gap?: Space,
  growAvailableHeight?: boolean,
  squashYMargin?: boolean,
  height?: Size,
  isViewBlock?: boolean,
  masonryItemRef?: React.RefCallback<HTMLElement>,
  position?: Record<string, string>,
  width?: Size,
  onEdit?: () => void,
  onRemove?: () => void,
  onResize?: (columns: any[]) => void,
  onViewSettings?: () => void,
  onMount?: () => void,
  switcher?: SwitcherResultProps['switcher']
}

type BlockProps = BlockStyleProps & FlexProps & MasonryItemProps

const getDefaultSize = (size?: Size) => size?.xs || size?.sm || size?.md || size?.lg || size?.xl

const useStyles = ({ gap, height, containerPadding: padding, width }: Pick<BlockStyleProps, 'gap' | 'height' | 'containerPadding' | 'width'>) => ({
  block: css({
    alignSelf: 'flex-start',
    // choose the smallest available screen value as default
    height: getDefaultSize(height),
    width: `calc(${getDefaultSize(width)} - ${spaces[gap!]})`,
    margin: `calc(${spaces[gap!]}/2)`
  }),

  block_fullWidth: css({
    width: `calc(100% + ${spaces[padding!]} + ${spaces[padding!]} - ${spaces[gap!]}) !important`,
    marginX: `calc(-${spaces[padding!]} + ${spaces[gap!]}/2) !important`
  }),

  block_fullHeight: css({
    height: `calc(100vh - ${spaces[gap!]}/2 - ${TOPBAR_HEIGHT}px) !important`,
    marginBottom: 0,
    marginTop: `${spaces[gap!]}/2`,
    display: 'flex',
    flexDirection: 'column',

    '& > [data-resizable]': {
      flexGrow: 1
    }
  }),

  block_growAvailableHeight: css({
    display: 'flex',
    flexDirection: 'column',

    '& > [data-resizable]': {
      flexGrow: 1
    }
  }),

  block_squashYMargin: css({
    marginY: `calc(-${spaces[gap!]}/2) !important`
  })
})

const ErrorFallback = () => (
  <Flex alignItems="center" justifyContent="center" alignSelf="stretch">
    <Text>
      An unexpected error occured, our engineering team is looking into it
    </Text>
  </Flex>
)

function Block({
  // ResizableProps
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  resizeEnabled,

  // BlockProps
  name,
  hideActionCard = true,
  fullHeight = false,
  fullWidth = false,
  isViewBlock = false,
  growAvailableHeight,
  squashYMargin,
  height,
  position,
  width,

  // BoxProps
  alignSelf,
  justifySelf,
  basis,
  grow,
  shrink,

  // FlexProps
  children = null,
  masonryItemRef,

  onEdit,
  onRemove,
  onResize,
  onViewSettings,
  id,
  containerId,

  ...others
}: BlockProps) {
  const { gap, containerPadding } = useMasonryContext()
  const classes = useStyles({ gap, height, containerPadding, width })

  return (
    <MasonryItem
      ref={masonryItemRef}
      blockName={name}
      hideActionCard={hideActionCard}
      fullWidth={fullWidth}
      isViewBlock={isViewBlock}
      resizeEnabled={resizeEnabled}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      alignSelf={alignSelf}
      onEdit={onEdit}
      onRemove={onRemove}
      onResize={onResize}
      onViewSettings={onViewSettings}
      basis={basis}
      grow={grow}
      justifySelf={justifySelf}
      shrink={shrink}
      id={id}
      containerId={containerId}
      {...others}
      className={classNames({
        [classes.block]: true,
        [classes.block_fullWidth]: fullWidth,
        [classes.block_fullHeight]: fullHeight,
        [classes.block_growAvailableHeight]: growAvailableHeight,
        [classes.block_squashYMargin]: squashYMargin,
        [others.className!]: Boolean(others.className)
      })}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
        {children}
      </ErrorBoundary>
    </MasonryItem>
  )
}

export default withOnMount(Block)
export { BlockDataSourceKind }
export type { BlockProps, Size }
