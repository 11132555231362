import BarChartBlock from 'components/blocks/BarChartBlock'
import ButtonBlock from 'components/blocks/ButtonBlock'
import ColumnsBlock from 'components/blocks/ColumnsBlock'
import CSVInputBlock from 'components/blocks/CSVInputBlock'
import DataListBlock from 'components/blocks/DataListBlock'
import DataTableBlock from 'components/blocks/DataTableBlock'
import DetailsBlock from 'components/blocks/DetailsBlock'
import DrawerBlock from 'components/blocks/DrawerBlock'
import DropdownBlock from 'components/blocks/DropdownBlock'
import EmailPreviewBlock from 'components/blocks/EmailPreviewBlock'
import FormBlock from 'components/blocks/FormBlock'
import HeaderBlock from 'components/blocks/HeaderBlock'
import LineChartBlock from 'components/blocks/LineChartBlock'
import NotesBlock from 'components/blocks/NotesBlock'
import PieChartBlock from 'components/blocks/PieChartBlock'
import QuickLinksBlock from 'components/blocks/QuickLinksBlock'
import StatBlock from 'components/blocks/StatBlock'
import TabsBlock from 'components/blocks/TabsBlock'
import TimeSeriesBlock from 'components/blocks/TimeSeriesBlock'
import TitleBlock from 'components/blocks/TitleBlock'

type BlockType =
  | 'BarChartBlock'
  | 'BehaviorBlock'
  | 'DataListBlock'
  | 'DataTableBlock'
  | 'DrawerBlock'
  | 'FlowBlock'
  | 'FunnelBlock'
  | 'GaugeBlock'
  | 'ImpactBlock'
  | 'LineChartBlock'
  | 'PieChartBlock'
  | 'QuickLinksBlock'
  | 'RetentionBlock'
  | 'StatBlock'
  | 'TimeSeriesBlock'
  | 'TitleBlock'
  | 'HeaderBlock'
  | 'ColumnsBlock'
  | 'TabsBlock'
  | 'NotesBlock'
  | 'DetailsBlock'
  | 'FormBlock'
  | 'EmailPreviewBlock'
  | 'DropdownBlock'
  | 'ButtonBlock'
  | 'CSVInputBlock'

export type { BlockType }

export default {
  BarChartBlock,
  ColumnsBlock,
  DataListBlock,
  DataTableBlock,
  DetailsBlock,
  DrawerBlock,
  EmailPreviewBlock,
  LineChartBlock,
  PieChartBlock,
  QuickLinksBlock,
  StatBlock,
  TimeSeriesBlock,
  TitleBlock,
  HeaderBlock,
  NotesBlock,
  TabsBlock,
  FormBlock,
  DropdownBlock,
  ButtonBlock,
  CSVInputBlock
}
