import React, { Fragment, useContext } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Dashboard from 'models/Dashboard'
import Flex from 'components/layout/Flex'
import Icon, { withPreload } from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import Text from 'components/typography/Text'
import TopbarItem from 'components/topbar/TopbarItem'
import { PERSONAL_DASHBOARD, WORKSPACE_DASHBOARD } from 'lib/generateDashboard'
import { Popover, PopoverBody, PopoverContainer, PopoverDivider, PopoverFooter, PopoverSubheader } from 'components/popover'
import { styled } from 'styles/stitches'
import { TourGuideContext } from 'components/providers/TourProvider'
import { useDashboardsListQuery } from 'generated/schema'
import type { Color } from 'styles/theme'

type ColorMap = { [key: string]: Color }

const POPOVER_MIN_WIDTH = 250
const POPOVER_ITEM_ACTIVE_BORDER_WIDTH = 2
const POPOVER_ITEM_HORIZONTAL_PADDING = 25
const POPOVER_ITEM_VERTICAL_PADDING = 15

const POPOVER_ITEM_ICON_COLORS: ColorMap = {
  normal: 'dark100',
  hover: 'primary300'
}

const POPOVER_ITEM_TEXT_COLORS: ColorMap = {
  normal: 'dark900',
  hover: 'primary300'
}

const StyledDashboardSwitcherTopbarItem = styled(TopbarItem, {
  paddingX: 20,
  variants: {
    active: {
      true: {
        '& [data-arrow]': {
          transform: 'rotate(180deg)'
        }
      }
    }
  }
})

const StyledDashboardPopover = styled(Popover, {
  minWidth: POPOVER_MIN_WIDTH
})

const StyledIcon = styled(Icon, {
  color: POPOVER_ITEM_ICON_COLORS.normal
})

const StyledText = styled(Text, {
  color: POPOVER_ITEM_TEXT_COLORS.normal
})

const StyledPopoverItemContainer = styled(Flex, {
  alignItems: 'center',
  backgroundColor: 'light100',

  cursor: 'pointer',

  '&:hover, &:focus': {
    backgroundColor: 'light400'
  }
})

const StyledPopoverItem = styled(Flex, {
  ...mixins.transition('simple'),
  paddingY: POPOVER_ITEM_VERTICAL_PADDING,
  paddingLeft: POPOVER_ITEM_HORIZONTAL_PADDING,
  cursor: 'pointer',

  '&:hover, &:focus': {

    [`${StyledIcon}`]: {
      color: POPOVER_ITEM_ICON_COLORS.hover
    },

    [`${StyledText}`]: {
      color: POPOVER_ITEM_TEXT_COLORS.hover
    }
  },

  variants: {
    active: {
      true: {
        borderLeftColor: 'primary300',
        borderLeftStyle: 'solid',
        borderLeftWidth: POPOVER_ITEM_ACTIVE_BORDER_WIDTH
      }
    }
  }
})

const ICONS_MAP = {
  dashboard: withPreload(StyledIcon, 'dashboard'),
  manage: withPreload(StyledIcon, 'manage')
} as const

function DashboardSwitcherTopbarItem() {
  const { showNextStep } = useContext(TourGuideContext)!
  const { data: { dashboardsList = [] } = {}, loading } = useDashboardsListQuery({
    variables: {
      order: [ { position: 'asc' } ],
      limit: 100
    }
  })

  const { currentDashboard } = useContext(InternalContext)!

  if (loading && dashboardsList.length === 0) {
    return null
  }

  return (
    <PopoverContainer
      onPopoverOpen={showNextStep}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -14, 0 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <StyledDashboardSwitcherTopbarItem
          active={isActive}
          {...otherToggleProps}
          className="tg--dashboard-switcher"
        >
          <Icon name="dashboard" data-icon size={24} />
          <Text fontSize={12} fontWeight="bold">
            {currentDashboard?.name}
          </Text>
          <Icon name="arrow-down" data-icon data-arrow size={10} />
        </StyledDashboardSwitcherTopbarItem>
      )}
      {(popoverProps) => (
        <StyledDashboardPopover withArrow {...popoverProps}>
          <PopoverSubheader title="Dashboards" />
          <PopoverDivider />
          <PopoverBody>
            <Scrollbars autoHeight>
              {dashboardsList.map((dashboard, index, { length }) => {
                const ResolvedIcon = ICONS_MAP[Dashboard.dashboardIcon(dashboard)]
                return (
                  <Fragment key={dashboard.identifier}>
                    {index < length && index !== 0 && <PopoverDivider />}
                    <StyledPopoverItemContainer>
                      <StyledPopoverItem
                        as={BaseLink}
                        to={`/~${dashboard.identifier}`}
                        grow={1}
                        gap={12}
                        alignItems="center"
                        justifyContent="space-between"
                        active={dashboard.identifier === currentDashboard?.identifier}
                        onClick={() => popoverProps.closePopover()}
                      >
                        <Flex gap={12} alignItems="center">
                          <ResolvedIcon size={16} />
                          <StyledText
                            fontSize={14}
                            fontWeight="semibold"
                            letterSpacing="condensed"
                          >
                            {dashboard.name}
                          </StyledText>
                        </Flex>
                      </StyledPopoverItem>
                    </StyledPopoverItemContainer>
                  </Fragment>
                )
              })}
            </Scrollbars>
          </PopoverBody>
          <PopoverDivider />
          <PopoverFooter direction="column" withDivider={false}>
            <PopoverSubheader title="Admin" />
            <StyledPopoverItemContainer
              className="tg--workspace-menu"
            >
              <StyledPopoverItem
                as={BaseLink}
                to={`/~${WORKSPACE_DASHBOARD.identifier}`}
                grow={1}
                gap={12}
                alignItems="center"
                justifyContent="space-between"
                active={WORKSPACE_DASHBOARD.identifier === currentDashboard?.identifier}
                onClick={() => popoverProps.closePopover()}
              >
                <Flex gap={12} alignItems="center">
                  <ICONS_MAP.manage size={16} />
                  <StyledText
                    fontSize={14}
                    fontWeight="semibold"
                    letterSpacing="condensed"
                  >
                    {WORKSPACE_DASHBOARD.name}
                  </StyledText>
                </Flex>
              </StyledPopoverItem>
            </StyledPopoverItemContainer>
            <PopoverDivider />
            <StyledPopoverItemContainer>
              <StyledPopoverItem
                as={BaseLink}
                to={`/~${PERSONAL_DASHBOARD.identifier}`}
                grow={1}
                gap={12}
                alignItems="center"
                justifyContent="space-between"
                active={PERSONAL_DASHBOARD.identifier === currentDashboard?.identifier}
                onClick={() => popoverProps.closePopover()}
              >
                <Flex gap={12} alignItems="center">
                  <ICONS_MAP.manage size={16} />
                  <StyledText
                    fontSize={14}
                    fontWeight="semibold"
                    letterSpacing="condensed"
                  >
                    {PERSONAL_DASHBOARD.name}
                  </StyledText>
                </Flex>
              </StyledPopoverItem>
            </StyledPopoverItemContainer>
            <PopoverDivider variant="whitespace" />
          </PopoverFooter>
        </StyledDashboardPopover>
      )}
    </PopoverContainer>
  )
}

export default DashboardSwitcherTopbarItem
