import React from 'react'

import TitleBlock from 'components/blocks/TitleBlock'
import ThirdPartyAppsList from 'components/views/apps/ThirdPartyAppsList'

function IntegrationsPage() {
  return (
    <>
      <TitleBlock heading="Integrations" />
      <ThirdPartyAppsList />
    </>
  )
}

export default IntegrationsPage
