import isEqual from 'lodash/isEqual'
import React from 'react'
import uuid from 'uuid-random'

import convertToArray from 'lib/convertToArray'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import useDeepMemo from 'hooks/useDeepMemo'
import { Content, StyledEmpty, renderFieldContent } from 'components/contentVersion/VersionDiff'
import { useContentTypeQuery, useFieldsListQuery } from 'generated/schema'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import type { ComputeDiffWithOptionsProps } from 'components/contentVersion/VersionDiff'
import type { Field } from 'generated/schema'

type Options = {
  activeLocale: string,
  defaultLocale: string
}

type Embedded = {
  contentTypeId: string,
  data: Record<string, any>
}

type EmbeddedFieldProps = {
  previous: Embedded[],
  current: Embedded[],
  isCurrent?: Boolean,
  options: Options
}

type EmbeddedFieldBlockProps = {
  previous?: Embedded,
  current?: Embedded,
  isCurrent?: Boolean,
  options: Options
}

function EmbeddedFieldBlock({
  previous, current, isCurrent, options
}: EmbeddedFieldBlockProps) {
  const { contentTypeId: currentContentTypeId, data: currentData } = current || {}
  const { contentTypeId: previousContentTypeId, data: previousData } = previous || {}

  // Only decides the title of the DrawerBlock
  const contentTypeId = isCurrent ? currentContentTypeId : previousContentTypeId
  // Only decides the subtitle of the DrawerBlock
  const data = isCurrent ? currentData : previousData

  const {
    data: contentTypeData
  } = useContentTypeQuery({
    variables: {
      id: contentTypeId
    },
    skip: !contentTypeId
  })

  const {
    data: fieldsData
  } = useFieldsListQuery({
    variables: {
      filter: {
        contentTypeId: { eq: contentTypeId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: FIELDS_LIST_LIMIT
    },
    skip: !contentTypeId,
    fetchPolicy: 'cache-first'
  })

  const { fieldsList = [] } = fieldsData || {}
  const { contentType } = contentTypeData || {}

  if (!contentType || fieldsList.length === 0) {
    return <StyledEmpty />
  }

  const { titleFieldId } = contentType
  const titleField = fieldsList.find((field) => field.id === titleFieldId)

  return (
    <DrawerBlock
      as={Flex}
      contentPadding="small"
      headerHeight="small"
      headerPadding="small"
      subtitle={titleField?.isTranslatable
        ? data?.[titleFieldId]?.[options.activeLocale]
        : data?.[titleFieldId]?.[options.defaultLocale]}
      title={contentType.name}
    >
      {() => (
        <Flex direction="column" gap={20}>
          {(fieldsList as Field[]).map((field) => {
            const {
              currentNode,
              previousNode,
              isDiff
            } = renderFieldContent(
              currentData,
              previousData,
              field,
              {
                ...options
              }
            )

            const node = isCurrent ? currentNode : previousNode
            const variant = (() => {
              if (!isDiff) return 'neutral'

              return isCurrent ? 'positive' : 'negative'
            })()

            return (
              <Content
                key={field.id}
                field={field}
                variant={variant}
                isNested
              >
                {node || <StyledEmpty />}
              </Content>
            )
          })}
        </Flex>
      )}
    </DrawerBlock>
  )
}

function EmbeddedField({ previous, current, isCurrent = false, ...rest }: EmbeddedFieldProps) {
  /**
   * - Reason for toggling between current and previous as the primary list to render.
   *
   * If the previous version as more data items than the current version AND if we always
   * choose to loop through the current version data items, then we would lose the extra
   * previous selected version items that has to be shown as deleted/removed.
   */
  const primary = isCurrent ? current : previous
  const currentAndPrevious = [ ...current, ...previous ]

  const memoizedBlocks = useDeepMemo(() => (
    primary.map((_, index) => {
      const currentValue = current[index]
      const previousValue = previous[index]

      return (
        <EmbeddedFieldBlock
          key={uuid()}
          current={currentValue}
          previous={previousValue}
          isCurrent={isCurrent}
          {...rest}
        />
      )
    })
  // We need to pass both current and previous to make sure that when either side
  // version changes we update the whole block else there could be a possibility
  // of stale data.
  ), currentAndPrevious)

  return (
    <>{memoizedBlocks}</>
  )
}

EmbeddedField.computeDiff = <T extends Embedded | Embedded[]>({
  previousValue, currentValue, options
}: ComputeDiffWithOptionsProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  const props = { options, previous, current }

  return {
    previousNode: (<EmbeddedField {...props} />),
    currentNode: <EmbeddedField {...props} isCurrent />,
    isDiff: !isEqual(previousValue, currentValue)
  }
}

export default EmbeddedField
