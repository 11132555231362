import React from 'react'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import { useForm, useFormState } from 'react-final-form'

import AddRecordView from 'components/views/graph/AddRecordView'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useSearchGenericRecords from 'components/views/graph/useSearchGenericRecords'
import { Kind } from 'models/Relationship'
import { useOperationsListQuery, useRelationshipsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { Attribute } from 'generated/schema'

type ReferenceAttributeProps = Omit<fieldProps<''>, 'input' | 'meta'>& {
  disabled?: boolean,
  attribute?: Attribute,
  currentLocale?: string,
  environmentId?: string
}

function ReferenceAttribute(
  { attribute, currentLocale, environmentId, label, ...inputProps }: ReferenceAttributeProps
) {
  const { resourceId, id } = attribute || {}
  const { change } = useForm()
  const { values } = useFormState()
  const { openView } = useViewDispatch()
  const inputValue = get(values, inputProps.name)

  const { disabled } = inputProps

  const { data: { relationshipsList } = {} } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId },
        sourceAttributeId: { eq: id }
      }
    },
    skip: !resourceId
  })

  const relationship = relationshipsList?.[0] || undefined

  const {
    data: { operationsList = [] } = {}
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: relationship?.target.id }
      }
    },
    skip: !relationship
  })

  const createOperation = operationsList.find((op) => op.graphqlKind === 'MUTATION' && op.identifier.includes('create'))

  const sourceAttribute = id !== relationship?.sourceAttributeId
    ? relationship?.sourceAttribute
    : relationship?.targetAttribute

  const target = id !== relationship?.sourceAttributeId
    ? relationship?.source
    : relationship?.target

  const {
    searchResult: [ {
      data: { internalSearchRecords: searchRecords = [] } = {},
      loading: searchLoading,
      error: searchError
    }, onSearch ]
  } = useSearchGenericRecords({
    resourceId: target?.id!,
    environmentId,
    filters: {
      ...(inputValue && relationship && {
        or: [
          {
            [relationship.targetAttribute.identifier]: {
              eq: inputValue
            }
          }
        ]
      })
    },
    skip: !relationship || !target
  })

  const titleAttribute = target?.attributes.find((attr) => attr.id === target.titleAttributeId)
  const subTitleAttribute = target?.attributes.find(
    (attr) => attr.id === target?.subtitleAttributeId
  )
  const labelKey = `data.${camelCase(titleAttribute?.identifier || target?.attributes[0]?.identifier || 'id')}.${currentLocale}`
  const metaKey = subTitleAttribute ? `data.${camelCase(subTitleAttribute.identifier)}.${currentLocale}` : undefined
  const valueKey = `data.id.${currentLocale}`

  const onAdd = () => openView({
    title: `Add ${relationship?.target.name}`,
    component: AddRecordView,
    params: {
      resourceId: relationship?.target.id,
      initialValues: {} as any,
      switcher: {
        data: {
          environment: {
            id: environmentId
          }
        } as any
      },
      operationId: createOperation!.id,
      title: `Add ${relationship?.target.name}`
    },
    style: 'PANEL'
  })

  return (
    <>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          {label}
        </Text>
        {!disabled && (
          <TextLink
            as="button"
            type="button"
            fontSize={10}
            onClick={onAdd}
            mode="distinct"
          >
            Add new
          </TextLink>
        )}
      </Flex>
      <FormField
        component={SelectInput}
        isMulti={relationship?.kind === Kind.HAS_MANY && sourceAttribute?.id !== id}
        size="small"
        variant="light"
        isLoading={searchLoading}
        hasError={Boolean(searchError)}
        labelKey={labelKey}
        metaKey={metaKey}
        valueKey={valueKey}
        defaultOptions={searchRecords}
        options={searchRecords}
        loadOptions={((inputValue: string, callback: any) => {
          onSearch(inputValue, (data) => callback(data.internalSearchRecords))
        })}
        onBlur={() => {
          if (!inputValue) onSearch('')
        }}
        isClearable
        setValueAsObject
        {...inputProps}
        input={{
          value: searchRecords.find((record) => get(record, valueKey) === inputValue),
          onChange: (value: any) => {
            change(inputProps.name, get(value, valueKey))
          }
        }}
      />
    </>
  )
}

export default ReferenceAttribute
