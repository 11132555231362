/* eslint-disable no-nested-ternary */
import arrayMutators from 'final-form-arrays'
import last from 'lodash/last'
import React, { useState } from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Card from 'components/card/Card'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import generatePosition from 'lib/generatePosition'
import Grid from 'components/layout/Grid'
import IconInput from 'components/inputs/IconInput'
import Label from 'components/typography/Label'
import RadioInput from 'components/inputs/RadioInput'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { EnvironmentBehavior } from 'models/Resource'
import { SidePaneFooter } from 'components/sidePane'
import { useCreateResourceMutation, CreateResourceInput, UpdateResourceInput, ResourcesListDocument, useUpdateResourceMutation, ResourcesListQuery, useResourcesListQuery, Resource, ResourceEnvironmentBehavior, useAttributesListQuery } from 'generated/schema'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = CreateResourceInput | UpdateResourceInput

type Params = ViewParams[Views.CREATE_RESOURCE]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const getMaxPosition = (resource: ResourcesListQuery['resourcesList']) => Math.max(
  resource[0]?.position || 0, last(resource)?.position || 0
)

const ENVIRONMENT_BEHAVIOR = [
  {
    label: 'Test & Live data are the same',
    description: 'Keeps data the same across all environments, and changes are reflected instantly',
    value: EnvironmentBehavior.NONE
  },
  {
    label: 'Test & Live data are different',
    description: 'Keeps data completely isolated between live & test environments',
    value: EnvironmentBehavior.ISOLATED
  },
  {
    label: 'Test data is published to Live',
    description: 'Data gets promoted from test to live, allowing you to review changes before they go live',
    value: EnvironmentBehavior.PROMOTED
  }
]

const CreateResourceView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView, stepBackDashboardEditor } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {}, app, resource, workspace } = params! as Params
  const { id: appId, kind, name: appName } = app! || {}
  const { id: workspaceId } = workspace! || {}

  const isUpdating = 'id' in initialValues
  const isProject = kind && kind === 'PROJECT'

  const [
    environmentBehavior,
    setEnvironmentBehavior
  ] = useState<ResourceEnvironmentBehavior>(
    resource?.environmentBehavior || EnvironmentBehavior.NONE
  )

  const queryVariables = {
    filter: {
      ...(appId ? { appId: { eq: appId } } : { appId: 'null' }),
      ...(workspaceId ? { workspaceId: { eq: workspaceId } } : {}),
      isReadOnly: { eq: false }
    },
    order: [ {
      position: 'asc'
    } ]
  }

  const {
    data: { resourcesList = [] } = {}
  } = useResourcesListQuery({ variables: queryVariables })

  const {
    data: { attributesList = [] } = {},
    loading: attributesListLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: isUpdating && resource?.id }
      }
    },
    skip: !isUpdating
  })

  const [ createResource ] = useCreateResourceMutation({
    onCompleted: ({ createResource }) => {
      stepBackDashboardEditor()
      openDashboardEditorView({
        target: Views.RESOURCE_DETAILS,
        params: { app, resource: createResource as Resource, workspace }
      })
    },
    refetchQueries: [ { query: ResourcesListDocument, variables: queryVariables } ]
  })

  const handleCreateResource = useSubmitHandler(createResource, {
    successAlert: { message: 'Resource Created.' }
  })

  const [ updateResource ] = useUpdateResourceMutation({
    onCompleted: ({ updateResource }) => {
      stepBackDashboardEditor(2)
      openDashboardEditorView({
        target: Views.RESOURCE_DETAILS,
        params: { app, resource: updateResource as Resource, workspace }
      })
    },
    refetchQueries: [ { query: ResourcesListDocument, variables: queryVariables } ]
  })

  const handleUpdateResource = useSubmitHandler(updateResource, {
    successAlert: { message: 'Resource Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateResource(
        values as UpdateResourceInput,
        form as FormApi<UpdateResourceInput>
      )
    }

    return handleCreateResource(values as CreateResourceInput)
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'New'} Resource`}
        heading={isUpdating
          ? `Resource: ${resource?.name}`
          : isProject ? `Project: ${appName}`
            : workspaceId ? 'Schema' : `App: ${appName}`}
        onClose={onClose}
      />
      <Form
        initialValues={{
          appId,
          position: generatePosition(getMaxPosition(resourcesList)),
          environmentBehavior,
          ...initialValues
        } as FormValues}
        decorators={[
          setIdentifier
        ]}
        mutators={{
          ...arrayMutators
        }}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        subscription={{
          submitting: true,
          pristine: true
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                <Field
                  autoFocus
                  checkRequired
                  name="name"
                  label="Name"
                  component={TextInput}
                  size="small"
                  helpText="Use singular form."
                />
                <Field
                  checkRequired
                  name="identifier"
                  label="Identifier"
                  component={TextInput}
                  size="small"
                  helpText="Used in code. Avoid modifying this."
                />
                <Field
                  component={IconInput}
                  name="icon"
                  label="Icon"
                  placeholder="Choose Icon"
                  size="small"
                  type="text"
                />
                <Field
                  name="description"
                  label="Summary"
                  helpText="Add notes regarding the resource's intended use cases."
                  component={TextAreaInput}
                  size="small"
                />
                <Flex direction="column" gap={10}>
                  <Label fontWeight="bold" fontSize={10}>Environment Behavior</Label>
                  <Flex direction="column" gap={2}>
                    {ENVIRONMENT_BEHAVIOR.map((env) => {
                      const onClick = () => {
                        if (!isUpdating) setEnvironmentBehavior(env.value)
                      }

                      return (
                        <Card onClick={onClick} disabled={isUpdating}>
                          <Flex gap={10}>
                            <RadioInput
                              align="flex-start"
                              disabled={isUpdating}
                              input={{
                                name: 'environmentBehavior',
                                value: env.value,
                                checked: environmentBehavior === env.value,
                                onChange: onClick,
                                onBlur: () => {},
                                onFocus: () => {}
                              }}
                              meta={{}}
                            />
                            <Flex direction="column" gap={6}>
                              <Text fontSize={12} fontWeight="bold">{env.label}</Text>
                              <Text fontSize={10} color="dark500">{env.description}</Text>
                            </Flex>
                          </Flex>
                        </Card>
                      )
                    })}
                  </Flex>
                </Flex>
                {isUpdating && (
                  <FormValuesField
                    fieldNames={[
                      'titleAttributeId',
                      'subtitleAttributeId',
                      'polymorphicAttributeId',
                      'updationTimestampAttributeId',
                      'creationTimestampAttributeId',
                      'deletionTimestampAttributeId'
                    ]}
                  >
                    {(values) => (
                      <DrawerBlock as={Flex} title="Special Attributes Mapping">
                        {() => (
                          <Grid gap={16}>
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="titleAttributeId"
                              label="Title Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.subtitleAttributeId
                                  || option.id === values.polymorphicAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="subtitleAttributeId"
                              label="Subtitle Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.titleAttributeId
                                  || option.id === values.polymorphicAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="polymorphicAttributeId"
                              label="Polymorphic Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.titleAttributeId
                                  || option.id === values.subtitleAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="creationTimestampAttributeId"
                              label="Creation Timestamp Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.titleAttributeId
                                  || option.id === values.subtitleAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="updationTimestampAttributeId"
                              label="Updated Timestamp Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.titleAttributeId
                                  || option.id === values.subtitleAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                            <Field
                              isLoading={attributesListLoading}
                              isDisabled={!attributesList.length}
                              isClearable
                              name="deletionTimestampAttributeId"
                              label="Deletion Timestamp Attribute"
                              component={SelectInput}
                              options={attributesList}
                              isOptionDisabled={(option: Record<any, any>) => (
                                option.id === values.titleAttributeId
                                  || option.id === values.subtitleAttributeId
                              )}
                              labelKey="name"
                              valueKey="id"
                              size="small"
                            />
                          </Grid>
                        )}
                      </DrawerBlock>
                    )}
                  </FormValuesField>
                )}
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateResourceView
