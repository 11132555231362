import useSubmitHandler from 'hooks/useSubmitHandler'
import { usePublishContentVersionMutation, useUnpublishContentVersionMutation } from 'generated/schema'

enum PublishingAction {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish'
}

type HandlePublishingProps = {
  action: PublishingAction,
  contentId: string,
  contentVersionId?: string
}

type HandlePublishingFn = (options: HandlePublishingProps) => Promise<Record<string, any>>

function useVersionPublishing() {
  const [ publishContent ] = usePublishContentVersionMutation()
  const publishContentSubmitHandler = useSubmitHandler(publishContent)

  const [ unpublishContent ] = useUnpublishContentVersionMutation()
  const unpublishContentSubmitHandler = useSubmitHandler(unpublishContent)

  const handlePublishing = ({ action, contentId, contentVersionId }: HandlePublishingProps) => {
    if (action === PublishingAction.UNPUBLISH) {
      return unpublishContentSubmitHandler({ id: contentId })
    }

    return publishContentSubmitHandler({
      id: contentId,
      contentVersionId
    })
  }

  return [ handlePublishing ]
}

export type {
  HandlePublishingFn
}

export {
  PublishingAction
}

export default useVersionPublishing
