import React from 'react'

import type { ViewProps } from 'components/views'
import DetailsBlock from 'components/blocks/DetailsBlock'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'

type Params = {
  title: string,
  resourceId: string,
  recordId: string,
  prefix?: string,
  suffix?: string,
  switcher?: SwitcherResultProps['switcher']
}

function ResourceDetailsView({
  closeView,
  onRequestClose,
  params: {
    title,
    resourceId,
    recordId,
    switcher,
    prefix = '',
    suffix = ''
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <DetailsBlock
              asFragment
              resourceId={resourceId}
              recordId={recordId}
              switcher={switcher}
              prefix={prefix}
              suffix={suffix}
            />
          </Body>
        </>
      )}
    </View>
  )
}

ResourceDetailsView.defaultStyle = 'PANEL' as const

export default ResourceDetailsView
