import React, { useState } from 'react'

import AddContentView from 'components/views/cms/AddContentView'
import Chip from 'components/chip/Chip'
import ContentsListView from 'components/views/cms/ContentsListView'
import Grid from 'components/layout/Grid'
import Masonry from 'components/layout/Masonry'
import MediaCard from 'components/mediaCard/MediaCard'
import { useViewDispatch } from 'hooks/useViewContext'
import type { ContentTypeFragmentFragment as ContentTypeFragment, ContentQuery, ContentTypeQuery } from 'generated/schema'
import type { ViewProps } from 'components/views'

enum Action {
  EDIT = 'edit',
  PICK = 'pick',
  CREATE = 'create'
}

type ActionParams =
  | { action: Action.EDIT, onAction: (selection: string) => void }
  | { action: Action.PICK, onAction: (selection: string[]) => void }
  | { action: Action.CREATE, onAction: (selection: string) => void }

type Params = ActionParams & {
  restrictions?: ContentTypeFragment[],
  content?: ContentQuery['content'],
  contentType?: ContentTypeQuery['contentType'],
  isArray?: boolean
}

function ReferenceContentView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const {
    action,
    content,
    contentType,
    isArray,
    restrictions
  } = params

  const getDefaultContentType = () => {
    if (action === Action.PICK) {
      if (restrictions && restrictions.length === 1) {
        return restrictions[0]
      }
    }
    if (action === Action.EDIT) {
      return restrictions?.find((contentType) => contentType.id === content?.contentTypeId)
    }

    if (action === Action.CREATE) {
      return contentType
    }

    return undefined
  }
  const [
    selectedContentType, setSelectedContentType
  ] = useState<ContentTypeFragment | undefined>(getDefaultContentType)

  const { openView } = useViewDispatch()

  const title = (() => {
    const contentTypeName = selectedContentType?.name

    switch (action) {
      case Action.PICK:
        return selectedContentType ? `Pick ${contentTypeName}` : 'Choose Content Type'
      case Action.EDIT:
        return `Edit ${contentTypeName}`
      case Action.CREATE:
        return `Create ${contentTypeName}`
      default:
        return ''
    }
  })()

  const isUpdating = Boolean(content)
  const showContentTypesList = !isUpdating && !selectedContentType

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Body, Header }) => (
        <>
          <Header onCloseClick={onRequestClose} title={title} />
          <Body>
            {showContentTypesList ? (
              <Grid gap={20} columns={1}>
                {restrictions?.map((contentType) => (
                  <MediaCard
                    key={contentType.id}
                    media={(
                      <Chip
                        label={contentType.identifier}
                        variant="light"
                      />
                    )}
                    onClick={() => setSelectedContentType(contentType)}
                    title={contentType.name}
                    titlePosition="top"
                    width="full"
                  />
                ))}
              </Grid>
            ) : (
              <Masonry>
                {params.action === Action.PICK ? (
                  <ContentsListView
                    contentType={selectedContentType!}
                    isArray={isArray}
                    onContentPick={(selection) => {
                      onRequestClose()
                      params.onAction(selection)
                    }}
                    onContentCreate={() => {
                      openView({
                        title: `Create ${contentType?.name}`,
                        component: ReferenceContentView,
                        style: 'PANEL',
                        params: {
                          action: Action.CREATE,
                          contentType,
                          onAction: (selection) => {
                            onRequestClose()
                            params.onAction([ selection ])
                          }
                        }
                      })
                    }}
                    sidePaneMode
                  />
                ) : (
                  <AddContentView
                    contentId={content?.id}
                    contentType={selectedContentType!}
                    onAddContent={(contentId) => {
                      onRequestClose()
                      params.onAction(contentId)
                    }}
                    onEditContent={(contentId) => {
                      onRequestClose()
                      params.onAction(contentId)
                    }}
                    sidePaneMode
                  />
                )}
              </Masonry>
            )}
          </Body>
        </>
      )}
    </View>
  )
}

export { Action }

export default ReferenceContentView
