import uuid from 'uuid-random'
import React from 'react'
import { diffWords } from 'diff'
import type { Change } from 'diff'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import useDeepMemo from 'hooks/useDeepMemo'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type MarkdownFieldProps = {
  isCurrent?: boolean,
  isPrevious?: boolean,
  diffs: Change[][]
}

function MarkdownField({ isCurrent = false, isPrevious = false, diffs }: MarkdownFieldProps) {
  const chunksList = useDeepMemo(() => (
    diffs.map((chunks) => ({
      key: uuid(),
      chunks: chunks.reduce((acc, chunk) => {
        const { added, removed, value } = chunk

        if (!added && !removed) {
          acc.push(<Text key={uuid()} as="span">{value}</Text>)

          return acc
        }

        if (isCurrent && added) {
          acc.push(<Text key={uuid()} as="span" color="positive400">{value}</Text>)
        }

        if (isPrevious && removed) {
          acc.push(<Text key={uuid()} as="span" color="negative400">{value}</Text>)
        }

        return acc
      }, [] as React.ReactNode[])
    }))
  ), diffs)

  return (
    <Flex as="pre" direction="column" gap={8}>
      {chunksList.map(({ key, chunks }) => (
        <Text key={key}>{chunks}</Text>
      ))}
    </Flex>
  )
}

MarkdownField.computeDiff = <T extends string | string[]>({
  currentValue, previousValue
}: ComputeDiffProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  const length = Math.max(previous.length, current.length)

  let isDiff = false
  const diffResults = Array(length).fill([]).map((_, index) => {
    const result = diffWords(previous[index] || '', current[index] || '')

    if (!isDiff) {
      isDiff = result.filter((r) => r.added || r.removed).length > 0
    }
    return result
  })

  return {
    previousNode: <MarkdownField isPrevious diffs={diffResults} />,
    currentNode: <MarkdownField isCurrent diffs={diffResults} />,
    isDiff
  }
}

export default MarkdownField
