import React from 'react'
import { Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import TextInput from 'components/inputs/TextInput'
import Workspace from 'models/Workspace'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { UpdateWorkspaceDomainInput } from 'generated/schema'
import HintBox from 'components/hints/HintBox'

type WorkspaceDomainFormProps = FormPropsWithId<UpdateWorkspaceDomainInput> & {
  onCancel: () => void
}

const {
  REACT_APP_DOMAIN
} = process.env

function WorkspaceDomainForm({ onCancel, ...others }: WorkspaceDomainFormProps) {
  return (
    <Form
      validate={(values) => Workspace.validate(values, [ 'identifier' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit} onReset={reset}>
          <Flex direction="column" gap={16}>
            <HintBox>
              Changing your Workspace Domain will redirect you to the new Workspace Domain,
              potentially logging you out.Your old Workspace Domain will still be active in
              24 hours.
            </HintBox>
            <Flex grow={1}>
              <Flex direction="column" css={{ flexBasis: '50%' }}>
                <FormField name="identifier" label="Subdomain" component={TextInput} type="text" appendText={`.${REACT_APP_DOMAIN}`} />
              </Flex>
            </Flex>
            <Divider />

            <Flex gap={24}>
              <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
              <Button type="reset" variant="outline" label="Cancel" mode="subtle" disabled={submitting || pristine} onClick={onCancel} />
            </Flex>
          </Flex>
        </form>
      )}
      {...others}
    />
  )
}

export default WorkspaceDomainForm
