import composeRefs from '@seznam/compose-react-refs'
import React, { useEffect, useMemo, useState } from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useDroppable } from '@dnd-kit/core'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useSortable } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import InspectWrapper from 'components/wrappers/InspectWrapper'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard from 'hooks/useDashboard'
import useHover from 'hooks/useHover'
import zIndices from 'styles/primitives/zIndices'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { Views } from 'components/dashboardEditor/constants'
import type { BoxProps } from 'components/layout/Box'

type MasonryItemProps = BoxProps & {
  hideActionCard?: boolean,
  fullWidth?: boolean,
  resizeEnabled?: boolean,
  onEdit?: () => void,
  onRemove?: () => void,
  onResize?: (property: string, value: string) => void,
  onViewSettings?: () => void
}

const StyledActionableCard = styled(Flex, {
  ...mixins.shadow('small', colorVars.dark500rgb, 0.3),
  ...mixins.transition('simple'),

  position: 'absolute',
  opacity: '0 !important',
  backgroundColor: 'dark900',
  borderRadius: 6,
  padding: 8,
  zIndex: 'above',

  variants: {
    isFirstBlock: {
      true: {}
    },
    fullWidth: {
      true: {}
    },
    isActive: {
      false: {
        left: 20
      },
      true: {
        opacity: 1,
        left: -50
      }
    }
  }
})

StyledActionableCard.compoundVariant({
  isFirstBlock: true,
  isActive: true,
  fullWidth: true
}, {
  left: 20,
  top: 25,
  zIndex: zIndices.dashboardEditor
})

StyledActionableCard.compoundVariant({
  isFirstBlock: true,
  isActive: false,
  fullWidth: true
}, {
  left: 20,
  top: 25
})

StyledActionableCard.compoundVariant({
  isActive: true,
  fullWidth: true
}, {
  left: 30,
  zIndex: zIndices.dashboardEditor
})

StyledActionableCard.compoundVariant({
  isActive: false,
  fullWidth: true
}, {
  left: 50
})

const ActionableCard = ({
  itemEl, isViewBlock, hideActionCard, fullWidth, onEdit, onRemove, listeners
}: any) => {
  const isCurrentItemDragging = false

  const [ isHovered, setIsHovered ] = useState(false)
  const { draggedBlockState, editableState } = useDashboard()
  const draggedBlock = useRecoilValue(draggedBlockState)

  const isEditable = useRecoilValue(editableState)
  const setEditable = useSetRecoilState(editableState)

  useEffect(() => {
    if (itemEl) {
      const onMouseEnter = () => { setIsHovered(true) }
      const onMouseLeave = () => { setIsHovered(false) }

      itemEl.addEventListener('mouseenter', onMouseEnter)
      itemEl.addEventListener('mousemove', onMouseEnter)
      itemEl.addEventListener('mouseleave', onMouseLeave)

      return () => {
        itemEl.removeEventListener('mouseenter', onMouseEnter)
        itemEl.removeEventListener('mousemove', onMouseEnter)
        itemEl.removeEventListener('mouseleave', onMouseLeave)
      }
    }
    return undefined
  }, [ itemEl ])

  if (hideActionCard) return null

  if (draggedBlock) return null

  if (!isEditable) {
    return isViewBlock && (
      <StyledActionableCard
        alignItems="stretch"
        isFirstBlock={isViewBlock}
        fullWidth={fullWidth}
        isActive={isCurrentItemDragging || isHovered}
      >
        <Flex direction="column" css={{ paddingX: 25, marginX: -25 }}>
          <IconButton name="pad-lock" size={12} description="Unlock" hideTooltip variant="light" onClick={() => setEditable(true)} />
        </Flex>
      </StyledActionableCard>
    )
  }

  return (
    <StyledActionableCard
      alignItems="stretch"
      isFirstBlock={isViewBlock}
      fullWidth={fullWidth}
      isActive={isCurrentItemDragging || isHovered}
    >
      <Flex direction="column" css={{ paddingX: 25, marginX: -25 }}>
        {isViewBlock && <IconButton name="pad-lock-open" size={12} description="Lock" hideTooltip variant="light" onClick={() => setEditable(false)} />}
        {!isViewBlock && <IconButton className="handle" name="drag" size={12} description="Drag" hideTooltip variant="light" {...listeners} />}
        {onEdit && <IconButton name="edit" size={12} description="Edit" hideTooltip variant="light" onClick={onEdit} />}
        {!isViewBlock && onRemove && <IconButton name="trash" size={12} description="Delete" hideTooltip variant="light" onClick={onRemove} />}
      </Flex>
    </StyledActionableCard>
  )
}

const Dropzones = ({ id }: { id: string }) => {
  const { blockIds: blockIdsState } = useDashboard()
  const { activeUrn } = useDashboardViewContext()
  const blockIds = useRecoilValue(blockIdsState(activeUrn!))

  const { setNodeRef: setLeftNodeRef, isOver: isOverLeft } = useDroppable({
    id: `blockId:${id}::left`
  })

  const { setNodeRef: setRightNodeRef, isOver: isOverRight } = useDroppable({
    id: `blockId:${id}::right`
  })

  const renderDroppableIndicator = (isOver: boolean) => (
    <Flex
      justifyContent="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: '100%',
        width: '100%',
        opacity: isOver ? 1 : 0
      }}
    >
      <Flex
        css={{
          height: '100%',
          borderRight: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )

  if (id === 'ADD_BLOCK') return null

  // if id is not in root (blockIds), it means it's inside a columns block
  if (!blockIds.includes(id)) return null

  return (
    <>
      <Flex
        ref={setLeftNodeRef}
        css={{
          position: 'absolute',
          left: -80,
          width: 80,
          height: '100%'
        } as const}
      >
        {renderDroppableIndicator(isOverLeft)}
      </Flex>
      <Flex
        ref={setRightNodeRef}
        css={{
          position: 'absolute',
          right: -80,
          width: 80,
          height: '100%'
        } as const}
      >
        {renderDroppableIndicator(isOverRight)}
      </Flex>
    </>
  )
}

const BlockBorderHighlight = (
  { id, itemEl, isDragging }: {id: string, itemEl: HTMLDivElement | null, isDragging?: boolean}
) => {
  const dashboard = useDashboard()

  const selectedBlockId = useRecoilValue(dashboard.selectedBlockIdState)

  useEffect(() => {
    if (!itemEl) return undefined
    // eslint-disable-next-line no-nested-ternary
    itemEl.style.border = selectedBlockId === id
      ? `1px dashed ${colorVars.dark200}`
      : id && isDragging
        ? `1px dashed ${colorVars.dark700}`
        : '1px solid transparent'

    return () => { itemEl.style.border = '' }
  }, [ id, isDragging, itemEl, selectedBlockId ])

  return null
}

const MasonryItem = React.forwardRef(({
  hideActionCard = true,
  fullWidth = false,
  isViewBlock = false,
  // ResizableProps

  onEdit,
  onRemove,
  onViewSettings,

  // BoxProps
  alignSelf,
  basis,
  children,
  className,
  grow,
  justifySelf,
  shrink,

  id,
  containerId,
  ...others
}: MasonryItemProps,
ref: React.Ref<any>) => {
  const [ itemEl, setItemEl ] = useState<HTMLDivElement | null>(null)

  const memoizedChildren = useMemo(() => children, [ children ])

  const dashboard = useDashboard()

  const { target } = useRecoilValue(dashboard.dashboardEditorState)
  const block = useRecoilValue(dashboard.blockState(id))

  const isInspecting = id && target === Views.EDIT_COMPONENT

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    data: {
      ...block,
      sortable: {
        containerId
      }
    }
  })

  const [ onHoverProps, isHovered, setIsHovered ] = useHover({
    onHoverIn: () => dashboard.selectBlock(block.id),
    onHoverOut: () => dashboard.selectBlock(null),
    pausedRef: { current: !isInspecting }
  })

  const confirm = useConfirmation({ style: 'DIALOG' })

  return (
    <Flex
      alignSelf={alignSelf}
      basis={basis}
      className={className}
      grow={grow}
      justifySelf={justifySelf}
      ref={composeRefs(setItemEl, setNodeRef, ref)}
      shrink={shrink}
      data-masonry-item
      {...others}
      css={{
        position: 'relative',
        transform: CSS.Transform.toString(transform),
        transition,

        opacity: id && isDragging ? 0.5 : 1,
        pointerEvents: id && isDragging ? 'none' : 'auto',
        '&:hover': {
          border: isInspecting ? `1px solid ${colorVars.primary400}` : '1px solid transparent'
        },
        ...(others?.css || {}) as any
      } as const}
      id={id}
      {...attributes}
      onClick={(e: any) => {
        if (isInspecting) {
          dashboard.openDashboardEditorView({
            target: Views.EDIT_BLOCK
          })
          return
        }

        others.onClick?.(e)
      }}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      {...onHoverProps}
    >
      <BlockBorderHighlight
        id={id}
        itemEl={itemEl}
        isDragging={isDragging}
      />
      <Dropzones id={id} />
      {!isInspecting && (
        <ActionableCard
          id={id}
          itemEl={itemEl}
          fullWidth={fullWidth}
          isViewBlock={isViewBlock}
          onViewSettings={onViewSettings}
          hideActionCard={hideActionCard}
          onEdit={onEdit}
          onRemove={() => {
            confirm({
              action: 'delete',
              onConfirmClick: () => Promise.resolve(onRemove?.()),
              recordType: `${block.type.replace('Block', ' Block')}`
            })
          }}
          listeners={listeners}
        />
      )}
      {isInspecting && (
        <InspectWrapper
          params={block}
          type="Block"
          isHovered={isHovered}
        />
      )}
      {memoizedChildren}
    </Flex>
  )
})

export default MasonryItem

export type { MasonryItemProps }
