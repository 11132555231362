import pluralize from 'pluralize'
import React from 'react'
import startCase from 'lodash/startCase'

import Accordian from 'components/accordian/Accordian'
import AccountNameRenderer from 'components/renderers/AccountNameRenderer'
import AssignRoleView from 'components/views/AssignRoleView'
import Button from 'components/buttons/Button'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import Label from 'components/typography/Label'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import Toolbar from 'components/toolbar/Toolbar'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { css, styled } from 'styles/stitches'
import { CustomRole, RoleMembership, RoleMembershipsListDocument, useDestroyRoleMembershipMutation, useRoleMembershipsListQuery } from 'generated/schema'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { Kind } from 'models/Role'
import { useViewDispatch } from 'hooks/useViewContext'
import type { ViewProps } from 'components/views'

type ViewParamsType = {
  role: CustomRole
}

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

const StyledText = styled(Text, {
  paddingLeft: 12,
  fontSize: 14
})

const MonospacedText = styled('span', {
  fontFamily: 'monospace',
  backgroundColor: 'light500',
  padding: '2px 4px'
})

const GRANT_KEY = 'grant'
const ALL_PERMISSION_KEY = '*'
const BLACKLISTED_POLICY_KEYS = [ '_identifier', '_name' ]

const PermissionText = (
  { policyKey, policy, parentName, isNested = false }:
  {
    policyKey: string,
    policy: any,
    parentName: string,
    isNested?: boolean
  }
) => {
  if (BLACKLISTED_POLICY_KEYS.includes(policyKey)) return <></>

  const allPermission = policyKey === ALL_PERMISSION_KEY
  const permission = policy[policyKey]
  const hasNestedPermissions = !Object.keys(permission).includes(GRANT_KEY)

  if (allPermission && !hasNestedPermissions) {
    return (
      <Flex gap={8} alignItems="flex-start">
        {isNested ? (
          <StyledText>
            <li><strong>{startCase(permission[GRANT_KEY])}</strong> all {pluralize(parentName)}</li>
          </StyledText>
        ) : (
          <StyledText>
            <strong>{startCase(permission[GRANT_KEY])}</strong> all {pluralize(parentName)}
          </StyledText>
        )}
      </Flex>
    )
  }

  const hasFullPermission = Object.keys(permission).includes(ALL_PERMISSION_KEY)

  if (hasFullPermission) {
    return (
      <Flex gap={8} alignItems="flex-start">
        <PermissionText
          isNested
          policyKey={Object.keys(permission)[0]}
          policy={permission}
          parentName={policyKey}
        />
      </Flex>
    )
  }

  if (!hasNestedPermissions) {
    if (isNested) {
      return (
        <StyledText>
          <li>
            <strong>{startCase(policy[policyKey][GRANT_KEY])}</strong> {' '}
            {/* eslint-disable-next-line no-underscore-dangle */}
            {parentName} <MonospacedText>{policy[policyKey]._identifier}</MonospacedText>
          </li>
        </StyledText>
      )
    }
    return (
      <Flex gap={8} alignItems="flex-start">
        <StyledText>
          <strong>{startCase(policy[policyKey][GRANT_KEY])}</strong> {' '}
          {/* eslint-disable-next-line no-underscore-dangle */}
          {parentName} <MonospacedText>{policy[policyKey]._identifier}</MonospacedText>
        </StyledText>
      </Flex>
    )
  }

  const nestedPermissionKeys = Object.keys(permission)

  if (hasNestedPermissions && isNested) {
    return (
      <StyledText>
        {nestedPermissionKeys.map((nestedPolicy) => (
          <li>
            <strong>{startCase(permission[nestedPolicy][GRANT_KEY])} </strong> {parentName} {' '}
            {/* eslint-disable-next-line no-underscore-dangle */}
            <MonospacedText>{permission[nestedPolicy]._identifier}</MonospacedText>.
          </li>
        ))}
      </StyledText>
    )
  }

  return (
    <Flex gap={8} alignItems="flex-start">
      <StyledText>
        {isNested
          ? (
            <li>
              <strong>{startCase(parentName)}</strong> {' '}
              {/* eslint-disable-next-line no-underscore-dangle */}
              {policy[policyKey]._identifier && (
                <>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <MonospacedText>{policy[policyKey]._identifier}</MonospacedText>
                </>
              )}
              {' '} has the following permissions:
            </li>
          )
          : (
            <>
              <strong>{startCase(parentName)}</strong> {' '}
              {/* eslint-disable-next-line no-underscore-dangle */}
              {policy[policyKey]._identifier && (
                <>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <MonospacedText>{policy[policyKey]._identifier}</MonospacedText>
                </>
              )}
              has the following permissions:
            </>
          )}
        {nestedPermissionKeys.map((nestedPolicy) => nestedPolicy !== GRANT_KEY && (
          <>
            <PermissionText
              isNested
              policyKey={nestedPolicy}
              policy={permission}
              parentName={nestedPolicy}
            />
          </>
        ))}
      </StyledText>
    </Flex>
  )
}

const GroupMembers = ({ role }: {role: CustomRole}) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      roleId: { eq: role.id },
      accountId: 'null'
    },
    page,
    limit: pageSize
  }

  const {
    data: { roleMembershipsList = [], roleMembershipsAggregate = {} } = {},
    loading,
    error
  } = useRoleMembershipsListQuery({
    variables: queryVariables
  })

  const openAssignGroupRole = () => {
    openView({
      title: 'Assign Role',
      component: AssignRoleView,
      params: {
        roleId: role.id,
        role,
        appId: role.appId,
        mode: 'group',
        roleMemberships: roleMembershipsList,
        kind: Kind.WORKSPACE,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const openDeleteRoleMembershipDialog = (roleMembership: RoleMembership) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: roleMembership.id }),
      recordType: 'Role',
      recordDescription: role.name
    })
  }

  const contents = [
    { dataKey: 'group.name', title: 'Name', slot: 'primary' }
  ]

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (roleMembership: RoleMembership) => openDeleteRoleMembershipDialog(roleMembership)
    }
  ]

  return (
    <>
      <Toolbar
        title="Group Members"
        secondaryElements={(
          <Button icon="add-thin" onClick={openAssignGroupRole} size="small" />
        )}
      />
      <DataList
        actions={actions}
        contents={contents as any}
        data={roleMembershipsList as any}
        error={error}
        loading={loading}
        selectionMode="none"
        paginationMode="finite"
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        totalRows={roleMembershipsAggregate?.count || 0}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
      />
    </>
  )
}

const AccountMembers = ({ role }: {role: CustomRole}) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      roleId: { eq: role.id },
      groupId: 'null'
    },
    page,
    limit: pageSize
  }

  const {
    data: { roleMembershipsList = [], roleMembershipsAggregate = {} } = {},
    loading,
    error
  } = useRoleMembershipsListQuery({
    variables: queryVariables
  })

  const openAssignAccountRole = () => {
    openView({
      title: 'Assign Role',
      component: AssignRoleView,
      params: {
        roleId: role.id,
        role,
        appId: role.appId,
        roleMemberships: roleMembershipsList,
        mode: 'account',
        kind: Kind.WORKSPACE,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const openDeleteRoleMembershipDialog = (roleMembership: RoleMembership) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: roleMembership.id }),
      recordType: 'Role',
      recordDescription: role.name
    })
  }

  const contents = [
    { dataKey: 'account', title: 'Name', slot: 'primary', renderer: AccountNameRenderer },
    { dataKey: 'account.email', title: 'Email', slot: 'secondary' }
  ]

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (roleMembership: RoleMembership) => openDeleteRoleMembershipDialog(roleMembership)
    }
  ]

  return (
    <>
      <Toolbar
        title="Account Members"
        secondaryElements={(
          <Button icon="add-thin" onClick={openAssignAccountRole} size="small" />
        )}
      />
      <DataList
        actions={actions}
        contents={contents as any}
        data={roleMembershipsList as any}
        error={error}
        loading={loading}
        selectionMode="none"
        paginationMode="finite"
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        totalRows={roleMembershipsAggregate?.count || 0}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
      />
    </>
  )
}

const Members = ({ role }: { role: CustomRole }) => (
  <Flex direction="column" gap={36}>
    <Text>The following accounts and groups have this role assigned to them:</Text>
    <AccountMembers role={role} />
    <GroupMembers role={role} />
  </Flex>
)

function RoleView({
  closeView, onRequestClose, params: { role }, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const title = `Role: ${role.name}`
  const operationsPolicies = role.policy.operations || {}
  const resourcesPolicies = role.policy.resources || {}

  const resources = Object.keys(resourcesPolicies).map((id) => ({
    id,
    // eslint-disable-next-line no-underscore-dangle
    name: resourcesPolicies[id]._name,
    // eslint-disable-next-line no-underscore-dangle
    identifier: resourcesPolicies[id]._identifier
  })) || []

  const operations = Object.keys(operationsPolicies).map((id) => ({
    id,
    // eslint-disable-next-line no-underscore-dangle
    name: operationsPolicies[id]._name,
    // eslint-disable-next-line no-underscore-dangle
    identifier: operationsPolicies[id]._identifier
  })) || []

  const renderOverview = () => (
    <Flex direction="column" gap={36}>
      <Flex direction="column" gap={12}>
        <Label>Name</Label>
        <Text>{role.name}</Text>
      </Flex>
      <Flex direction="column" gap={12}>
        <Label>Permissions</Label>
        <Accordian
          data={resources}
          content={{
            icon: 'app-core',
            title: (resource) => resource.name,
            content: (resource) => (
            // eslint-disable-next-line no-underscore-dangle
              <PermissionText policyKey={resource.id} policy={resourcesPolicies} parentName="resource" />
            )
          }}
        />
        <Accordian
          data={operations}
          content={{
            icon: 'trigger',
            // eslint-disable-next-line no-underscore-dangle
            title: (operation) => operation.name,
            content: (operation) => (
            // eslint-disable-next-line no-underscore-dangle
              <PermissionText policyKey={operation.id} policy={operationsPolicies} parentName="resource" />
            )
          }}
        />
      </Flex>
    </Flex>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />

          <Tabs
            tabListBackground="light"
            panelWrapper={Body}
            tabListClassName={classes.tabsList}
          >
            <Tab label="Overview" index={0}>
              {renderOverview()}
            </Tab>
            <Tab label="Members" index={1}>
              <Members role={role} />
            </Tab>
          </Tabs>
        </>
      )}
    </View>
  )
}

RoleView.defaultStyle = 'PANEL' as const

export { PermissionText }

export default RoleView
