import React from 'react'

import DashboardForm from 'components/forms/DashboardForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, DashboardsListQueryVariables, useCreateDashboardMutation, useUpdateDashboardMutation } from 'generated/schema'
import type { CreateDashboardInput, UpdateDashboardInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { ViewProps } from 'components/views'

type DashboardFormValues = CreateDashboardInput | UpdateDashboardInput

type Params = {
  initialValues: object,
  queryVariables: DashboardsListQueryVariables
}

function DashboardFormView({
  onRequestClose,
  params: { initialValues, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ createDashboard ] = useCreateDashboardMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      DashboardsListDocument
    ]
  })
  const [ updateDashboard ] = useUpdateDashboardMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      DashboardsListDocument
    ]
  })

  const handleCreateDashboardSubmit = useSubmitHandler(createDashboard, {
    update: {
      strategy: 'APPEND',
      query: DashboardsListDocument,
      queryVariables,
      dataKey: 'dashboardsList',
      mutation: 'createDashboard'
    }
  })

  const handleUpdateDashboardSubmit = useSubmitHandler(updateDashboard, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateDashboard',
      typename: 'Dashboard',
      defaultValue: {
        menuElements: []
      },
      override: (values: UpdateDashboardInput) => ({
        ...initialValues,
        ...values
      })
    }
  })

  const handleDashboardFormSubmit = (values: DashboardFormValues, form: FormPropsWithId<UpdateDashboardInput>['form']) => {
    if ('id' in values) {
      return handleUpdateDashboardSubmit(values, form)
    }

    return handleCreateDashboardSubmit(values as CreateDashboardInput)
  }

  const title = 'id' in initialValues ? 'Edit Dashboard' : 'Create Dashboard'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <DashboardForm
              initialValues={initialValues}
              onSubmit={handleDashboardFormSubmit}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default DashboardFormView
