import BarChartBlockWrapper from 'components/blocks/wrappers/BarChartBlockWrapper'
import DataListBlockWrapper from 'components/blocks/wrappers/DataListBlockWrapper'
import DataTableBlockWrapper from 'components/blocks/wrappers/DataTableBlockWrapper'
import DetailsBlockWrapper from 'components/blocks/wrappers/DetailsBlockWrapper'
import DropdownBlockWrapper from 'components/blocks/wrappers/DropdownBlockWrapper'
import FormBlockWrapper from 'components/blocks/wrappers/FormBlockWrapper'
import LineChartBlockWrapper from 'components/blocks/wrappers/LineChartBlockWrapper'
import PieChartBlockWrapper from 'components/blocks/wrappers/PieChartBlockWrapper'
import QuickLinksBlockWrapper from 'components/blocks/wrappers/QuickLinksBlockWrapper'
import StatBlockWrapper from 'components/blocks/wrappers/StatBlockWrapper'
import TimeSeriesBlockWrapper from 'components/blocks/wrappers/TimeSeriesBlockWrapper'
import TitleBlockWrapper from 'components/blocks/wrappers/TitleBlockWrapper'

export default {
  BarChartBlockWrapper,
  DataListBlockWrapper,
  DataTableBlockWrapper,
  DetailsBlockWrapper,
  LineChartBlockWrapper,
  PieChartBlockWrapper,
  QuickLinksBlockWrapper,
  StatBlockWrapper,
  TimeSeriesBlockWrapper,
  TitleBlockWrapper,
  FormBlockWrapper,
  DropdownBlockWrapper
}
