/* eslint-disable camelcase */
import dayjs from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import BaseDurationInput from 'components/inputs/DurationInput/BaseDurationInput'
import type { DatePickerQuickLinksType } from 'components/date/DatePicker'
import type { InputType } from 'components/inputs/DurationInput/BaseDurationInput'

dayjs.extend(duration)

type DurationInputOwnProps = {
  helpText?: string,
  isTranslatable?: boolean,
  quickLinks?: DatePickerQuickLinksType[],
  size?: 'small' | 'normal' | 'large',
  inputFormat?: 'YY:MM:WW:DD:HH:mm' | 'MM:WW:DD:HH:mm'| 'WW:DD:HH:mm' | 'DD:HH:mm'| 'HH:mm' | 'mm'
  | 'YY:MM:WW:DD:HH:mm' | 'MM:WW:DD:HH:mm'| 'WW:DD:HH:mm' | 'DD:HH:mm:ss'| 'HH:mm:ss' | 'mm:ss'| 'ss'
}

type DurationInputProps = FieldRenderProps<number, HTMLInputElement> & DurationInputOwnProps

type InputFieldValue = Record<InputType, string | undefined>

/**
 * UX improvements for better typing experience
 * @param inputValue
 * @param type
 */
const shouldEarlyReturn = (
  inputValue: InputFieldValue,
  type: keyof InputFieldValue,
  inputFormat: string
) => {
  const value = inputValue[type]

  if (!value || inputFormat.startsWith(type)) {
    return false
  }

  const numValue = parseInt(value, 10)

  if (type === 'DD') {
    if (numValue > 31) {
      return true
    }
  }

  if (type === 'WW') {
    if (numValue > 4) {
      return true
    }
  }

  if (type === 'MM') {
    if (numValue > 11) {
      return true
    }
  }

  if (type === 'HH') {
    if (numValue > 23) {
      return true
    }
  }

  if (type === 'mm') {
    if (numValue > 59) {
      return true
    }
  }

  if (type === 'ss') {
    if (numValue > 59) {
      return true
    }
  }

  return false
}

const padInputValue = (
  inputValue: InputFieldValue,
  type: keyof InputFieldValue,
  inputFormat: string
) => {
  const value = inputValue[type]

  if (!value || inputFormat.startsWith(type)) {
    return
  }

  const numValue = parseInt(value, 10)

  if (type === 'DD') {
    if (numValue > 3 && numValue < 10) {
      inputValue.DD = value.padStart(2, '0')
    }
  }

  if (type === 'MM') {
    if (numValue > 1 && numValue < 10) {
      inputValue.MM = value.padStart(2, '0')
    }
  }

  if (type === 'WW') {
    if (numValue > 0 && numValue < 5) {
      inputValue.WW = value.padStart(2, '0')
    }
  }

  if (type === 'HH') {
    if (numValue > 2 && numValue < 10) {
      inputValue.HH = value.padStart(2, '0')
    }
  }

  if (type === 'mm') {
    if (numValue > 5 && numValue < 10) {
      inputValue.mm = value.padStart(2, '0')
    }
  }

  if (type === 'ss') {
    if (numValue > 5 && numValue < 10) {
      inputValue.mm = value.padStart(2, '0')
    }
  }
}

const initialFieldValues = {
  YYYY: undefined,
  MM: '',
  DD: '',
  YY: '',
  WW: '',
  hh: '',
  HH: '',
  mm: '',
  ss: '',
  A: ''
}

const inputTypeToPlaceholderMap = {
  YYYY: undefined,
  MM: 'mo',
  DD: 'd',
  YY: 'yr',
  WW: 'wk',
  hh: 'h',
  HH: 'h',
  mm: 'm',
  ss: 's',
  A: undefined
}

function DurationInput({
  disabled,
  input,
  inputFormat = 'HH:mm:ss',
  meta,
  ...others
}: DurationInputProps) {
  const [ inputFieldValue, setInputFieldValue ] = useState<InputFieldValue>(initialFieldValues)
  const durationInputsRef = useRef<Array<HTMLInputElement>>([])

  useEffect(() => {
    const setDuration = (inputDuration: Duration) => {
      const doSetDuration = (key: InputType, value: string) => {
        setInputFieldValue((currInputValue) => (
          currInputValue[key] === value
            ? currInputValue
            : { ...currInputValue, [key]: value }
        ))
      }

      if (inputFormat.includes('YY')) {
        if (inputFormat.startsWith('YY')) {
          doSetDuration('YY', Math.floor(inputDuration.asYears()).toString().padStart(2, '0'))
        } else {
          doSetDuration('YY', inputDuration.years().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('WW')) {
        if (inputFormat.startsWith('WW')) {
          doSetDuration('WW', Math.floor(inputDuration.asWeeks()).toString().padStart(2, '0'))
        } else {
          doSetDuration('WW', inputDuration.weeks().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('MM')) {
        if (inputFormat.startsWith('MM')) {
          doSetDuration('MM', Math.floor(inputDuration.asMonths()).toString().padStart(2, '0'))
        } else {
          doSetDuration('MM', inputDuration.months().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('DD')) {
        if (inputFormat.startsWith('DD')) {
          doSetDuration('DD', Math.floor(inputDuration.asDays()).toString().padStart(2, '0'))
        } else {
          doSetDuration('DD', inputDuration.days().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('HH')) {
        if (inputFormat.startsWith('HH')) {
          doSetDuration('HH', Math.floor(inputDuration.asHours()).toString().padStart(2, '0'))
        } else {
          doSetDuration('HH', inputDuration.hours().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('mm')) {
        if (inputFormat.startsWith('mm')) {
          doSetDuration('mm', Math.floor(inputDuration.asMinutes()).toString().padStart(2, '0'))
        } else {
          doSetDuration('mm', inputDuration.minutes().toString().padStart(2, '0'))
        }
      }

      if (inputFormat.includes('ss')) {
        if (inputFormat.startsWith('ss')) {
          doSetDuration('ss', Math.floor(inputDuration.asSeconds()).toString().padStart(2, '0'))
        } else {
          doSetDuration('ss', inputDuration.seconds().toString().padStart(2, '0'))
        }
      }
    }

    input.value && setDuration(dayjs.duration(input.value, 'seconds'))
  }, [ input.value, inputFormat ])

  const onChangeRef = useRef(input.onChange)

  useLayoutEffect(() => {
    onChangeRef.current = input.onChange
  })

  useEffect(() => {
    const inputLength = Object.values(inputFieldValue).filter(Boolean).reduce((a, i = '') => a + i.length, 0)
    if (inputLength >= inputFormat.replaceAll(':', '').length) {
      onChangeRef.current(dayjs.duration({
        seconds: inputFieldValue.ss ? parseInt(inputFieldValue.ss, 10) : undefined,
        minutes: inputFieldValue.mm ? parseInt(inputFieldValue.mm, 10) : undefined,
        hours: inputFieldValue.HH ? parseInt(inputFieldValue.HH, 10) : undefined,
        days: inputFieldValue.DD ? parseInt(inputFieldValue.DD, 10) : undefined,
        months: inputFieldValue.MM ? parseInt(inputFieldValue.MM, 10) : undefined,
        weeks: inputFieldValue.WW ? parseInt(inputFieldValue.WW, 10) : undefined,
        years: inputFieldValue.YY ? parseInt(inputFieldValue.YY, 10) : undefined
      }).asSeconds())
    } else {
      // clears stale value
      if (inputLength > 0) {
        // set invalid value so validation error is raised
        onChangeRef.current('')
      }
      onChangeRef.current(null)
    }
  }, [ inputFieldValue, inputFormat ])

  return (
    <BaseDurationInput
      disabled={disabled}
      input={input}
      inputFormat={inputFormat}
      meta={meta}

      inputsRef={durationInputsRef}
      inputValue={inputFieldValue}
      padInputValue={padInputValue}
      shouldEarlyReturn={shouldEarlyReturn}
      setInputValue={setInputFieldValue}
      getInputPlaceholder={(inputType: InputType) => inputTypeToPlaceholderMap[inputType]}

      {...others}
    />
  )
}

export type { DurationInputProps }

export default DurationInput
