import React, { useState } from 'react'

import DashboardPage from 'components/pages/DashboardPage'
import Masonry from 'components/layout/Masonry'
import type { ViewProps } from '.'

type Params = {
  viewUrn: string
}

function GenericView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ title, setTitle ] = useState('')
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Body, Header, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Body>
            <Masonry>
              <DashboardPage activeUrn={params.viewUrn} setTitle={setTitle} footerEl={footerEl} />
            </Masonry>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}

export default GenericView
