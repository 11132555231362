import React, { useContext } from 'react'
import { useRecoilValue } from 'recoil'
import { useSortable } from '@dnd-kit/sortable'

import DashboardContext from 'components/contexts/DashboardContext'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import TopbarItem from 'components/topbar/TopbarItem'
import useDashboard from 'hooks/useDashboard'
import { styled } from 'styles/stitches'
import { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { Views } from 'components/dashboardEditor/constants'

const StyledAddIcon = styled(TopbarItem, {
  opacity: 0,
  paddingX: 20,
  textAlign: 'center',
  variants: {
    draggingOver: {
      true: {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          inset: 8,
          border: '2px dashed dark700',
          borderRadius: 4
        }
      },
      false: {}
    },
    visible: {
      true: {
        opacity: 1
      }
    }
  }
})

type AddTopbarItemProps = {
  isVisible: boolean
}

function AddTopbarItem({ isVisible }: AddTopbarItemProps) {
  const { currentDashboard } = useContext(InternalContext)!
  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}

  const { openDashboardEditor } = useContext(DashboardContext)!
  const { openDashboardEditorView } = useDashboard()

  const initialValues = { dashboardId, placement: 'TOP', newNonStickyPosition, newStickyPosition }
  const handleClick = () => {
    openDashboardEditorView({
      target: Views.ADD_MENU,
      params: { initialValues }
    })
    openDashboardEditor()
  }

  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    draggingOverMenuIdState
  } = useDashboard()

  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)

  const isMenuDragStarted = !!draggedMenu && !draggedMenu.id && !draggingOverMenuId
  const isAppDragStarted = !!draggedApp && !draggedApp.id && !draggingOverMenuId
  const isResourceDragStarted = !!draggedResource
    && !draggedResource.id && !draggingOverMenuId

  const isDragStarted = isMenuDragStarted || isAppDragStarted || isResourceDragStarted

  const isDraggingOverAddMenu = draggingOverMenuId === 'ADD_MENU_TOP' || draggingOverMenuId === 'TOPBAR'

  const { setNodeRef } = useSortable({
    id: 'ADD_MENU_TOP',
    data: {
      placement: 'TOP',
      isSticky: false,
      parentId: null
    }
  })

  return (
    <StyledAddIcon
      ref={setNodeRef}
      onClick={handleClick}
      visible={isVisible || isDragStarted || isDraggingOverAddMenu}
      draggingOver={isDragStarted || isDraggingOverAddMenu}
    >
      <Icon data-icon name="add-outline-round" size={24} />
    </StyledAddIcon>
  )
}

export default AddTopbarItem
