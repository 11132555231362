import React, { useState } from 'react'

import FormBlock from 'components/blocks/FormBlock'
import type { InternalAddRecordInput, InternalEditRecordInput, Operation } from 'generated/schema'
import type { ViewProps } from 'components/views'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'

type FormValues = InternalAddRecordInput['arguments'] | InternalEditRecordInput['arguments']

type Params = {
  initialValues: FormValues,
  operationId: Operation['id'],
  title: string,
  switcher?: SwitcherResultProps['switcher'],
  resourceId?: string
}

function AddRecordView({
  closeView,
  onRequestClose,
  params: {
    initialValues,
    operationId,
    title,
    switcher,
    resourceId
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Footer, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <FormBlock
              initialValues={initialValues}
              operationId={operationId}
              resourceId={resourceId}
              asFragment
              footerEl={footerEl}
              switcher={switcher}
              prefix="arguments"
              suffix="en_US"
            />
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}

export default AddRecordView
