import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import React from 'react'

import Flex from 'components/layout/Flex'
import ResourceDetailsView from 'components/views/graph/ResourceDetailsView'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import type { RendererOptions } from 'components/dataTable/types'
import type { Attribute } from 'generated/schema'

const StyledText = styled(Text, {
  color: 'currentColor',
  fontWeight: 600,
  flexGrow: 1
})

const TitleText = styled(Text, {
  transition: 'textDecoration 0.2s ease-in-out',

  width: '100%',
  color: 'currentColor',
  fontWeight: 600,
  flexGrow: 1
})

const SecondaryText = styled(Text, {
  width: '100%',
  color: 'dark500',
  fontSize: 12,
  fontWeight: 400,
  flexGrow: 1
})

const Container = styled(Flex, {
  cursor: 'pointer',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: 4,
  width: 0,

  '&:hover': {
    [`& ${TitleText}`]: {
      textDecoration: 'underline'
    }
  }
})

function ReferenceRenderer<T extends object>({
  dataKey,
  rowData,
  attributeProps,
  prefix = '',
  suffix = ''
}: RendererOptions<T>) {
  const { openView } = useViewDispatch()
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const data = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)
  const { resource, attributes = [] } = attributeProps || {}
  const { titleAttributeId, subtitleAttributeId } = resource || {}

  const title = attributes.find(({ id }: Attribute) => id === titleAttributeId)
  const subtitle = attributes.find(({ id }: Attribute) => id === subtitleAttributeId)

  if (resource && title && subtitle) {
    const record = get(rowData, `${prefixContent}${camelCase(resource.identifier)}`)
    const titleContent = get(record, `${prefixContent}${camelCase(title.identifier)}${suffixContent}`, '--')
    const subtitleContent = get(record, `${prefixContent}${camelCase(subtitle.identifier)}${suffixContent}`, '--')

    return (
      <Container
        grow={1}
        onClick={() => openView({
          title: titleContent || `${resource.name} details`,
          component: ResourceDetailsView,
          style: ResourceDetailsView.defaultStyle,
          params: {
            title: titleContent || `${resource.name} details`,
            recordId: record?.[prefix].id[suffix],
            resourceId: resource.id
          }
        })}
      >
        <TitleText
          title={titleContent || '--'}
          truncate
        >
          {titleContent || '--'}
        </TitleText>
        <SecondaryText truncate>
          {subtitleContent || '--'}
        </SecondaryText>
      </Container>
    )
  }

  return (
    <StyledText as="span" truncate>{data}</StyledText>
  )
}

export default ReferenceRenderer
