import last from 'lodash/last'
import React, { useMemo } from 'react'

import AddFieldView from 'components/views/AddFieldView'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataListBlock from 'components/blocks/DataListBlock'
import generatePosition from 'lib/generatePosition'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import SectionLoader from 'components/loaders/SectionLoader'
import useConfirmation from 'hooks/useConfirmation'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { ContentTypeDocument, FieldFragmentFragment as FieldFragment, FieldsListDocument, useDestroyFieldMutation, useFieldsListQuery, useFieldTypesListQuery, useUpdateFieldMutation } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from 'components/blocks/Block'
import type { CreateContentTypeMutation } from 'generated/schema'

const EMPTY_CONTENT_TYPES_SUBTITLE = 'Try adding one.'
const EMPTY_CONTENT_TYPES_TITLE = "You don't have any content types."
const EMPTY_FIELDS_SUBTITLE = 'Try adding one.'
const EMPTY_FIELDS_TITLE = "You don't have any fields."

const FIELDS_LIST_LIMIT = 100

type FieldTypeName = { fieldTypeName?: string }

type FieldsListProps = {
  contentType?: CreateContentTypeMutation['createContentType'],
  contentTypeLoading?: boolean,
  width?: BlockProps['width']
}

enum FieldKind {
  SINGLETON = 'Single Type',
  COLLECTION = 'Collection Type'
}

const FieldsList = ({
  contentType, contentTypeLoading = false, width = { md: '75%' }
}: FieldsListProps) => {
  const { id, kind, name } = contentType || {}

  const confirm = useConfirmation({ style: 'DIALOG' })
  const { openView } = useViewDispatch()

  const queryVariables = {
    filter: {
      contentTypeId: { eq: id }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: FIELDS_LIST_LIMIT
  }

  const skip = !id

  const {
    data: fieldsData, error: fieldsError, loading: fieldsLoading
  } = useFieldsListQuery({
    skip,
    notifyOnNetworkStatusChange: true,
    variables: queryVariables
  })

  const fields = fieldsData?.fieldsList || []

  const {
    data: { fieldTypes = [] } = {}, error: fieldTypesError, loading: fieldTypesLoading
  } = useFieldTypesListQuery()

  const [ destroyField ] = useDestroyFieldMutation({
    refetchQueries: [ { query: ContentTypeDocument, variables: { id } } ]
  })

  const handleDestroyField = useSubmitHandler(destroyField, {
    // TODO: This causes a full refresh (FE2-12)
    /* optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyField',
      typename: 'Field',
      override: (input) => fields.find((field) => field.id === input.id)
    }, */
    update: {
      strategy: 'REMOVE',
      query: FieldsListDocument,
      dataKey: 'fieldsList',
      mutation: 'destroyField',
      queryVariables
    }
  })

  const [ updateField ] = useUpdateFieldMutation()

  const handleUpdateField = useSubmitHandler(updateField)
  const reorder = useReorder({
    query: FieldsListDocument,
    variables: queryVariables,
    dataKey: 'fieldsList',
    callback: handleUpdateField
  })

  const identifierToFieldTypeMap = useMemo(() => getPropertyToElementMap(fieldTypes, 'identifier'), [ fieldTypes ])

  const fieldsWithFieldType = fields
    .map((field) => {
      let fieldTypeName = ''
      if (identifierToFieldTypeMap[field.fieldType]?.name) {
        fieldTypeName = `${field.isArray ? 'Repeated' : ''} ${identifierToFieldTypeMap[field.fieldType].name}`
      }

      return { ...field, fieldTypeName }
    }) as Array<FieldFragment & FieldTypeName>

  const openAddFieldView = () => {
    openView({
      title: 'New Field',
      component: AddFieldView,
      style: 'PANEL',
      params: {
        initialValues: {
          contentTypeId: id,
          position: generatePosition(last(fieldsWithFieldType)?.position)
        }
      }
    })
  }

  const openEditFieldView = (field: FieldFragment) => {
    openView({
      title: 'Edit Field',
      component: AddFieldView,
      style: 'PANEL',
      params: {
        initialValues: field
      }
    })
  }

  const openDeleteContentTypeView = (field: FieldFragment) => {
    confirm({ action: 'delete',
      onConfirmClick: () => handleDestroyField({ id: field.id }),
      recordType: 'Field',
      recordDescription: field.name })
  }

  const primaryElement = (
    <Chip label={FieldKind[kind!]} variant="primary_inverse" />
  )

  const secondaryElement = <Button icon="add-thin" onClick={openAddFieldView} size="small" />

  const actions = ([
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openEditFieldView
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: openDeleteContentTypeView
    }
  ])

  const batchActions = [
    {
      icon: 'trash',
      title: 'Delete',
      isSecondary: true,
      onClick: () => {}
    }
  ]

  if (!contentType) {
    return (
      <Block width={{ md: '75%' }}>
        <SectionLoader
          data={contentType}
          empty={{ title: EMPTY_CONTENT_TYPES_TITLE, subtitle: EMPTY_CONTENT_TYPES_SUBTITLE }}
          loading={contentTypeLoading}
        />
      </Block>
    )
  }

  return (
    <DataListBlock
      actions={actions}
      batchActions={batchActions}
      contents={[
        { dataKey: 'name', slot: 'primary' },
        { dataKey: 'identifier', slot: 'secondary' },
        { dataKey: 'fieldTypeName', slot: 'meta', renderer: ChipRenderer }
      ]}
      data={fieldsWithFieldType}
      empty={{ title: EMPTY_FIELDS_TITLE, subtitle: EMPTY_FIELDS_SUBTITLE }}
      error={fieldsError || fieldTypesError}
      onRowDragEnd={reorder}
      selectionMode="none"
      loading={fieldsLoading || fieldTypesLoading}
      primaryElements={primaryElement}
      secondaryElements={secondaryElement}
      title={name}
      width={width}
    />
  )
}

export default FieldsList

export { FIELDS_LIST_LIMIT }
