import camelCase from 'lodash/camelCase'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import Block, { BlockProps } from './Block'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import JSONParseOr from 'lib/JSONParseOr'
import PigeonContentView from 'components/views/messaging/PigeonContentView'
import SectionLoader from 'components/loaders/SectionLoader'
import Toolbar from 'components/toolbar/Toolbar'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Delivery, useExecuteMutationOperationMutation } from 'generated/schema'
import { parseAndRenderSync } from 'lib/templater'

type EmailPreviewProps = Delivery['content']
type EmailPreviewBlockProps = BlockProps & EmailPreviewProps

function EmailPreviewBlock(
  {
    blockRef,
    from,
    subject,
    preview,
    html_body: htmlBody,
    plain_body: plainBody,
    reply_to: replyTo,
    imageData,
    data,
    heading,
    block,
    switcher,
    ...other
  }: EmailPreviewBlockProps
) {
  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)

  const content = useMemo(() => ({
    from: from && parseAndRenderSync(from, blockProperties),
    subject: subject && parseAndRenderSync(subject, blockProperties),
    preview: preview && parseAndRenderSync(preview, blockProperties),
    htmlBody: htmlBody && parseAndRenderSync(htmlBody, blockProperties),
    plainBody: plainBody && parseAndRenderSync(plainBody, blockProperties),
    replyTo: replyTo && parseAndRenderSync(replyTo, blockProperties),
    imageData: imageData && parseAndRenderSync(imageData, blockProperties),
    data: data && parseAndRenderSync(data, blockProperties)
  }), [
    from,
    subject,
    preview,
    htmlBody,
    plainBody,
    replyTo,
    imageData,
    data,
    blockProperties
  ])

  const [ executeAction ] = useExecuteMutationOperationMutation()
  const handleExecuteOperation = useSubmitHandler(executeAction)

  const secondaryElements = (
    <Flex gap={16} alignItems="center">
      {block.actions?.map((action: any) => {
        if (action.behavior === 'RUN_OPERATION') {
          const input = Object.fromEntries(
            Object.entries(
              action.input || {}
            ).map(([ key, value ]: any[]) => (
              [ camelCase(key), JSONParseOr(parseAndRenderSync(value || '', blockProperties)) ]
            ))
          )

          if (action.display_style === 'PRIMARY') {
            return (
              <Button
                icon={action.identifier}
                disabled={Object.values(input).every((v) => !v)}
                onClick={() => handleExecuteOperation({
                  arguments: input,
                  operationId: action.operation,
                  targetEnvironment: switcher?.data.environment?.id
                })}
                size="small"
              />
            )
          }

          return (
            <IconButton
              variant="darker"
              disabled={Object.values(input).every((v) => !v)}
              name={action.identifier}
              description={action.name}
              onClick={() => handleExecuteOperation({
                arguments: input,
                operationId: action.operation,
                targetEnvironment: switcher?.data.environment?.id
              })}
              size={24}
            />
          )
        }

        if (action.behavior === 'REDIRECT') {
          const url = parseAndRenderSync(action.url, { ...blockProperties, environment: switcher?.data.environment?.id || '' })

          if (action.display_style === 'PRIMARY') {
            return (
              <Button
                icon={action.identifier}
                href={url}
                target={action.target}
                size="small"
              />
            )
          }

          return (
            <IconButton
              variant="darker"
              name={action.identifier}
              description={action.name}
              onClick={() => window.open(url, action.target, 'noopener noreferrer')}
              size={24}
              disabled={Object.values(content).filter(Boolean).length === 0}
            />
          )
        }
        return null
      })}
    </Flex>
  )

  return (
    <Block gap={20} direction="column" masonryItemRef={blockRef} {...other}>
      <Toolbar
        title={heading}
        secondaryElements={secondaryElements}
      />
      <SectionLoader
        data={Object.values(content).filter(Boolean)}
        empty={{ title: 'No Preview available.' }}
      >
        <PigeonContentView
          content={content}
          templateSubkind="EMAIL"
          variant="compact"
        />
      </SectionLoader>
    </Block>
  )
}

export default EmailPreviewBlock
