/* eslint-disable camelcase */
import React, { Suspense, useMemo } from 'react'
import startCase from 'lodash/startCase'
import { Form, useField, useForm } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import AttributeModel from 'models/Attribute'
import Button from 'components/buttons/Button'
import componentLoader from 'lib/componentLoader'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Label from 'components/typography/Label'
import Loader from 'components/loaders/Loader'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard from 'hooks/useDashboard'
import { DataSource } from './EditBlockView'
import { DataTypeKind } from 'models/DataType'
import { DISPLAY_TYPES, getAllowedDisplayTypes, getDisplayViewFileName } from './graph/CreateAttributeView'
import { SidePaneFooter } from 'components/sidePane'
import { Attribute, DataType, useAttributeQuery, useDataTypesListQuery } from 'generated/schema'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { FieldIdentifier } from 'models/Field'

type Params = ViewParams[Views.ADD_COLUMN]

const DisplayTypeSettings = (
  { attribute, dataTypes }: { attribute?: Attribute, dataTypes?: DataType[] }
) => {
  const form = useForm()
  const displayType = useField('display_type').input.value
  const displayViewFileName = displayType ? getDisplayViewFileName(displayType) : null

  const dataTypeId = useField('data_type').input.value
  const dataType = attribute?.dataType.kind || dataTypes?.find((d) => d.id === dataTypeId)?.kind

  const allowedDisplayTypes = dataType
    ? getAllowedDisplayTypes(
      dataType as DataTypeKind,
      attribute?.fieldType as FieldIdentifier
    ) : DISPLAY_TYPES

  const Settings = useMemo(() => (displayViewFileName ? React.lazy(
    () => componentLoader(`displayTypes/${displayViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('displayTypes/PlainTextView'))
      .then((module) => ({ default: module.default?.Configurations }))
  ) : () => <></>), [ displayViewFileName ])

  return (
    <>
      <FormField
        alwaysDirty
        isClearable
        component={SelectInput}
        name="display_type"
        label="Renderer"
        size="small"
        placeholder={startCase(attribute?.displayType.toLowerCase())}
        defaultValue={allowedDisplayTypes[0]?.value}
        options={allowedDisplayTypes}
        onChange={(option: any) => {
          form.change('display_type', option?.value || null)
          form.change('display_type_settings', {})
        }}
      />
      {AttributeModel.hasDisplayTypeSettings(displayType) && (
        <Suspense fallback={<Loader loading />}>
          <Settings fieldPrefix="display_type_settings." />
        </Suspense>
      )}
    </>
  )
}

const AddColumnView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    selectBlock,
    stepBackDashboardEditor,
    updateBlock
  } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues, block, type, currentIndex, isUpdating } = params! as Params
  const { attribute: attributeId } = initialValues || {}

  const isResourceType = type === DataSource.RESOURCE

  const {
    data: { attribute } = {},
    loading: loadingAttribute
  } = useAttributeQuery({
    variables: { id: attributeId },
    skip: !attributeId
  })

  const {
    data: { dataTypesList } = {},
    loading: loadingDataTypes
  } = useDataTypesListQuery()

  const { urn } = useDashboardEditorContextProvider()
  const stepBack = (values?: any) => {
    if (values && (values.label || values.value || values.attribute)) {
      let updatedColumns

      if (isUpdating) {
        updatedColumns = block.properties?.columns.map(
          (c: any, index: number) => {
            if (index === currentIndex) return { ...c, ...values }
            return c
          }
        ) || []
      } else {
        updatedColumns = [ ...block.properties?.columns, values ]
      }

      updateBlock(urn, {
        ...block,
        properties: {
          ...block.properties,
          columns: updatedColumns
        }
      })
    }

    selectBlock(block.id)
    stepBackDashboardEditor(2)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Add'} Column`}
        heading="Edit Table Block"
        onClose={onClose}
        onStepBack={stepBack}
      />
      <Form
        initialValues={initialValues}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <DashboardEditorLoader
                  empty={{
                    variant: 'simple',
                    element: (
                      <Flex alignItems="center" direction="column">
                        <Text fontSize={14} color="dark500">Missing attribute or data type.</Text>
                      </Flex>
                    )
                  }}
                  data={attribute || dataTypesList || []}
                  loading={loadingAttribute || loadingDataTypes}
                >
                  {isResourceType && (
                    <Flex direction="column" gap={6}>
                      <Label fontSize={10} fontWeight="bold">Linked Attribute</Label>
                      {attribute && (
                        <Flex justifyContent="space-between">
                          <Text fontSize={14} fontWeight="bold">{`${attribute?.resource.name}: ${attribute?.name}`}</Text>
                          <TextLink
                            as="button"
                            type="button"
                            fontSize={12}
                            onClick={() => openDashboardEditorView({
                              target: Views.LINK_ATTRIBUTE,
                              params: {
                                parentResourceId: block.properties.data_source_settings?.resource,
                                resourceId: block.properties.data_source_settings?.resource,
                                isUpdating: true,
                                columnValues: form.getState().values,
                                block,
                                type,
                                isColumnUpdating: isUpdating,
                                currentIndex
                              }
                            })}
                            mode="distinct"
                          >
                            Edit
                          </TextLink>
                        </Flex>
                      )}
                      {!isUpdating && !attribute && (
                        <TextLink
                          as="button"
                          alignSelf="flex-start"
                          type="button"
                          fontSize={12}
                          onClick={() => openDashboardEditorView({
                            target: Views.LINK_ATTRIBUTE,
                            params: {
                              parentResourceId: block.properties.data_source_settings?.resource,
                              resourceId: block.properties?.data_source_settings?.resource,
                              columnValues: form.getState().values,
                              block,
                              type,
                              currentIndex,
                              isColumnUpdating: isUpdating
                            }
                          })}
                          mode="distinct"
                        >
                          Link Attribute
                        </TextLink>
                      )}
                    </Flex>
                  )}
                  {!isResourceType && (
                    <FormField
                      alwaysDirty
                      component={SelectInput}
                      name="data_type"
                      label="Data Type"
                      size="small"
                      options={dataTypesList?.filter((d) => d.isPrimitive)}
                      loading={loadingDataTypes}
                      labelKey="name"
                      valueKey="id"
                      defaultValue={dataTypesList?.find((d) => d.kind === DataTypeKind.STRING)?.id}
                    />
                  )}
                  <FormField
                    name="label"
                    label="Label"
                    component={TextInput}
                    placeholder={attribute?.name}
                    size="small"
                  />
                  <FormField
                    name="width"
                    label="Width"
                    component={TextInput}
                    size="small"
                  />
                  {!isResourceType && <FormField checkRequired name="value" label="Value" component={TextInput} size="small" />}
                  {!isResourceType && <FormField name="is_orderable" type="checkbox" label="Sortable?" component={ToggleInput} size="small" />}
                  <DisplayTypeSettings
                    attribute={attribute as Attribute}
                    dataTypes={dataTypesList as DataType[]}
                  />
                  <input type="submit" style={{ display: 'none' }} />
                </DashboardEditorLoader>
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default AddColumnView
