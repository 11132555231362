import React, { useContext } from 'react'
import get from 'lodash/get'

import AccountModel from 'models/Account'
import AccountNameRenderer from 'components/renderers/AccountNameRenderer'
import DataTable from 'components/dataTable/DataTable'
import DateRenderer from 'components/renderers/DateRenderer'
import PageLoader from 'components/loaders/PageLoader'
import { ContentVersionContext } from 'components/contentVersion/ContentVersionProvider'
import { contentVersionStatusVariants } from 'models/Content'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { generateStatusRenderer } from 'components/renderers/StatusRenderer'
import type { ContentVersion } from 'models/Content'
import type { Column, RendererOptions } from 'components/dataTable/types'
import type { LoaderProps } from 'components/loaders/Loader'
import type { ViewStyleComponentRenderProps } from 'components/views'

const dateRenderer = (props: RendererOptions) => (
  <DateRenderer {...props} full />
)

type VersionListProps = ViewStyleComponentRenderProps & LoaderProps & {
  onRequestClose: () => void,
  onSelect: (version: ContentVersion) => void
}

const StatusRenderer = generateStatusRenderer<ContentVersion>({
  statusVariants: contentVersionStatusVariants
})

function VersionList({
  Body,
  error,
  Header,
  loading,
  onRequestClose,
  onSelect
}: VersionListProps) {
  const {
    versions, versionsListPagerProps, versionsCount
  } = useContext(ContentVersionContext)
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = versionsListPagerProps

  const columns: Column<ContentVersion>[] = [
    { dataKey: 'name', title: 'Name', sortable: true, style: { width: '25%' }, renderer: generateLinkRenderer({ onClick: onSelect }) },
    { dataKey: 'createdBy', title: 'Created By', sortable: true, style: { width: 300 }, renderer: AccountNameRenderer, renderCellTitle: ({ rowData, dataKey }) => AccountModel.getFullName(get(rowData, dataKey)) },
    { dataKey: 'createdAt', title: 'Creation Time', style: { flexGrow: 1 }, renderer: dateRenderer },
    { dataKey: 'status', title: 'Status', sortable: true, style: { flexGrow: 1, justifyContent: 'flex-end' }, renderer: StatusRenderer }
  ]

  return (
    <>
      <Header title="History" onCloseClick={onRequestClose} wide />
      <Body wide>
        <PageLoader
          data={versions}
          error={error}
          loading={loading}
        >
          <DataTable
            actions={[]}
            columns={columns}
            data={versions}
            selectionMode="none"
            onChangePage={handlePageChange}
            onChangePageSize={handlePageSizeChange}
            page={page}
            pageSize={pageSize}
            paginationMode="finite"
            totalRows={versionsCount}
          />
        </PageLoader>
      </Body>
    </>
  )
}

export default VersionList
