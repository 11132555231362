import get from 'lodash/get'
import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { useForm, useFormState } from 'react-final-form'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import Card from 'components/card/Card'
import Chip from 'components/chip/Chip'
import ConditionalField from 'components/form/ConditionalField'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Icon from 'components/icons/Icon'
import JsonField from 'components/contentEditors/generic/fields/JsonField'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { FieldIdentifier } from 'models/Field'
import { Popover, PopoverContainer, PopoverItem } from 'components/popover'
import { styled } from 'styles/stitches'
import { DisplayType, ValidationKind } from 'models/Attribute'
import type { CreateAttributeInput, UpdateAttributeInput, ValidationInput } from 'generated/schema'
import { parseDateTimeValues } from 'components/displayTypes/DateTimeView'

type FormValues = CreateAttributeInput | UpdateAttributeInput & { dataTypeId?: string }

type FieldsMap = {
  all?: boolean,
  only?: FieldIdentifier[],
  except?: FieldIdentifier[]
}

enum MatchPattern {
  CUSTOM = 'custom',
  EMAIL = 'email',
  IPV4 = 'ipv4',
  IPV6 = 'ipv6',
  MAC = 'mac',
  PHONE = 'phone',
  URL = 'url',
  UUID = 'uuid'
}

const MatchPatternOptions = [
  { label: 'Email', value: MatchPattern.EMAIL },
  { label: 'Phone', value: MatchPattern.PHONE },
  { label: 'Url', value: MatchPattern.URL },
  { label: 'Mac', value: MatchPattern.MAC },
  { label: 'Ipv4', value: MatchPattern.IPV4 },
  { label: 'Ipv6', value: MatchPattern.IPV6 },
  { label: 'Uuid', value: MatchPattern.UUID },
  { label: 'Custom', value: MatchPattern.CUSTOM }
]

const ValidationTypes = [
  {
    label: 'Required',
    identifier: ValidationKind.PRESENCE,
    fieldsMap: {
      all: true
    },
    defaultSettings: {}
  },
  {
    label: 'Prevent Duplicates',
    identifier: ValidationKind.UNIQUENESS,
    fieldsMap: {
      all: true,
      except: [
        FieldIdentifier.JSON,
        FieldIdentifier.MEDIA,
        FieldIdentifier.FILE,
        FieldIdentifier.MARKDOWN,
        FieldIdentifier.BOOLEAN,
        FieldIdentifier.PASSWORD,
        FieldIdentifier.SWITCH
      ]
    },
    defaultSettings: {}
  },
  {
    label: 'Limit character count',
    identifier: ValidationKind.LENGTH,
    fieldsMap: {
      only: [
        FieldIdentifier.TEXT,
        FieldIdentifier.MARKDOWN
      ]
    },
    defaultSettings: {
      minimum: null,
      maximum: null,
      equal_to: null,
      not_equal_to: null
    }
  },
  {
    label: 'Matches pattern',
    identifier: ValidationKind.MATCHES,
    fieldsMap: {
      only: [
        FieldIdentifier.TEXT
      ]
    },
    defaultSettings: {
      pattern: null
    }
  },
  {
    label: 'Doesn\'t match pattern',
    identifier: ValidationKind.NOT_MATCHES,
    fieldsMap: {
      only: [
        FieldIdentifier.TEXT
      ]
    },
    defaultSettings: {
      pattern: null
    }
  },
  {
    label: 'Accept specific values',
    identifier: ValidationKind.INCLUSION,
    fieldsMap: {
      only: [
        FieldIdentifier.TEXT,
        FieldIdentifier.NUMBER,
        FieldIdentifier.PHONE
      ]
    },
    defaultSettings: {
      values: null,
      matchString: null
    }
  },
  {
    label: 'Reject specific values',
    identifier: ValidationKind.EXCLUSION,
    fieldsMap: {
      only: [
        FieldIdentifier.TEXT,
        FieldIdentifier.NUMBER,
        FieldIdentifier.PHONE
      ]
    },
    defaultSettings: {
      values: null,
      matchString: null
    }
  }
]

const ValidationCard = styled(Card, {
  ...mixins.transition('fluid'),

  '[data-icon]': {
    ...mixins.transition('fluid'),
    color: 'dark100'
  },

  '&:hover': {
    '[data-icon]': {
      color: 'dark500'
    }
  },

  variants: {
    variant: {
      bordered: {
        border: '1px dashed dark100',

        '&:hover': {
          borderColor: 'dark500',

          [`${Text}`]: {
            color: 'dark800'
          }
        }
      }
    }
  }
})

const formatValidations = (validations: ValidationInput[]) => {
  const formattedValidations = validations.map(({ kind, settings = {}, criteria }) => {
    const showCriteria = !!criteria

    if ([ ValidationKind.NOT_MATCHES, ValidationKind.MATCHES ].includes(kind as ValidationKind)) {
      const { pattern } = settings

      if (typeof pattern === 'string') return { kind, settings, criteria, showCriteria }

      if (typeof pattern === 'object') {
        const { ipv4, ipv6, custom } = pattern

        if (custom) {
          return ({
            kind,
            settings: { customPattern: pattern[MatchPattern.CUSTOM], pattern: MatchPattern.CUSTOM },
            criteria,
            showCriteria
          })
        }

        if (ipv4) {
          return ({
            kind,
            settings: { ipPattern: pattern[MatchPattern.IPV4]?.join(', '), pattern: MatchPattern.IPV4 },
            criteria,
            showCriteria
          })
        }

        if (ipv6) {
          return ({
            kind,
            settings: { ipPattern: pattern[MatchPattern.IPV6]?.join(', '), pattern: MatchPattern.IPV6 },
            criteria,
            showCriteria
          })
        }
      }
    }

    return { kind, settings, criteria, showCriteria }
  })

  return formattedValidations
}

const parseValidations = (values: FormValues) => {
  let parsedValues = values
  if (values.displayType === DisplayType.DATE_TIME) {
    parsedValues = parseDateTimeValues(values as FormValues)
  }

  const { validations = [] } = parsedValues as FormValues

  const parsedValidations = validations.map((validation) => {
    const { settings = {}, kind, criteria } = validation

    if ([ ValidationKind.NOT_MATCHES, ValidationKind.MATCHES ].includes(kind as ValidationKind)) {
      if (settings.pattern === MatchPattern.CUSTOM) {
        return ({
          kind,
          settings: {
            pattern: {
              [MatchPattern.CUSTOM]: settings.customPattern
            }
          },
          criteria
        })
      }

      if (settings.pattern === MatchPattern.IPV4 || MatchPattern.IPV6) {
        return ({
          kind,
          settings: {
            pattern: {
              [settings.pattern]: settings.ipPattern?.split(',').map((s: string) => s.trim())
            }
          },
          criteria
        })
      }
    }

    return { criteria, kind, settings }
  })

  return { ...parsedValues, validations: parsedValidations }
}

const getShowValidation = (fieldType: FieldIdentifier, fieldsMap: FieldsMap) => {
  const { all, except, only } = fieldsMap

  if (all) {
    if (except?.includes(fieldType)) return false
    return true
  }

  if (only?.length) {
    if (only.includes(fieldType)) return true
    return false
  }

  return false
}

const ValidationOptions = ({ identifier, prefix = '' }: { identifier: ValidationKind, prefix: string }) => {
  const { values } = useFormState({ subscription: { values: true } })
  const { change } = useForm()

  switch (identifier) {
    case ValidationKind.LENGTH:
      return (
        <Flex direction="column" css={{ marginLeft: 28 }} gap={16}>
          <FormField
            name={`${prefix}settings.minimum`}
            label="Minimum"
            component={TextInput}
            size="small"
            type="number"
            parse={(value: string) => value && parseInt(value, 10)}
          />
          <FormField
            name={`${prefix}settings.maximum`}
            label="Maximum"
            component={TextInput}
            size="small"
            type="number"
            parse={(value: string) => value && parseInt(value, 10)}
          />
          <FormField
            name={`${prefix}settings.equal_to`}
            label="Equal to"
            component={TextInput}
            size="small"
            type="number"
            parse={(value: string) => value && parseInt(value, 10)}
          />
          <FormField
            name={`${prefix}settings.not_equal_to`}
            label="Not Equal to"
            component={TextInput}
            size="small"
            type="number"
            parse={(value: string) => value && parseInt(value, 10)}
          />
        </Flex>
      )

    case ValidationKind.MATCHES:
    case ValidationKind.NOT_MATCHES:
      return (
        <Flex direction="column" css={{ marginLeft: 28 }} gap={16}>
          <FormField
            checkRequired
            name={`${prefix}settings.pattern`}
            label="Pattern"
            component={SelectInput}
            options={MatchPatternOptions}
            size="small"
          />
          <ConditionalField when={`${prefix}settings.pattern`} is={MatchPattern.CUSTOM}>
            <FormField
              name={`${prefix}settings.customPattern`}
              label="Custom"
              component={TextInput}
              size="small"
            />
          </ConditionalField>
          <ConditionalField when={`${prefix}settings.pattern`} is={MatchPattern.IPV4}>
            <FormField
              name={`${prefix}settings.ipPattern`}
              label="Range"
              component={TextInput}
              size="small"
            />
          </ConditionalField>
          <ConditionalField when={`${prefix}settings.pattern`} is={MatchPattern.IPV6}>
            <FormField
              name={`${prefix}settings.ipPattern`}
              label="Range"
              component={TextInput}
              size="small"
            />
          </ConditionalField>
        </Flex>
      )

    case ValidationKind.INCLUSION:
    case ValidationKind.EXCLUSION: {
      const matchValues = get(values, `${prefix}settings.values`, [])

      return (
        <Flex direction="column" css={{ marginLeft: 28 }} gap={16}>
          <FormField
            component="input"
            type="hidden"
            name={`${prefix}settings.values`}
            value={matchValues}
          />
          <FormField
            name={`${prefix}settings.matchString`}
            component={TextInput}
            size="small"
            onBlur={(
              e: React.FocusEvent<HTMLInputElement>
            ) => {
              if (e.target?.value) {
                if (matchValues.includes(e.currentTarget.value)) {
                  change(`${prefix}settings.matchString`, undefined)
                  return
                }
                const updatedValues = [ ...(matchValues || []), e.target.value ]

                change(`${prefix}settings.matchString`, undefined)
                change(`${prefix}settings.values`, updatedValues)
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' && e.currentTarget?.value) {
                e.preventDefault()
                if (matchValues?.includes(e.currentTarget.value)) {
                  change(`${prefix}settings.matchString`, undefined)
                  return
                }
                const updatedValues = [ ...(matchValues || []), e.currentTarget.value ]

                change(`${prefix}settings.matchString`, undefined)
                change(`${prefix}settings.values`, updatedValues)
              }
            }}
          />
          <Flex gap={2} wrap="wrap" style={{ rowGap: 2 }}>
            {matchValues?.map((value: string) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => {
                  const updatedValues = matchValues.filter((v: string) => v !== value)

                  change(`${prefix}settings.values`, updatedValues)
                }}
              />
            ))}
          </Flex>
        </Flex>
      )
    }

    default:
      return null
  }
}

const ActiveValidations = ({
  fieldsRef
}: {
  fieldsRef: React.MutableRefObject<FieldArrayChildrenProps<any> | undefined>
}) => {
  const { change } = useForm()
  const { values } = useFormState({ subscription: { values: true } })
  const { validations = [] } = values
  const droppableId = useRef(uuid())

  const handleDragEnd: OnDragEndResponder = ({ source, destination }) => {
    const updatedValidations = validations.slice()
    const [ removed ] = updatedValidations.splice(source.index, 1)
    updatedValidations.splice(destination?.index!, 0, removed)

    change('validations', updatedValidations)
  }

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const { kind, showCriteria } = validations[rubric.source.index]
    const validationType = ValidationTypes.find((v) => v.identifier === kind)

    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <ValidationCard key={kind} gap={16}>
          <Flex gap={16} alignItems="center">
            <Icon
              data-icon
              name="drag"
              size={12}
              {...provided.dragHandleProps}
            />
            <Flex alignContent="center" gap={16} justifyContent="space-between" grow={1}>
              <Text fontWeight="bold" fontSize={12}>{validationType?.label}</Text>
              <PopoverContainer placement="left-start">
                {({ openPopover, ...rest }) => (
                  <Icon
                    data-icon
                    name="vertical-ellipsis"
                    size={12}
                    onClick={openPopover}
                    style={{ cursor: 'pointer' }}
                    {...rest}
                  />
                )}
                {(popoverProps) => (
                  <Popover autoFocus {...popoverProps}>
                    <PopoverItem
                      size="small"
                      onClick={() => {
                        if (showCriteria) {
                          change(`validations[${rubric.source.index}].showCriteria`, undefined)
                          change(`validations[${rubric.source.index}].criteria`, null)
                        } else {
                          change(`validations[${rubric.source.index}].showCriteria`, true)
                          change(`validations[${rubric.source.index}].criteria`, {})
                        }
                      }}
                      text={`${showCriteria ? 'Remove' : 'Set'} Condition`}
                    />
                    <PopoverItem
                      size="small"
                      onClick={() => {
                        fieldsRef.current?.fields.remove(rubric.source.index)
                      }}
                      negative
                      text="Delete Rule"
                    />
                  </Popover>
                )}
              </PopoverContainer>
            </Flex>
          </Flex>
          <ValidationOptions identifier={kind as ValidationKind} prefix={`validations[${rubric.source.index}].`} />
          <ConditionalField when={`validations[${rubric.source.index}].showCriteria`} is>
            <JsonField
              label="Condition"
              name={`validations[${rubric.source.index}].criteria`}
              size="small"
            />
          </ConditionalField>
        </ValidationCard>
      </div>
    )
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        droppableId={droppableId.current}
        renderClone={renderDraggableChildren}
      >
        {(droppableProvided) => (
          <Flex
            direction="column"
            gap={6}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            <FieldArray name="validations" fieldsRef={fieldsRef}>
              {({ keys }) => (
                <>
                  {keys.map((key, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={key}
                      index={index}
                      key={key}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </>
              )}
            </FieldArray>
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const AttributeValidations = () => {
  const { values } = useFormState({ subscription: { values: true } })
  const {
    validations = [],
    fieldType = FieldIdentifier.TEXT
  } = values as FormValues
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()

  return (
    <Flex direction="column">
      <ActiveValidations fieldsRef={fieldsRef} />
      <Box css={{ height: validations.length ? 24 : 0 }} />
      <Flex direction="column" gap={6}>
        {ValidationTypes.map(({ label, identifier, fieldsMap, defaultSettings }) => {
          const showValidation = getShowValidation(fieldType as FieldIdentifier, fieldsMap)

          if (!showValidation) return null

          return (
            <ValidationCard
              as={Flex}
              key={identifier}
              direction="row"
              gap={16}
              alignItems="center"
              onClick={() => {
                fieldsRef.current?.fields.push({
                  kind: identifier,
                  settings: defaultSettings
                })
              }}
              height={48}
              variant="bordered"
            >
              <Icon data-icon name="add-thin" size={12} />
              <Text color="dark500" fontSize={12}>{label}</Text>
            </ValidationCard>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default AttributeValidations

export {
  formatValidations,
  MatchPattern,
  parseValidations
}
