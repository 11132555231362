import { object, string } from 'yup'

import dayjs from 'dayjs'

import BaseModel from 'models/BaseModel'
import { resolveCardImage } from 'lib/resolveImage'

enum Kind {
  CARD = 'Card',
  BANK_ACCOUNT = 'BankAccount'
}

enum Brands {
  UNKNOWN = 'unknown',
  AMERICAN_EXPRESS = 'amex',
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  VISA = 'visa'
}

const BRAND_COLORS: Record<Brands, string[]> = {
  [Brands.UNKNOWN]: [ '#308c67', '#a3f2cf' ],
  [Brands.AMERICAN_EXPRESS]: [ '#308c67', '#a3f2cf' ],
  [Brands.DINERS_CLUB]: [ '#000', '#949595' ],
  [Brands.DISCOVER]: [ '#30748b', '#c1f2ff' ],
  [Brands.JCB]: [ '#181730', '#347bbd' ],
  [Brands.MASTERCARD]: [ '#f37b26', '#fdb731' ],
  [Brands.VISA]: [ '#8d55ff', '#703eff' ]
}

class PaymentMethod extends BaseModel {
  static getFormattedNumber = (last4?: string) => {
    if (last4) {
      return `**** **** **** ${last4}`
    }

    return '**** **** **** ****'
  }

  static getFormattedName = (name?: string) => name || 'Your Name'

  static getFormattedExpiry = (expiryYear?: number, expiryMonth?: number) => {
    if (expiryYear && expiryMonth) {
      const expiryDate = new Date(expiryYear, expiryMonth - 1)
      return dayjs(expiryDate).format('MM / YY')
    }

    return '** / **'
  }

  static getLogo = (brand: Brands) => {
    if (!Object.values(Brands).includes(brand)) {
      throw new Error(`There is no logo for ${brand}`)
    }

    return resolveCardImage(`${brand}.png`).default
  }

  static getBrandColor = (brand: Brands) => {
    const brandColors = BRAND_COLORS[brand]

    if (!brandColors) {
      return {}
    }

    const [ firstColor, secondColor ] = brandColors

    return {
      backgroundImage: `linear-gradient(70deg, ${firstColor}, ${secondColor})`
    }
  }

  static getFormattedCVC = () => '###'

  static schema = object({
    name: string().required(),
    address: object({
      line1: string().required(),
      line2: string().required(),
      city: string().required(),
      state: string().required(),
      zip: string().required(),
      countryCode: string().required(),
      kind: string().required()
    }),
    taxRegistration: object({
      value: string(),
      kind: string()
    })
  })
}

export { Kind, Brands, BRAND_COLORS }
export default PaymentMethod
