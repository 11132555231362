import React, { createContext } from 'react'

import type { CustomContent, Field } from 'generated/schema'
import type { ContentVersion } from 'models/Content'
import type { HandlePublishingFn } from 'hooks/useVersionPublishing'
import type { Locale } from 'hooks/useActiveLocales'
import type { UsePagerResult } from 'hooks/usePager'

type ContentVersionContextValue = {
  activeLocales: Locale[],
  defaultLocale: string,
  content: Partial<CustomContent>,
  fieldsList: Field[],
  handlePublishing: HandlePublishingFn,
  isPublishingEnabled: boolean,
  currentVersion?: ContentVersion,
  previousVersion?: ContentVersion,
  setCurrentVersionId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setPreviousVersionId: React.Dispatch<React.SetStateAction<string | undefined>>,
  versions: ContentVersion[],
  versionsCount: number,
  versionsListPagerProps: UsePagerResult
}

type ContentVersionProviderProps = React.PropsWithChildren<ContentVersionContextValue>

const ContentVersionContext = createContext<ContentVersionContextValue>(
  {} as ContentVersionContextValue
)

function ContentVersionProvider({ children, ...rest }: ContentVersionProviderProps) {
  return (
    <ContentVersionContext.Provider value={rest}>
      {children}
    </ContentVersionContext.Provider>
  )
}

export {
  ContentVersionContext
}

export type {
  ContentVersionContextValue
}

export default ContentVersionProvider
