import React, { Fragment, memo, useCallback, useContext, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay, useDroppable } from '@dnd-kit/core'
import { NavLink } from 'react-router-dom'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { Scrollbars } from 'react-custom-scrollbars'
import { useRecoilState, useRecoilValue } from 'recoil'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { Dispatch, SetStateAction, UIEvent } from 'react'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import GenericMenuElement from 'components/menuelements/GenericMenuElement'
import Icon from 'components/icons/Icon'
import MenuElementPositionProvider from 'components/contexts/MenuElementPositionContext'
import rgba from 'lib/rgba'
import SidebarAddElement from 'components/sidebar/SidebarAddElement'
import SidebarFooter from 'components/sidebar/SidebarFooter'
import SidebarToggleButton from 'components/sidebar/SidebarToggleButton'
import Text from 'components/typography/Text'
import transitions from 'styles/primitives/transitions'
import useClientQuery from 'hooks/useClientQuery'
import useContainerScroll from 'hooks/useContainerScroll'
import useDashboard from 'hooks/useDashboard'
import withOnMount from 'hoc/withOnMount'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceLogo from 'components/logos/WorkspaceLogo'
import { APPS_MENU_ELEMENT_ID, ComputedMenuElement } from 'lib/generateDashboard'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { Popover, PopoverContainer, PopoverItem } from 'components/popover'
import { PREFERENCES_QUERY } from 'client/state/preferences'
import { SIDEBAR_BORDER_RADIUS, SIDEBAR_PRIMARY_BACKGROUND, SIDEBAR_PRIMARY_BORDER_COLOR_RGB, SIDEBAR_PRIMARY_HEADER_HEIGHT, SIDEBAR_PRIMARY_HEADER_PADDING_BOTTOM, SIDEBAR_PRIMARY_HEADER_PADDING_TOP, SIDEBAR_PRIMARY_WIDTH, SIDEBAR_PRIMARY_WIDTH_COLLAPSED } from 'components/sidebar/constants'
import { TourGuideContext } from 'components/providers/TourProvider'
import { Views } from 'components/dashboardEditor/constants'
import type { GenericMenuElementProps } from 'components/menuelements/GenericMenuElement'
import type { PreferencesQuery } from 'client/state/preferences'
import InternalContext from 'components/contexts/InternalContext'

type SidebarPrimaryProps = {
  hasSidebarSecondary: boolean,
  isPrimaryHovered: boolean,
  isSidebarLocked?: boolean,
  menuElements?: ComputedMenuElement[],
  setIsPrimaryHovered: Dispatch<SetStateAction<boolean>>,
  toggleIsAnimating: (duration: number, delay: number) => void
}

type DraggableMenuElementsProps = {
  menuElements: ComputedMenuElement[],
  onMenuElementClick: (menuElement: ComputedMenuElement, e?: React.MouseEvent<HTMLElement>) => void
}

const SIDEBAR_PRIMARY_HEADER_CENTER_POSITION = SIDEBAR_PRIMARY_HEADER_HEIGHT / 2
const SIDEBAR_PRIMARY_HEADER_LOGO_BORDER_RADIUS = 8
const SIDEBAR_PRIMARY_HEADER_LOGO_TRANSLATE_Y = `translateY(${SIDEBAR_PRIMARY_HEADER_CENTER_POSITION - SIDEBAR_PRIMARY_HEADER_PADDING_TOP}px) translateY(-50%)`
const SIDEBAR_PRIMARY_TRANSITION = 'simple'
const SIDEBAR_PRIMARY_TRANSITION_DELAY = 0.15

const StyledSidebarPrimary = styled(Flex, {
  ...mixins.transition(SIDEBAR_PRIMARY_TRANSITION),

  size: [ SIDEBAR_PRIMARY_WIDTH, '100%' ],
  background: SIDEBAR_PRIMARY_BACKGROUND,
  borderBottomRightRadius: SIDEBAR_BORDER_RADIUS,
  borderTopRightRadius: SIDEBAR_BORDER_RADIUS,
  position: 'relative',
  transitionProperty: 'transform, width',
  willChange: 'transform',
  zIndex: 'sidebar',

  '& [data-logo]': {
    ...mixins.transition('fastOut'),

    borderRadius: SIDEBAR_PRIMARY_HEADER_LOGO_BORDER_RADIUS
  },

  variants: {
    transitionDelayed: {
      true: {
        transitionDelay: `${SIDEBAR_PRIMARY_TRANSITION_DELAY}s`,

        '& [data-logo]': {
          transitionDelay: '0.3s'
        }
      }
    },
    collapsed: {
      true: {
        width: SIDEBAR_PRIMARY_WIDTH_COLLAPSED,

        '& [data-logo]': {
          ...mixins.transition('fastIn'),

          transform: SIDEBAR_PRIMARY_HEADER_LOGO_TRANSLATE_Y
        },

        '& [data-collapsible]': {
          ...mixins.transition('fastIn'),

          opacity: 0
        }
      }
    }
  }
})

const StyledSidebarHeader = styled(NavLink, {
  ...mixins.transition('simple'),

  alignItems: 'center',
  borderBottomColor: 'transparent',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  borderLeftWidth: 2,
  borderTopRightRadius: SIDEBAR_BORDER_RADIUS,
  display: 'flex',
  flexDirection: 'column',
  height: SIDEBAR_PRIMARY_HEADER_HEIGHT,
  justifyContent: 'center',
  paddingBottom: SIDEBAR_PRIMARY_HEADER_PADDING_BOTTOM,
  paddingTop: SIDEBAR_PRIMARY_HEADER_PADDING_TOP,
  textAlign: 'center',
  transitionProperty: 'transform',
  width: '100%',
  willChange: 'transform',

  '&:hover': {
    backgroundColor: rgba(colorVars.secondary400rgb, 0.3),
    cursor: 'pointer'
  },
  variants: {
    bodyScrolled: {
      true: {
        borderBottomColor: rgba(colorVars[SIDEBAR_PRIMARY_BORDER_COLOR_RGB], 0.1)
      }
    }
  }
})

const StyledTitleWrapper = styled('div', {
  ...mixins.transition('fastOut'),

  marginTop: 10,
  width: '100%',

  variants: {
    hasSidebarSecondary: {
      true: {},
      false: {}
    },
    collapsed: {
      true: {
        ...mixins.transition('fastIn'),

        opacity: 0,
        transform: SIDEBAR_PRIMARY_HEADER_LOGO_TRANSLATE_Y,
        transitionDelay: '0s'
      },
      false: {
        transitionDelay: '0.3s'
      }
    }
  }
})

StyledTitleWrapper.compoundVariant({
  hasSidebarSecondary: true, collapsed: true
},
{
  transitionDelay: `${SIDEBAR_PRIMARY_TRANSITION_DELAY}s`
})

const StyledTitle = styled(Text, {
  margin: 0,
  paddingX: 8,
  truncate: true
})

const classes = {
  sidebarHeader_active: css({
    backgroundColor: rgba(colorVars.secondary400rgb, 0.3),
    borderLeftColor: 'light100'
  }),
  placeholder: css({
    height: 'inherit',
    opacity: 0.2,

    paddingY: 10,
    paddingX: 15,
    position: 'relative',
    width: SIDEBAR_PRIMARY_WIDTH,
    zIndex: 'below',

    '&::before': {
      borderColor: 'light100',
      borderRadius: 4,
      borderStyle: 'dashed',
      borderWidth: 2,
      content: "''",
      height: 'calc(100% - 20px)',
      position: 'absolute',
      width: 'calc(100% - 30px)'
    }
  }),
  placeholder_collapsed: css({
    opacity: 0
  })
}

const GenericMenuElementWithOnMount = withOnMount<GenericMenuElementProps>(
  GenericMenuElement as (p: GenericMenuElementProps) => JSX.Element
)

const Dropzone = ({ id, index, length }: any) => {
  const { idToMenuElementMap } = useContext(InternalContext)!
  const { draggingOverMenuIdState } = useDashboard()
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggedElement = draggedMenu || draggedApp || draggedResource
  const draggingOverMenuElement = draggingOverMenuId && idToMenuElementMap[draggingOverMenuId]

  const isDraggingAccrossDroppables = draggedMenu?.parentId !== draggingOverMenuElement?.parentId
  || draggedMenu?.placement !== draggingOverMenuElement?.placement

  if (id === 'PREPEND_SIDEBAR_PRIMARY') {
    if (!draggedElement || ('id' in draggedElement && !isDraggingAccrossDroppables)) {
      return null
    }
  }

  const isAddingMenuElement = draggedMenu && !draggedMenu.id
  const isDraggingBeforeLastMenuElement = index < length - 1
  const isAddingApp = draggedApp?.id
  const isDraggingResource = draggedResource?.id

  if (!(
    (isAddingMenuElement && isDraggingBeforeLastMenuElement)
    || (draggedMenu?.id && isDraggingAccrossDroppables)
    || isAddingApp
    || isDraggingResource
  )) {
    return null
  }

  return (
    <Flex
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: draggingOverMenuId === id ? 1 : 0,
        zIndex: 1,

        '&:hover': {
          opacity: draggingOverMenuId ? undefined : 1
        }
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed light100',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const Overlay = () => {
  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  return createPortal(
    <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
      {draggedMenu?.id ? (
        <GenericMenuElement menuElement={draggedMenu} isDragging />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const DraggableMenuElements = memo(({
  menuElements,
  onMenuElementClick
}: DraggableMenuElementsProps) => {
  const { showNextStep } = useContext(TourGuideContext)!
  const {
    data: { preferences: { isSidebarMinimized } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)

  return (
    <SortableContext
      id="PRIMARY_SIDEBAR_SORTABLE"
      items={menuElements.map((menuElement) => menuElement.id)}
      strategy={verticalListSortingStrategy}
    >
      {menuElements.map((menuElement, index, { length }) => (
        <Fragment key={menuElement.id}>
          <GenericMenuElementWithOnMount
            key={menuElement.id}
            {...((!isSidebarMinimized && menuElement.id === APPS_MENU_ELEMENT_ID) && { className: 'tg--apps-menu', onMount: showNextStep })}
            menuElement={menuElement}
            onMenuElementClick={onMenuElementClick}
          />
          <Dropzone id={menuElement.id} index={index} length={length} />
        </Fragment>
      ))}
      <Overlay />
    </SortableContext>
  )
})

const SidebarHeaderMenuElement = (
  { scrollY, menuElement, isCollapsed, hasSidebarSecondary }: any
) => {
  const { currentWorkspace: { logoSymbol } } = useContext(WorkspaceContext)!
  const { openDashboardEditorView } = useDashboard()
  const { openDashboardEditor } = useContext(DashboardContext)!

  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  const { setNodeRef } = useSortable({
    id: 'PREPEND_SIDEBAR_PRIMARY',
    data: {
      placement: 'SIDE',
      parentId: null,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  return (
    <PopoverContainer openOn="contextMenu">
      {({ ref, isActive, closePopover, openPopover, ...contextMenuProps }) => (
        <div>
          <StyledSidebarHeader
            bodyScrolled={scrollY > 0}
            activeClassName={classes.sidebarHeader_active}
            exact
            to={menuElement.fullPath!}
            ref={setNodeRef}
            {...contextMenuProps}
          >
            <WorkspaceLogo data-logo logoSymbol={logoSymbol} mode="light" size="small" variant="symbol" />
            <StyledTitleWrapper
              collapsed={isCollapsed}
              hasSidebarSecondary={hasSidebarSecondary}
            >
              <StyledTitle
                color="light100"
                fontSize={24}
                fontWeight="semibold"
                letterSpacing="compact"
                lineHeight="cozy"
                title={menuElement.renderedName}
              >
                {menuElement.renderedName}
              </StyledTitle>
            </StyledTitleWrapper>
          </StyledSidebarHeader>
          <Dropzone id="PREPEND_SIDEBAR_PRIMARY" />
        </div>
      )}
      {(popoverProps) => (
        <Popover autoFocus {...popoverProps}>
          <PopoverItem
            size="small"
            onClick={() => {
              openDashboardEditorView({
                target: Views.ADD_MENU_ELEMENT,
                params: { initialValues: menuElement }
              })
              openDashboardEditor()
            }}
            text="Edit Menu Item"
          />
        </Popover>
      )}
    </PopoverContainer>
  )
}

function SidebarPrimary({
  hasSidebarSecondary,
  isPrimaryHovered,
  isSidebarLocked = false,
  menuElements = [],
  setIsPrimaryHovered,
  toggleIsAnimating,
  ...other
}: SidebarPrimaryProps) {
  const {
    data: { preferences: { isSidebarMinimized } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)
  const [ { scrollY }, updateScroll ] = useContainerScroll()

  const { dashboardEditorState } = useDashboard()
  const { target } = useRecoilValue(dashboardEditorState)
  const isInspecting = target === Views.EDIT_COMPONENT

  const isTransitionDelayed = !isSidebarMinimized || hasSidebarSecondary || isPrimaryHovered
  const isCollapsed = (isSidebarMinimized || hasSidebarSecondary) && !isPrimaryHovered

  const sidebarTransitionDuration = transitions[SIDEBAR_PRIMARY_TRANSITION].duration || 0
  const sidebarTransitionDelay = isTransitionDelayed ? SIDEBAR_PRIMARY_TRANSITION_DELAY : 0

  const toggleMinimize = () => {
    if (!isSidebarMinimized || hasSidebarSecondary) {
      setIsPrimaryHovered(false)
      toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
    }
  }

  const onAddMenuElement = useCallback(() => {
    setIsPrimaryHovered(false)
    toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
  }, [ setIsPrimaryHovered, toggleIsAnimating, sidebarTransitionDuration, sidebarTransitionDelay ])

  const handleScroll = useCallback((event: UIEvent<HTMLElement>) => updateScroll({
    scrollX: event?.currentTarget.scrollLeft,
    scrollY: event?.currentTarget.scrollTop
  }), [ updateScroll ])

  const [
    headerMenuElement,
    bodyMenuElements,
    footerMenuUnlockedElements,
    footerMenuLockedElements
  ] = useMemo(() => {
    let headerElement: ComputedMenuElement | undefined
    const bodyElements: ComputedMenuElement[] = []
    const footerUnlockedElements: ComputedMenuElement[] = []
    const footerLockedElements: ComputedMenuElement[] = []

    menuElements.forEach((menuElement) => {
      if (menuElement.position === 0 && menuElement.isRoot && !menuElement.isSticky) {
        headerElement = menuElement
      } else if (menuElement.isRoot && menuElement.isSticky) {
        footerLockedElements.push(menuElement)
      } else if (menuElement.isSticky) {
        footerUnlockedElements.push(menuElement)
      } else {
        bodyElements.push(menuElement)
      }
    })

    return [ headerElement, bodyElements, footerUnlockedElements, footerLockedElements ]
  }, [ menuElements ])

  const onMenuElementClick = useCallback((menuElement: ComputedMenuElement) => {
    if (menuElement.target === 'SUBMENU') {
      setIsPrimaryHovered(false)
      toggleIsAnimating(sidebarTransitionDuration, sidebarTransitionDelay)
    }
  }, [
    setIsPrimaryHovered,
    toggleIsAnimating,
    sidebarTransitionDuration,
    sidebarTransitionDelay
  ])

  const { setNodeRef } = useDroppable({
    id: 'SIDEBAR_PRIMARY',
    data: {
      placement: 'SIDE',
      parentId: null,
      isSticky: false
    }
  })

  return (
    <StyledSidebarPrimary
      {...other}
      collapsed={isCollapsed}
      direction="column"
      transitionDelayed={isTransitionDelayed}
    >
      {headerMenuElement && (
      <SidebarHeaderMenuElement
        hasSidebarSecondary={hasSidebarSecondary}
        isCollapsed={isCollapsed}
        menuElement={headerMenuElement}
        scrollY={scrollY}
      />
      )}

      <SidebarToggleButton
        isMinimized={isSidebarMinimized}
        isVisible={isPrimaryHovered}
        onToggleMinimize={toggleMinimize}
        variant="primary"
      />
      <MenuElementPositionProvider
        stickyElements={footerMenuUnlockedElements}
        nonStickyElements={bodyMenuElements}
      >
        <Scrollbars
          autoHide
          onScroll={handleScroll}
          renderTrackHorizontal={() => <div />}
          renderThumbHorizontal={() => <div />}
        >
          <div ref={setNodeRef} style={{ height: '100%' }}>
            <DraggableMenuElements
              menuElements={bodyMenuElements}
              onMenuElementClick={onMenuElementClick}
            />
            {!isSidebarLocked && !isInspecting && (
              <SidebarAddElement
                // 1 for excluding root element
                isVisible={menuElements.length === 1 ? true : isPrimaryHovered}
                onAddElement={onAddMenuElement}
                variant="primary"
              />
            )}
          </div>
        </Scrollbars>

        <div>
          <SidebarFooter>
            {(!!footerMenuUnlockedElements.length || !!footerMenuLockedElements.length)
                && (
                <Divider
                  css={{
                    '&::before': {
                      backgroundColor: rgba(colorVars[SIDEBAR_PRIMARY_BORDER_COLOR_RGB], 0.1)
                    }
                  }}
                />
                )}
            {!!footerMenuUnlockedElements.length
                && (
                  <DraggableMenuElements
                    menuElements={footerMenuUnlockedElements}
                    onMenuElementClick={onMenuElementClick}
                  />
                )}

            {footerMenuLockedElements.map((menuElement) => (
              <GenericMenuElement
                key={menuElement.id}
                menuElement={menuElement}
                onMenuElementClick={onMenuElementClick}
              />
            ))}
          </SidebarFooter>
        </div>

      </MenuElementPositionProvider>
    </StyledSidebarPrimary>
  )
}

DraggableMenuElements.displayName = 'DraggableMenuElements'

export default memo(SidebarPrimary)
