import React, { InputHTMLAttributes, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

const CHECKBOX_INACTIVE_COLOR = 'dark100'
const CHECKBOX_INACTIVE_TEXT_COLOR = 'dark500'
const CHECKBOX_ACTIVE_COLOR = 'dark900'
const CHECKBOX_HOVER_COLOR = 'dark300'
const CHECKBOX_SIZE = 16

const StyledCheckbox = styled(Flex, {
  ...mixins.transition('fastIn'),

  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: CHECKBOX_INACTIVE_COLOR,
  color: 'light100',
  size: [ CHECKBOX_SIZE ],
  background: 'light100',
  borderRadius: 2,

  [`& ${Icon}`]: {
    ...mixins.transition('fastIn'),

    opacity: 0,
    transform: 'scale(0.5)'
  },

  [`& + ${Text}`]: {
    ...mixins.transition('fastIn'),

    color: CHECKBOX_INACTIVE_TEXT_COLOR,
    userSelect: 'none'
  }

})

const StyledInput = styled('input', {
  hidden: true,

  [`&:focus + ${StyledCheckbox}`]: {
    ...mixins.transition('simple'),

    borderColor: CHECKBOX_HOVER_COLOR
  }

})

const highlightedStyle = {
  [`& ${StyledCheckbox}`]: {
    ...mixins.transition('simple'),

    borderColor: CHECKBOX_HOVER_COLOR
  }
}

const StyleCheckboxContainer = styled(Flex, {
  cursor: 'pointer',

  '&:hover': highlightedStyle,
  '&:focus': highlightedStyle,

  variants: {
    checked: {
      true: {
        [`& ${StyledCheckbox}`]: {
          borderColor: CHECKBOX_ACTIVE_COLOR,

          [`& ${Icon}`]: {
            color: CHECKBOX_ACTIVE_COLOR,
            opacity: 1,
            transform: 'scale(1)'
          },

          [`& + ${Text}`]: {
            color: CHECKBOX_ACTIVE_COLOR
          }
        },

        [`& ${StyledInput}`]: {
          [`&:focus + ${StyledCheckbox}`]: {
            borderColor: CHECKBOX_ACTIVE_COLOR
          }
        },

        [`&:hover ${StyledCheckbox}`]: {
          borderColor: CHECKBOX_ACTIVE_COLOR
        }
      }
    },

    disabled: {
      true: {
        cursor: 'auto',

        [`& ${StyledCheckbox}`]: {
          opacity: 0.3
        },

        [`&:hover ${StyledCheckbox}`]: {
          borderColor: CHECKBOX_INACTIVE_COLOR
        }
      }
    }

  }
})

type CheckboxInputProps = {
  label?: string | ReactNode,
  checked?: boolean,
  isTranslatable?: boolean,
  // input prop is optional to support usage outside forms using checked prop
  input?: Omit<InputHTMLAttributes<HTMLInputElement>, 'css'>
}

function CheckboxInput({ checked, input, isTranslatable, label }: CheckboxInputProps) {
  return (
    <StyleCheckboxContainer
      alignItems="center"
      as="label"
      checked={checked || input?.checked}
      disabled={input?.disabled}
      display="inline-flex"
      gap={10}
    >
      <StyledInput
        type="checkbox"
        name="checkbox"
        disabled={input?.disabled}
        {...input}
      />
      <StyledCheckbox alignItems="center" justifyContent="center">
        <Icon name="check" size={10} />
      </StyledCheckbox>
      {label && (
        <>
          {isTranslatable && <Icon size={12} name="translate" />}
          <Text data-label fontSize={12} fontWeight="bold">
            {label}
          </Text>
        </>
      )}
    </StyleCheckboxContainer>
  )
}

export default CheckboxInput

export type { CheckboxInputProps }
