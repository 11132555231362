import React from 'react'
import { Field, Form } from 'react-final-form'
import type { FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Dashboard from 'models/Dashboard'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'

function DashboardForm(props: FormProps<any>) {
  return (
    <Form
      // decorators={[
      //   setIdentifierDecorator
      // ]}
      subscription={{
        submitting: true,
        pristine: true
      }}
      validate={(values) => Dashboard.validate(values, [ 'name' ])}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Flex gap={16} direction="column">
            <Field autoFocus name="name" label="Name" component={TextInput} type="text" />
            {/* <Field name="identifier" label="Identifier" component={TextInput} type="text" /> */}

            <Flex gap={24}>
              <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
            </Flex>
          </Flex>
        </form>
      )}
      {...props}
    />
  )
}

export default DashboardForm
