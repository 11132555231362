import arrayMutators from 'final-form-arrays'
import omit from 'lodash/omit'
import React from 'react'
import uuid from 'uuid-random'
import { Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import CreateAttributeView from './CreateAttributeView'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import Parameter from 'models/Parameter'
import SectionLoader from 'components/loaders/SectionLoader'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { DisplayType } from 'models/Attribute'
import { formatDateTimeValues } from 'components/displayTypes/DateTimeView'
import { formatValidations } from './AttributeValidations'
import { SidePaneFooter } from 'components/sidePane'
import { useDataTypeQuery, ValidationInput } from 'generated/schema'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ViewParams, Views } from '../constants'

type FormValues = any

type Params = ViewParams[Views.CREATE_DATA_TYPE_FIELD]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const decorators = [
  setIdentifier
]

const CreateDataTypeFieldView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const {
    params = {} as Params
  } = useRecoilValue<DashboardEditorView<Views.CREATE_DATA_TYPE_FIELD>>(
    dashboardEditorState
  )

  const {
    initialValues = {},
    parentDataType: dataType,
    currentIndex
  } = params || {} as Params

  const fields = dataType?.settings?.fields || []

  const isUpdating = 'currentIndex' in (params || {})
  let formattedValidations = fields?.[currentIndex!]?.validations || []

  let formattedInitialValues = { ...initialValues, ...fields[currentIndex!] || {} } as FormValues
  if (fields?.displayType && fields.displayType === DisplayType.DATE_TIME) {
    formattedInitialValues = formatDateTimeValues(fields as any) as FormValues
  }

  if (fields?.validations?.length) {
    formattedValidations = formatValidations(fields?.validations as ValidationInput[])
  }

  const initialDataTypeId = formattedInitialValues?.dataTypeId

  // query dataType object if not present in initial values
  const { data, loading } = useDataTypeQuery({
    variables: {
      id: initialDataTypeId
    },
    skip: formattedInitialValues.dataType || !initialDataTypeId
  })

  formattedInitialValues = {
    dataType: formattedInitialValues.dataType || data?.dataType,
    ...formattedInitialValues
  }

  const handleSubmit = (values: FormValues) => {
    const cleanValues = {
      id: uuid(),
      ...omit(values, 'mapping', 'dataType', 'dataTypeKind', 'enumId', 'objectId')
    }

    let updatedFields

    if (isUpdating) {
      updatedFields = dataType?.settings.fields.map(
        (p: any, index: number) => {
          if (index === currentIndex) {
            return { ...p, ...cleanValues }
          }
          return p
        }
      ) || []
    } else {
      updatedFields = [ ...(dataType?.settings?.fields || []), cleanValues ]
    }

    stepBackDashboardEditor(1, {
      initialValues: {
        ...(params.parentDataType || {}),
        settings: {
          ...(params.parentDataType || {})?.settings,
          fields: updatedFields
        }
      }
    })
  }

  return (
    <>
      <DashboardEditorHeader
        heading={`Data Type: ${dataType?.name || 'New'}`}
        subtitle={`${isUpdating ? 'Update' : 'Add'} Field`}
        onClose={onClose}
      />
      <SectionLoader data={formattedInitialValues.dataType} loading={loading}>
        <Form
          decorators={decorators}
          mutators={{
            ...arrayMutators
          }}
          initialValues={{
            defaultValue: '',
            isTranslatable: false,
            // data type fields are JSON arrays and are positioned according to array index
            position: currentIndex ?? -1,
            ...formattedInitialValues as FormValues,
            dataTypeSettings: formattedInitialValues?.dataTypeSettings || {},
            fieldTypeSettings: formattedInitialValues?.fieldTypeSettings || {},
            displayTypeSettings: formattedInitialValues?.displayTypeSettings || {},
            validations: formattedValidations,
            // @ts-ignore - required for UI but gets omitted out while submission
            // eslint-disable-next-line max-len
            dataTypeKind: formattedInitialValues?.dataTypeKind || formattedInitialValues?.dataType?.kind
          }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          subscription={{
            submitting: true
          }}
          validate={(values) => Parameter.validate(values, [ 'identifier', 'name' ])}
          render={({ handleSubmit, submitting }) => (
            <>
              <DashboardEditorBody>
                <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                  <CreateAttributeView.FinalStep isUpdating={isUpdating} />
                  <input type="submit" style={{ display: 'none' }} />
                </Flex>
              </DashboardEditorBody>
              <SidePaneFooter variant="small" isSticky>
                <Flex gap={16} direction="row-reverse">
                  <Button size="small" type="submit" label="Submit" disabled={submitting} onClick={handleSubmit} />
                </Flex>
              </SidePaneFooter>
            </>
          )}
        />
      </SectionLoader>
    </>
  )
}

export default CreateDataTypeFieldView
