import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import type { FieldRenderProps } from 'react-final-form'
import type { FormEvent } from 'react'

import * as mixins from 'styles/mixins'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import ImageUploadModal from 'components/modals/ImageUploadModal'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import Loader from 'components/loaders/Loader'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { MEDIA_TYPE_OPTIONS, MEDIA_TYPE_TO_MIME_MAPPING } from 'components/contentEditors/generic/fields/fieldProps'
import { styled } from 'styles/stitches'
import type { settings } from 'components/contentEditors/generic/fields/fieldProps'

import soundWave from 'assets/images/sound-wave-colored.svg'

type MediaInputProps = FieldRenderProps<File | string | Record<string, any>> & {
  handlerRef?: Ref<any>,
  height: number,
  helpText?: string,
  isTranslatable?: boolean,
  isUploading?: boolean,
  label?: string,
  accept?: string[],
  onDelete: (index: any) => void,
  onSelect: (media: Blob, mediaName: string, index: number) => void,
  previewFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down',
  previewHeight?: number,
  previewWidth?: number,
  title?: string,
  width: number,
  showLegacyModal?: boolean,
  includeExtensions?: string[],
  fileTypeCategory?: settings['media']['fileTypeCategory'],
  mediaNotAvailable?: boolean,
  setToUndefinedOnDelete: boolean,
  standalone?: boolean
}

type ImageHandlerAction = {
  openImageUploadModal: () => void,
  handleDelete: (e: FormEvent) => void
}

const StyledFileInput = styled(Flex, {
  ...mixins.transition('simple'),

  background: 'dark100',
  borderRadius: 6,
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',

  '&:hover': {
    ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.2)
  },

  '[data-preview]': {
    size: [ '100%' ],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > img': {
      size: [ '100%' ],
      overflow: 'hidden'
    }
  },
  variants: {
    error: {
      true: {
        background: 'negative100'
      }
    }
  }
})

const StyledContainer = styled(Flex, {
  position: 'relative'
})

const StyledPlaceholder = styled(Icon, {
  color: 'dark200',

  variants: {
    error: {
      true: {
        color: 'negative400'
      }
    }
  }
})

const StyledLoader = styled(Flex, {
  borderRadius: 6,
  height: '100%',
  position: 'absolute',
  width: '100%'
})

const ActionsContainer = styled(Flex, {
  position: 'absolute',
  right: 6,
  bottom: 6,
  zIndex: 'above'
})

const StyledAction = styled(IconButton, {
  ...mixins.dropShadow('icon', colorVars.dark500rgb),
  variants: {
    error: {
      true: {
        [`& ${Icon}`]: { color: 'negative600' }
      }
    }
  }
})

const StyledFileName = styled(Text, {
  width: '90%',
  wordBreak: 'break-all'
})

const AudioPreview = styled('div', {
  size: [ '100%' ],
  backgroundColor: '#3a4165',
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '25%',
    bottom: '25%',
    backgroundImage: `url(${soundWave})`,
    backgroundSize: 'cover'
  }
})

function MediaInput({
  autoFocus,
  fileTypeCategory = MEDIA_TYPE_OPTIONS.CUSTOM,
  handlerRef,
  height,
  helpText,
  accept,
  showLegacyModal = false,
  includeExtensions,
  index,
  input: { name, onChange, value },
  setToUndefinedOnDelete = false,
  isLoading,
  isTranslatable,
  checkRequired = false,
  isUploading,
  label,
  meta,
  onDelete,
  onSelect,
  previewFit,
  previewHeight,
  previewWidth,
  title,
  width,
  mediaNotAvailable,
  onClick,
  standalone = false
}: MediaInputProps) {
  const [ imageUploadModalOpened, setImageUploadModalOpened ] = useState(false)

  const [ fileRejectionError, setFileRejectionError ] = useState<string | null>(null)

  const openImageUploadModal = useCallback(() => setImageUploadModalOpened(true),
    [ setImageUploadModalOpened ])
  const closeImageUploadModal = () => setImageUploadModalOpened(false)

  const loading = isLoading || isUploading

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const [ acceptedFile ] = acceptedFiles

    if (acceptedFiles.length > 0) {
      if (onSelect) {
        onSelect(acceptedFile, acceptedFile.name, index)
      } else {
        onChange(acceptedFile)
      }

      openImageUploadModal()
      setFileRejectionError(null)
    } else {
      setFileRejectionError(fileRejections[0].errors[0].message)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept || (MEDIA_TYPE_TO_MIME_MAPPING[fileTypeCategory] ?? includeExtensions),
    noClick: !!value && !standalone,
    multiple: false
  })

  const handleDelete = useCallback((e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    onChange(setToUndefinedOnDelete ? undefined : null)

    onDelete?.(index)
  }, [ setToUndefinedOnDelete, onDelete, onChange, index ])

  const onSubmit = (media: Blob, mediaName: string) => {
    if (onSelect) {
      onSelect(media, mediaName, index)
    } else {
      onChange(media)
    }
  }

  const mediaSrc = useMemo(() => {
    try {
      const currentValue = (Array.isArray(value) ? value[0] : value)
      return (typeof currentValue === 'string' ? currentValue : URL.createObjectURL(currentValue))
    } catch (e) {
      return ''
    }
  }, [ value ])

  useEffect(() => () => {
    if (mediaSrc) {
      try {
        URL.revokeObjectURL(mediaSrc)
      // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [ mediaSrc ])

  useImperativeHandle(handlerRef, () => ({
    openImageUploadModal,
    handleDelete
  }), [ handleDelete, openImageUploadModal ])

  useEffect(() => {
    if (!mediaSrc) return () => null

    return () => URL.revokeObjectURL(mediaSrc)
  }, [ mediaSrc ])

  const { css, ...rootProps } = getRootProps()

  let placeHolderIcon = 'upload-placeholder'

  switch (fileTypeCategory) {
    case MEDIA_TYPE_OPTIONS.IMAGE:
      placeHolderIcon = mediaNotAvailable ? 'image-broken' : 'upload-placeholder'
      break
    case MEDIA_TYPE_OPTIONS.VIDEO:
      placeHolderIcon = mediaNotAvailable ? 'video-broken' : 'video'
      break
    case MEDIA_TYPE_OPTIONS.AUDIO:
      placeHolderIcon = mediaNotAvailable ? 'audio-broken' : 'sound'
      break
    case MEDIA_TYPE_OPTIONS.DOCUMENT:
    case MEDIA_TYPE_OPTIONS.PRESENTATION:
    case MEDIA_TYPE_OPTIONS.SPREADSHEET:
      placeHolderIcon = mediaNotAvailable ? 'document-broken' : 'page-stroked'
      break
    default:
      placeHolderIcon = mediaNotAvailable ? 'image-broken' : 'upload-placeholder'
  }

  return (
    <>
      <StyledContainer as="label" direction="column" gap={10}>
        {label && (
          <FieldLabel isTranslatable={isTranslatable}>
            {label}{checkRequired && <span> *</span>}
          </FieldLabel>
        )}
        <StyledFileInput
          {...rootProps}
          alignItems="center"
          as="button"
          autoFocus={autoFocus}
          justifyContent="center"
          name={name}
          {...(!standalone && { onClick: () => {
            openImageUploadModal()
            onClick?.()
          } })}
          style={{
            height: previewHeight || height,
            width: previewWidth || width
          }}
          type="button"
          error={mediaNotAvailable}
        >
          <input {...getInputProps()} />
          {loading && (
            <StyledLoader alignItems="center">
              <Loader loading />
            </StyledLoader>
          )}
          {!mediaSrc || !value ? (
            <>
              {(value as Record<string, any>)?.fileName
                ? <StyledFileName fontWeight="semibold">{(value as Record<string, any>).fileName}</StyledFileName>
                : (
                  <StyledPlaceholder
                    name={placeHolderIcon}
                    error={mediaNotAvailable}
                    size={32}
                  />
                )}
              {!loading && (
                <ActionsContainer>
                  {Boolean(index) && (
                    <StyledAction
                      description="Delete"
                      variant="light"
                      name="trash"
                      onClick={handleDelete}
                      size={16}
                    />
                  )}
                  <StyledAction description="Upload" variant="light" name="upload" size={16} />
                </ActionsContainer>
              )}
            </>
          ) : (
            <div data-preview>
              <div
                style={{
                  ...mixins.size('100%'),
                  alignItems: 'center',
                  backgroundColor: mediaSrc ? '#3a4165' : 'inherit',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}
              >
                {(fileTypeCategory === MEDIA_TYPE_OPTIONS.IMAGE
                  || fileTypeCategory === MEDIA_TYPE_OPTIONS.VIDEO) && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    poster={mediaSrc}
                    style={{
                      ...(!showLegacyModal ? mixins.size('80%') : mixins.size('100%')),
                      objectFit: previewFit || 'cover',
                      position: 'relative',
                      zIndex: 1
                    }}
                  />

                )}

                <StyledPlaceholder
                  error={mediaNotAvailable}
                  name={placeHolderIcon}
                  size={32}
                  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 0 }}
                />

              </div>
              {fileTypeCategory === MEDIA_TYPE_OPTIONS.AUDIO && (
                <AudioPreview />
              )}

              <ActionsContainer>
                <StyledAction description="Delete" variant="light" name="trash" onClick={handleDelete} size={16} />
              </ActionsContainer>
            </div>
          )}
        </StyledFileInput>

        {helpText && <InputHelpText helpText={helpText} />}
        <FieldError
          css={{
            bottom: 0,
            left: 0,
            right: 0,
            top: 'auto'
          }}
          error={FieldError.getError(meta) || fileRejectionError}
        />

      </StyledContainer>

      {showLegacyModal && fileTypeCategory === MEDIA_TYPE_OPTIONS.IMAGE && (
        <ImageUploadModal
          title={title || (label && `Change ${label}`) || ''}
          imageWidth={width}
          imageHeight={height}
          defaultImage={mediaSrc}
          isOpen={imageUploadModalOpened}
          onSubmit={onSubmit}
          onClose={closeImageUploadModal}
        />
      )}
    </>
  )
}

export type {
  ImageHandlerAction,
  MediaInputProps
}

export default MediaInput
