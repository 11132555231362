import omit from 'lodash/omit'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { useMutation } from '@apollo/client'

import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import DataListBlock from 'components/blocks/DataListBlock'
import DateRenderer from 'components/renderers/DateRenderer'
import Divider from 'components/divider/Divider'
import DrawerBlock from 'components/blocks/DrawerBlock'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import Flex from 'components/layout/Flex'
import GlobalContext from 'components/contexts/GlobalContext'
import HintBox from 'components/hints/HintBox'
import parseError from 'lib/parseError'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextField from 'components/contentEditors/generic/fields/TextField'
import TextLink from 'components/links/TextLink'
import TitleBlock from 'components/blocks/TitleBlock'
import useClientQuery from 'hooks/useClientQuery'
import usePager from 'hooks/usePager'
import { DATE_FORMATS, SAVE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'
import { DeveloperInput, DeveloperQuery, DEVELOPER_QUERY, SAVE_DEVELOPER_MUTATION } from 'client/state/developer'
import { Kind } from 'models/Account'
import { QuickLinks } from 'components/blocks/QuickLinksBlock'
import { useGenerateIdentityTokenLazyQuery, useParseIdentityTokenLazyQuery, useSearchOperationLogsQuery, useSummarizeOperationLogsQuery, useValidateKeyPairLazyQuery, useValidateKeyPairQuery } from 'generated/schema'
import type { Content } from 'components/dataList/DataList'
import type { DrawerBlockRenderProps } from 'components/blocks/DrawerBlock'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { GenerateIdentityTokenInput, ParseIdentityTokenInput, ValidateKeyPairInput } from 'generated/schema'

const HELP_SECTION_CARDS = [
  {
    title: 'Helpful Links',
    icon: 'support',
    links: [
      {
        label: 'Product Guide',
        url: 'https://docs.dashx.com'
      },
      {
        label: 'Demo Applications',
        url: 'https://dashxdemo.com/'
      }
    ]
  },
  {
    title: 'Frontend SDKs',
    icon: 'js-console',
    links: [
      {
        label: 'JavaScript',
        url: 'https://github.com/dashxhq/dashx-browser'
      },
      {
        label: 'Native iOS',
        url: 'https://github.com/dashxhq/dashx-ios'
      },
      {
        label: 'Native Android',
        url: 'https://github.com/dashxhq/dashx-android'
      },
      {
        label: 'React Native',
        url: 'https://github.com/dashxhq/dashx-react-native'
      },
      {
        label: 'Flutter',
        url: 'https://github.com/dashxhq/dashx-flutter'
      }
    ]
  },
  {
    title: 'Backend SDKs',
    icon: 'wrench-tool',
    links: [
      {
        label: 'Node.js',
        url: 'https://github.com/dashxhq/dashx-node'
      },
      {
        label: 'Python',
        url: 'https://github.com/dashxhq/dashx-python'
      },
      {
        label: 'Ruby',
        url: 'https://github.com/dashxhq/dashx-ruby'
      },
      {
        label: 'PHP',
        url: 'https://github.com/dashxh/dashx-php'
      },
      {
        label: 'Golang',
        url: 'https://github.com/dashxhq/dashx-go'
      }
    ]
  }
]

const LogsView = () => {
  const [ _, { privateKey, publicKey } ] = useKeys()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 20
  })
  const [ filters, setFilters ] = useState<FilterType>({})

  const { data, loading } = useSearchOperationLogsQuery({
    variables: {
      input: {
        privateKey,
        publicKey,
        page,
        limit: pageSize,
        order: [ {
          createdAt: 'desc'
        } ],
        filter: {
          ...filters
        }
      }
    }
  })

  const { data: summarizeData } = useSummarizeOperationLogsQuery({
    variables: {
      input: {
        privateKey,
        publicKey,
        filter: {
          ...filters
        }
      }
    }
  })

  const contents: Content[] = [
    { dataKey: 'operation.name', slot: 'primary', flexGrow: 1, fieldType: 'text-field', title: 'Name' },
    {
      dataKey: 'createdAt',
      slot: 'meta',
      renderer: (props) => <DateRenderer {...props} full />,
      fieldType: 'date-time-field',
      settings: { saveFormat: SAVE_FORMATS.TIMESTAMP, format: DATE_FORMATS.DD_MM_YYYY_HH_mm },
      title: 'Created At'
    }
  ]

  return (
    <DataListBlock
      asFragment
      actions={[]}
      data={data?.searchOperationLogs || []}
      loading={loading}
      contents={contents}
      paginationMode="finite"
      page={page}
      pageSize={pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      totalRows={summarizeData?.summarizeOperationLogs.count || 0}
      selectionMode="none"
      filters={filters}
      setFilters={setFilters}
    />
  )
}

const UtilitiesView = ({ privateKey, publicKey }: UtilitiesViewProps) => {
  const { openFailureAlert } = useContext(GlobalContext)!
  const [ generateIdentityToken, { data, loading, error } ] = useGenerateIdentityTokenLazyQuery()
  const [
    parseIdentityToken,
    { data: parsedData, loading: parsing, error: parsingError }
  ] = useParseIdentityTokenLazyQuery()

  useEffect(() => {
    if (error) {
      const { alert } = parseError(error)

      if (alert) {
        openFailureAlert(alert)
      }
    }
  }, [ error, openFailureAlert ])

  useEffect(() => {
    if (parsingError) {
      const { alert } = parseError(parsingError)

      if (alert) {
        openFailureAlert(alert)
      }
    }
  }, [ parsingError, openFailureAlert ])

  return (
    <Flex direction="column" gap={32}>
      <DrawerBlock as={Flex} title="Generate Identity Token">
        {() => (
          <Form
            initialValues={{
              privateKey,
              publicKey,
              kind: Kind.USER,
              uid: ''
            }}
            onSubmit={(input: GenerateIdentityTokenInput) => {
              generateIdentityToken({ variables: { input } })
            }}
            render={({ handleSubmit }) => (
              <Flex as="form" direction="column" gap={16} alignItems="flex-start" onSubmit={handleSubmit}>
                <Field name="privateKey" component="input" type="hidden" />
                <Field name="publicKey" component="input" type="hidden" />
                <Flex alignSelf="stretch" gap={16}>
                  <DropdownField
                    name="kind"
                    label="Kind"
                    options={[ { label: 'User', value: Kind.USER }, { label: 'Visitor', value: Kind.VISITOR } ]}
                    shouldValidate
                    settings={{ checkRequired: true }}
                  />
                  <TextField name="uid" label="Uid" shouldValidate settings={{ checkRequired: true }} />
                </Flex>
                <Button label="Generate" type="submit" disabled={loading} />
                {data?.generateIdentityToken
                  && (
                  <>
                    <Divider />
                    <CodeEditorInput
                      disabled
                      size="auto"
                      meta={{}}
                      input={{
                        name: 'result',
                        value: JSON.stringify(omit(data.generateIdentityToken, '__typename') || {}, null, 2),
                        onChange: () => {},
                        onBlur: () => {},
                        onFocus: () => {}
                      }}
                    />
                  </>
                  )}
              </Flex>
            )}
          />
        )}
      </DrawerBlock>
      <DrawerBlock as={Flex} title="Parse Identity Token">
        {() => (
          <Form
            initialValues={{
              privateKey,
              publicKey,
              token: ''
            }}
            onSubmit={(input: ParseIdentityTokenInput) => {
              parseIdentityToken({ variables: { input } })
            }}
            render={({ handleSubmit }) => (
              <Flex as="form" direction="column" gap={16} alignItems="flex-start" onSubmit={handleSubmit}>
                <Flex alignSelf="stretch" gap={16}>
                  <TextField name="token" label="Token" shouldValidate settings={{ checkRequired: true }} />
                </Flex>
                <Button label="Parse" type="submit" disabled={parsing} />
                {parsedData?.parseIdentityToken
                  && (
                  <>
                    <Divider />
                    <CodeEditorInput
                      disabled
                      size="auto"
                      meta={{}}
                      input={{
                        name: 'result',
                        value: JSON.stringify(omit(parsedData.parseIdentityToken, '__typename') || {}, null, 2),
                        onChange: () => {},
                        onBlur: () => {},
                        onFocus: () => {}
                      }}
                    />
                  </>
                  )}
              </Flex>
            )}
          />
        )}
      </DrawerBlock>
    </Flex>
  )
}

type UtilitiesViewProps = {
  privateKey: string,
  publicKey: string
}

const useKeys = () => {
  const {
    data: { developer: { privateKey, publicKey } }
  } = useClientQuery<DeveloperQuery>(DEVELOPER_QUERY)

  const [ setKeys ] = useMutation<DeveloperInput>(SAVE_DEVELOPER_MUTATION)

  const { data: cachedData } = useValidateKeyPairQuery({
    fetchPolicy: 'cache-only',
    variables: {
      input: {
        privateKey,
        publicKey
      }
    },
    skip: !privateKey || !publicKey
  })

  const { openFailureAlert } = useContext(GlobalContext)!

  const [
    doValidateKeyPair,
    { data = cachedData, loading, error, variables }
  ] = useValidateKeyPairLazyQuery()

  useEffect(() => {
    if (variables?.input.privateKey && variables?.input.publicKey) {
      setKeys({
        variables: {
          developer: {
            privateKey: variables.input.privateKey,
            publicKey: variables.input.publicKey
          }
        }
      })
    }
  }, [ data, variables, setKeys ])

  useEffect(() => {
    if (error) {
      const { alert } = parseError(error)

      if (alert) {
        openFailureAlert(alert)
      }

      setKeys({
        variables: {
          developer: {
            privateKey: '',
            publicKey: ''
          }
        }
      })
    }
  }, [ error, openFailureAlert, setKeys ])

  return [ doValidateKeyPair, { privateKey, publicKey, data, loading, error, variables } ] as const
}

function DeveloperPage() {
  const drawerHandlerRef = useRef<DrawerBlockRenderProps>()

  const [ doValidateKeyPair, { privateKey, publicKey, data, loading } ] = useKeys()

  useEffect(() => {
    if (data?.validateKeyPair) {
      drawerHandlerRef.current?.closeDrawer()
    }
  }, [ data ])

  const isValidated = data?.validateKeyPair.success

  return (
    <>
      <TitleBlock heading="Developer" />
      <Block gap={32} direction="column" width={{ md: '75%' }}>
        <HintBox headline="Advanced Feature">
          This feature is meant for Developers integrating with our API &amp; SDKs.
          {' '}
          <TextLink href="https://docs.dashx.com/">Learn more</TextLink>
        </HintBox>
      </Block>
      <Block gap={32} direction="column" width={{ md: '75%' }}>
        <DrawerBlock
          handlerRef={drawerHandlerRef}
          defaultOpened={!isValidated}
          as={Flex}
          title="Authentication"
          summary={isValidated ? 'Validated' : ''}
        >
          {() => (
            <Flex gap={16} direction="column">
              <Text color="dark500" fontSize={14}>To begin, <TextLink href="https://docs.dashx.com/developer/quickstart/prerequisites">obtain your API keys</TextLink> and enter them below</Text>
              <Form
                initialValues={{ privateKey, publicKey }}
                onSubmit={(input: ValidateKeyPairInput) => {
                  doValidateKeyPair({
                    variables: {
                      input
                    }
                  })
                }}
                render={({ handleSubmit }) => (
                  <Flex as="form" direction="column" gap={16} alignItems="flex-start" onSubmit={handleSubmit}>
                    <Flex alignSelf="stretch" gap={16}>
                      <TextField name="publicKey" label="Public Key" shouldValidate settings={{ checkRequired: true }} />
                      <TextField name="privateKey" label="Private Key" shouldValidate settings={{ checkRequired: true }} />
                    </Flex>
                    <Button label="Validate" type="submit" disabled={loading} />
                  </Flex>
                )}
              />
            </Flex>
          )}
        </DrawerBlock>
        {isValidated
          ? (
            <Tabs>
              <Tab index={0} label="Logs">
                <LogsView />
              </Tab>
              {/* <Tab index={1} label="Test">
                <div />
              </Tab> */}
              <Tab index={1} label="Utilities">
                <UtilitiesView privateKey={privateKey} publicKey={publicKey} />
              </Tab>
            </Tabs>
          ) : (
            <HintBox>
              You must enter valid API keys first.
            </HintBox>
          )}
      </Block>
      <Block gap={32} direction="column" width={{ md: '25%' }}>
        {HELP_SECTION_CARDS.map((section) => (
          <QuickLinks
            key={section.title}
            title={section.title}
            icon={section.icon}
            links={section.links}
            width={{ md: '25%' }}
          />
        ))}
      </Block>
    </>
  )
}

export default DeveloperPage
