import { useContext } from 'react'

import orderBy from 'lodash/orderBy'

import { APP_IDS } from 'models/App'
import InternalContext from 'components/contexts/InternalContext'
import type { UrnObject } from './parseViewUrn'
import DashboardContext from 'components/contexts/DashboardContext'

const useJumpToView = ({ type, id, componentPath, viewId }: UrnObject) => {
  const {
    selectedSideMenuElement,
    selectedTopMenuElement
  } = useContext(DashboardContext)!

  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement

  const { menuElements = [] } = useContext(InternalContext) || {}
  let urn = ''

  if (type === 'app' && id) {
    const appId: string = APP_IDS[id as keyof typeof APP_IDS]
    urn = `${type}::${appId}::${componentPath}`
  }

  if (type === 'resource') {
    urn = `${type}::${id}::${componentPath}`
  }

  if (type === 'custom') {
    urn = `${type}::${viewId}`
  }

  const currentMenuElementParentId = selectedMenuElement?.parentId

  // first try to find the one with the same parentId
  const closestMenuElement = orderBy(
    menuElements.filter((menuElement) => menuElement.viewUrn === urn),
    (menuElement) => menuElement?.parentId !== currentMenuElementParentId
  )[0]

  return closestMenuElement
}

export default useJumpToView
