/* eslint-disable no-case-declarations */
import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import * as schema from 'generated/schema'
import Button from 'components/buttons/Button'
import DataListBlock from 'components/blocks/DataListBlock'
import Flex from 'components/layout/Flex'
import GenericView from 'components/views/GenericView'
import JSONParseOr from 'lib/JSONParseOr'
import IconButton from 'components/buttons/IconButton'
import SearchBar from 'components/searchbar/SearchBar'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './useExecuteOperationQuery'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { FieldIdentifier } from 'models/Field'
import { parseAndRenderSync } from 'lib/templater'
import type { BlockProps } from 'components/blocks/Block'
import type { Content } from 'components/dataList/DataList'
import type { DataTableBlockProps } from 'components/blocks/DataTableBlock'
import type { Operation } from 'hooks/useDashboard'
import type { RowSelectionFn } from 'components/providers/DataManagerProvider'
import { Behavior } from 'components/dashboardEditor/AddActionView'
import { useViewDispatch } from 'hooks/useViewContext'

const STABLE_EMPTY_ARRAY = Object.freeze([])

const DataListBlockWrapper: React.FC<
  BlockProps & Partial<DataTableBlockProps>
> = ({ block, switcher, containerId, ...blockProps }) => {
  const { operationState, updateBlockProperties, blockPropertiesState } = useDashboard()
  const { operations = STABLE_EMPTY_ARRAY, properties, layout, identifier, actions } = block

  const blockProperties = useRecoilValue(blockPropertiesState)
  const currentBlockProperties = blockProperties[identifier] || {}

  const {
    data_source_settings: dataSourceSettings = {},
    heading: title,
    selection_mode: selectionMode = 'SINGLE',
    primary_value: primaryValue,
    secondary_value: secondaryValue,
    enableCrud,
    enableExport,
    enableFilter,
    search,
    pagination,
    ...rest
  } = properties

  const selectionHandlerRef = React.useRef<any>()

  useEffect(() => {
    if (!blockProperties[identifier]?.currentRow) return
    selectionHandlerRef.current.selectRow([
      blockProperties[identifier].currentRow.id
    ])
  }, [ blockProperties, identifier, selectionMode ])

  const toolbarActions = actions?.filter((action: any) => action.kind === 'TOOLBAR') || []
  const rowActions = actions?.filter((action: any) => action.kind === 'ROW') || []
  const enableSearch = search?.is_enabled
  const enablePagination = pagination?.is_enabled

  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: pagination?.per_page_count
  })

  const {
    resource: resourceId,
    operation: operationId,
    parameters: paramProperties
  } = dataSourceSettings

  const { width } = layout || {}

  const isOperationMode = !!operationId

  const canPaginate = operations.findIndex(
    (operation: Operation) => operation.behaviorMethod === 'AGGREGATE'
  ) !== -1 && enablePagination

  const listOperation = operations.find(
    (operation: Operation) => operation.behaviorMethod === 'LIST'
  )
  const aggregateOperation = operations.find(
    (operation: Operation) => operation.behaviorMethod === 'AGGREGATE'
  )

  const listOperationData: Operation = useRecoilValue(
    operationState(listOperation?.identifier)
  )
  const aggregateOperationData: Operation = useRecoilValue(
    operationState(aggregateOperation?.identifier)
  )

  const handleRowSelect: RowSelectionFn = (record, isSelected) => {
    let updatedBlockProperties

    if (selectionMode === 'SINGLE') {
      updatedBlockProperties = {
        [identifier]: {
          ...currentBlockProperties,
          currentRow: isSelected ? record : null
        }
      }
    }

    if (selectionMode === 'MULTIPLE') {
      const currentSelectedRows = currentBlockProperties?.selectedRows || []

      updatedBlockProperties = {
        [identifier]: {
          ...currentBlockProperties,
          selectedRows: isSelected
            ? currentSelectedRows.concat(record)
            : currentSelectedRows.filter((r: any) => r.id !== record.id)
        }
      }
    }

    if (updatedBlockProperties) updateBlockProperties(updatedBlockProperties)
  }

  const { data: { attribute: primaryAttribute } = {} } = schema.useAttributeQuery({
    variables: { id: primaryValue?.attribute },
    skip: !primaryValue?.attribute
  })

  const { data: { attribute: secondaryAttribute } = {} } = schema.useAttributeQuery({
    variables: { id: secondaryValue?.attribute },
    skip: !secondaryValue?.attribute
  })

  const primaryDataKey = isOperationMode
    ? primaryValue?.value?.replace('{{record.', '').replace('}}', '')
    : `data.${camelCase(primaryAttribute?.identifier)}.en_US`

  const secondaryDataKey = isOperationMode
    ? secondaryValue?.value?.replace('{{record.', '').replace('}}', '')
    : `data.${camelCase(secondaryAttribute?.identifier)}.en_US`

  const listContents: Content[] = [
    { dataKey: primaryDataKey, slot: 'primary' },
    { dataKey: secondaryDataKey, slot: 'secondary' }
  ]

  const attributes = [ primaryAttribute, secondaryAttribute ]

  const searchRecordsVariables: schema.InternalSearchRecordsQueryVariables = {
    input: {
      resourceId,
      limit: canPaginate ? 1000 : pageSize,
      page,
      targetEnvironment: switcher?.data.environment?.id
    }
  }

  const searchableAttributes: string[] = compact((attributes.map(
    (attr: any) => {
      if (!attr?.isArray
        && attr?.fieldType === FieldIdentifier.TEXT
      ) return camelCase(attr?.identifier)
      return ''
    }
  ) || []))

  const [ {
    data: { internalSearchRecords: searchRecords = STABLE_EMPTY_ARRAY } = {},
    loading,
    error
  }, onSearch ] = useSearch<
    schema.InternalSearchRecordsQuery, schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: searchRecordsVariables,
      skip: !resourceId || !attributes.length
    },
    keys: searchableAttributes
  })

  const {
    data: { internalSummarizeRecords: summarizeRecords } = {}
  } = schema.useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId,
        filter: searchRecordsVariables.input.filter,
        targetEnvironment: 'production'
      }
    },
    skip: !resourceId
  })

  const [ executeAction ] = schema.useExecuteMutationOperationMutation()
  const handleExecuteOperation = useSubmitHandler(executeAction)
  const { openView } = useViewDispatch()

  const secondaryElements = (
    <Flex gap={16} alignItems="center">
      {toolbarActions.map((action: any) => {
        switch (action.behavior) {
          case Behavior.RUN_OPERATION:
            const input = Object.fromEntries(
              Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                camelCase(key),
                JSONParseOr(parseAndRenderSync(value || '', blockProperties))
              ])
            )

            const handleRunOperationClick = () => handleExecuteOperation({
              arguments: input,
              operationId: action.operation,
              targetEnvironment: switcher?.data.environment?.id
            })

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.identifier}
                  disabled={Object.values(input).every((v) => !v)}
                  onClick={handleRunOperationClick}
                  size="small"
                />
              )
            }

            return (
              <IconButton
                variant="darker"
                disabled={Object.values(input).every((v) => !v)}
                name={action.identifier}
                description={action.name}
                onClick={handleRunOperationClick}
                size={24}
              />
            )

          case Behavior.REDIRECT:
            const url = parseAndRenderSync(action.url, {
              ...blockProperties,
              environment: switcher?.data.environment?.id || ''
            })

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.identifier}
                  href={url}
                  target={action.target}
                  size="small"
                />
              )
            }

            return (
              <IconButton
                variant="darker"
                name={action.identifier}
                description={action.name}
                onClick={() => window.open(url, action.target, 'noopener noreferrer')}
                size={24}
              />
            )

          case Behavior.OPEN_VIEW:
            const handleOpenViewClick = () => {
              openView({
                component: GenericView,
                style: action.view_style,
                params: {
                  viewUrn: `custom::${action.view_urn}`
                }
              })
            }

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.identifier}
                  size="small"
                  onClick={handleOpenViewClick}
                />
              )
            }

            return (
              <IconButton
                variant="darker"
                name={action.identifier}
                description={action.name}
                onClick={handleOpenViewClick}
                size={24}
              />
            )
          default:
            return null
        }
      })}
    </Flex>
  )
  const {
    data: { executeQueryOperation = STABLE_EMPTY_ARRAY } = {},
    loading: executeOperationLoading,
    error: executeOperationError
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: paramProperties,
    targetEnvironment: switcher?.data.environment?.id
  })

  const [ searchText, setSearch ] = useState('')

  const primaryElements = (
    <Flex gap={8}>
      {enableSearch && (
        <SearchBar
          placeholder="Search"
          loading={listOperationData.loading}
          onChange={(e) => {
            onSearch(e.target.value)
            setSearch(e.target.value)
          }}
        />
      )}
    </Flex>
  )

  const filteredOperationRecords = (
    Array.isArray(executeQueryOperation) ? executeQueryOperation : [ executeQueryOperation ]
  ).map((d: any) => ({
    ...d,
    id: d.id || get(d, primaryDataKey)
  }))
    .filter(
      (record: any) => get(record, primaryDataKey)
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
        || get(record, secondaryDataKey)
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
    )

  const paginationProps = {
    page,
    pageSize,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    paginationMode: enablePagination ? 'finite' : 'infinite',
    totalRows:
      (aggregateOperationData as any)?.[aggregateOperation?.identifier]
        ?.count
      || summarizeRecords?.count
      || filteredOperationRecords?.length
  }

  const paginatedOperationRecords = enablePagination
    ? filteredOperationRecords?.slice((page - 1) * pageSize, page * pageSize)
    : filteredOperationRecords

  return (
    <DataListBlock
      actions={rowActions?.map((action: any) => {
        const onClick = (record: any) => {
          if (action.behavior === Behavior.RUN_OPERATION) {
            const input = Object.fromEntries(
              Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                camelCase(key),
                JSONParseOr(
                  parseAndRenderSync(value || '', {
                    ...blockProperties,
                    record
                  })
                )
              ])
            )

            handleExecuteOperation({
              arguments: input,
              operationId: action.operation,
              targetEnvironment: switcher?.data.environment?.id
            })
          }

          if (action.behavior === Behavior.REDIRECT) {
            const url = parseAndRenderSync(action.url, {
              ...blockProperties,
              record,
              environment: switcher?.data.environment?.id || ''
            })

            window.open(url, action.target, 'noopener noreferrer')
          }
        }

        return {
          icon: action.identifier,
          title: action.name,
          isSecondary: action.is_secondary,
          onClick
        }
      })}
      primaryElements={primaryElements}
      secondaryElements={secondaryElements}
      contents={listContents}
      loading={listOperationData.loading || executeOperationLoading || loading}
      error={listOperationData.error || executeOperationError || error}
      data={
        (listOperationData as any)?.[listOperation?.identifier]
        || paginatedOperationRecords
        || (searchRecords as any[])
        || []
      }
      title={title}
      onRowSelect={handleRowSelect}
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      selectionMode={selectionMode?.toLowerCase()}
      width={width || { md: '100%' }}
      withPageControls={!containerId}
      selectionHandlerRef={selectionHandlerRef}
      {...((canPaginate || enablePagination) && paginationProps)}
      {...blockProps}
      {...rest}
    />
  )
}

export default DataListBlockWrapper
