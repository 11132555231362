import dayjs from 'dayjs'
import React, { useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'

import ContentVersionProvider from 'components/contentVersion/ContentVersionProvider'
import ContentModel, { ContentVersion, ContentVersionStatus } from 'models/Content'
import SlideTransition from 'components/slideTransition/SlideTransition'
import useActiveLocales from 'hooks/useActiveLocales'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useVersionPublishing from 'hooks/useVersionPublishing'
import VersionCompare from 'components/contentVersion/VersionCompare'
import VersionList from 'components/contentVersion/VersionList'
import { CustomContent, Field, useContentQuery, useContentVersionsListQuery, useRestoreContentVersionMutation } from 'generated/schema'
import type { ContentVersionContextValue } from 'components/contentVersion/ContentVersionProvider'
import type { ViewProps } from 'components/views'

dayjs.extend(relativeTime)

type Params = {
  contentId: string,
  fieldsList: Field[],
  isPublishingEnabled: boolean
}

function ContentVersionView({
  closeView,
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { contentId, fieldsList, isPublishingEnabled } = params

  const {
    data: { content } = {},
    loading: contentLoading,
    error: contentError
  } = useContentQuery({
    variables: {
      id: contentId
    }
    // fetchPolicy: 'cache-only'
  })

  const [ currentVersionId, setCurrentVersionId ] = useState<string>()
  const [ previousVersionId, setPreviousVersionId ] = useState<string>()

  const pager = usePager()
  const [ page, pageSize ] = pager

  const {
    data,
    loading: versionsLoading,
    error: versionsError
  } = useContentVersionsListQuery({
    variables: {
      filter: { contentId: { eq: contentId } },
      order: [ { createdAt: 'desc' } ],
      limit: pageSize,
      page
    }
  })

  const [ handlePublishing ] = useVersionPublishing()

  const {
    activeLocales,
    defaultLocale,
    error: localesError,
    loading: localesLoading
  } = useActiveLocales()

  const {
    contentVersionsList = [],
    contentVersionsAggregate
  } = data || {}

  const versionsCount = contentVersionsAggregate?.count || 0

  const getStatusOf = (versionId: string) => {
    if (isPublishingEnabled) {
      let status = ContentVersionStatus.OLDER_DRAFT

      if (content?.currentContentVersionId === versionId) {
        status = ContentVersionStatus.CURRENT_DRAFT
      }
      if (content?.publishedContentVersionId === versionId) {
        status = ContentVersionStatus.PUBLISHED
      }

      return status
    }

    let status = ContentVersionStatus.OLDER
    if (content?.currentContentVersionId === versionId) {
      status = ContentVersionStatus.CURRENT
    }

    return status
  }

  const startVersionIndex = versionsCount - ((page - 1) * pageSize)
  const versions = contentVersionsList.map((version, index) => ({
    ...version,
    index,
    name: `Version ${startVersionIndex - index}`,
    status: getStatusOf(version.id)
  }))

  const currentVersion = versions.find((version) => version.id === currentVersionId)
  const previousVersion = versions.find((version) => version.id === previousVersionId)

  const [ restoreContentVersion ] = useRestoreContentVersionMutation({
    onCompleted: onRequestClose
  })

  const handleRestoreContentVersion = useSubmitHandler(restoreContentVersion, {
    successAlert: {
      message: `Successfully restored to ${currentVersion?.name}`
    }
  })

  const onRestoreContent = (id: string) => handleRestoreContentVersion({ id })

  const onSelectContent = (version: ContentVersion) => {
    const versionIndex = versions.findIndex((v) => v.id === version.id)
    const currentVersionIndex = versions.findIndex((v) => v.id === content?.currentContentVersionId)
    setCurrentVersionId(version.id)

    if (currentVersionIndex !== -1 && currentVersionIndex !== versionIndex) {
      setPreviousVersionId(versions[currentVersionIndex].id)
    } else if (versionIndex + 1 < versions.length) {
      setPreviousVersionId(versions[versionIndex + 1].id)
    }
  }

  const contextValue: ContentVersionContextValue = {
    activeLocales,
    defaultLocale: defaultLocale?.identifier || ContentModel.fallbackLocale,
    content: (content as CustomContent)!,
    fieldsList,
    handlePublishing,
    isPublishingEnabled,
    currentVersion,
    previousVersion,
    setCurrentVersionId,
    setPreviousVersionId,
    versions,
    versionsCount,
    versionsListPagerProps: pager
  }

  return (
    <View
      contentLabel="Version History"
      onRequestClose={onRequestClose}
      {...other}
    >
      {(viewProps) => (
        <ContentVersionProvider {...contextValue}>
          <SlideTransition activeIndex={currentVersion ? 1 : 0}>
            <VersionList
              error={versionsError || localesError || contentError}
              loading={versionsLoading || localesLoading || contentLoading}
              onRequestClose={onRequestClose}
              onSelect={onSelectContent}
              {...viewProps}
            />
            <VersionCompare
              onRequestClose={onRequestClose}
              onRestoreContent={onRestoreContent}
              {...viewProps}
            />
          </SlideTransition>
        </ContentVersionProvider>
      )}
    </View>
  )
}

export default ContentVersionView
