import sortBy from 'lodash/sortBy'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'

import { OrderableData, reorderData } from './useReorder'

type UseInfinitePagerProps<T> = {
  data?: readonly T[],
  sortKey?: string,
  page: number,
  pageSize: number,
  totalSize: number,
  onReorder?: (...params: any) => any
}

function useInfinitePager<T extends OrderableData>({
  data = [],
  sortKey,
  page,
  pageSize,
  totalSize,
  onReorder
}: UseInfinitePagerProps<T>) {
  const [ dataList, setDataList ] = useState<T[]>(() => [ ...data ])

  useEffect(() => {
    setDataList((currentList) => {
      if (!data.length) { return currentList }
      const newDataList = new Array(totalSize)
        .fill(null)
        .map((_, index) => currentList[index])

      const sortedData = sortKey ? sortBy(data, sortKey) : data
      for (let i = 0; i < pageSize; i++) {
        newDataList[i + (page - 1) * pageSize] = sortedData[i]
      }

      return newDataList
    })
  }, [ data, page, pageSize, sortKey, totalSize ])

  const reorder = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) { return }

    const startIndex = result.source.index
    const endIndex = result.destination?.index!

    const sortedData = reorderData(startIndex, endIndex, dataList)

    setDataList(sortedData)

    const { id, position } = sortedData[endIndex]
    onReorder?.({ id, position })
  }

  return [ dataList, reorder ] as const
}

export default useInfinitePager
