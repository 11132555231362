import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useField } from 'react-final-form'
import type { FieldRenderProps } from 'react-final-form'

import JsonField from 'components/contentEditors/generic/fields/JsonField'
import reportError from 'lib/reportError'
import Text from 'components/typography/Text'
import {
  CheckboxField,
  ColorField,
  DateTimeField,
  DropdownField,
  DurationField,
  EmailField,
  EmbeddedField,
  MarkdownField,
  MediaField,
  NumberField,
  PasswordField,
  RadioField,
  ReferenceField,
  RepeatedField,
  SwitchField,
  TextField
} from 'components/contentEditors/generic/fields'
import FieldModel, { FieldIdentifier } from 'models/Field'
import type { FieldsListQuery, Installation } from 'generated/schema'
import type { Locale } from 'hooks/useActiveLocales'

type Field = FieldsListQuery['fieldsList'][number]

type GenericContentEditorProps = {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  fieldPrefix?: string,
  fieldsList: FieldsListQuery['fieldsList'],
  installationId?: Installation['id']
}

type RenderFieldOptions = {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  fieldName?: string,
  fieldPrefix?: string,
  isFirstField?: boolean,
  installationId?: Installation['id']

}

type FieldNameProps = {
  fieldPrefix?: string,
  currentLocale?: Locale,
  defaultLocale?: Locale,
  fieldName?: Field['name'],
  id: Field['id'],
  isTranslatable: boolean
}

const getFieldName = ({
  currentLocale: currentLocaleData,
  defaultLocale: defaultLocaleData,
  fieldName,
  fieldPrefix,
  id,
  isTranslatable
}: FieldNameProps) => {
  const currentLocale = currentLocaleData?.identifier || ''
  const defaultLocale = defaultLocaleData?.identifier || ''
  const computedLocale = isTranslatable ? currentLocale : defaultLocale
  const currentLocaleString = computedLocale ? `.${computedLocale}` : ''

  const prefix = fieldPrefix ? `${fieldPrefix}.` : ''
  const suffix = fieldName || `data.${id}${currentLocaleString}`

  return `${prefix}${suffix}`
}

const isProduction = process.env.NODE_ENV === 'production'

const getCommonProps = (
  fieldName: string,
  {
    contentTypeField,
    options,
    formField
  } : {
    contentTypeField: Field,
    options?: RenderFieldOptions,
    formField?: FieldRenderProps<any, HTMLElement>
  }
) => {
  const {
    currentLocale,
    isFirstField,
    installationId
  } = options || {}

  const {
    defaultValue,
    identifier,
    name,
    settings,
    isTranslatable
  } = contentTypeField

  return {
    alwaysDirty: true,
    autoFocus: isFirstField,
    defaultValue: defaultValue ?? undefined,
    isTranslatable,
    installationId,
    key: identifier,
    name: fieldName,
    settings: { ...settings, hasFallbackLocale: currentLocale?.allowFallback, helpText: (!isProduction ? `${settings.helpText ?? ''}\n\`${JSON.stringify(formField?.input.value)}\`` : settings.helpText) },
    size: 'small',
    label: name,
    shouldValidate: true
  }
}

const FieldComponent = ({ field, options }: { field: Field, options?: RenderFieldOptions }) => {
  const { fieldType } = field || {}
  const name = getFieldName({
    ...field,
    ...options
  })

  const formField = useField(name, { subscription: { value: !isProduction } })

  const commonProps = getCommonProps(name, { contentTypeField: field, options, formField })

  switch (fieldType) {
    case FieldIdentifier.DROPDOWN:
      return (
        <DropdownField
          {...commonProps}
          field={field}
          isArray={field.isArray}
          isClearable
          metaKey="value"
        />
      )

    case FieldIdentifier.MEDIA:
    case FieldIdentifier.FILE:
      return (
        <MediaField
          {...commonProps}
          setToUndefinedOnDelete
          isArray={field.isArray}
        />
      )

    case FieldIdentifier.EMBEDDED:
      return (
        <EmbeddedField
          {...commonProps}
          defaultLocale={options?.defaultLocale}
          currentLocale={options?.currentLocale}
          fieldRestrictions={field.fieldRestrictions}
          isArray={field.isArray}
        />
      )

    case FieldIdentifier.REFERENCE:
      return (
        <ReferenceField
          field={field}
          restrictions={field.fieldRestrictions.map((f) => f.contentType)}
          currentLocale={options?.currentLocale?.identifier}
          {...commonProps}
        />
      )

    case FieldIdentifier.CHECKBOX:
      return <CheckboxField {...commonProps} />

    case FieldIdentifier.COLOR:
      return <ColorField {...commonProps} />

    case FieldIdentifier.DATE:
      return (
        <DateTimeField
          {...commonProps}
          isNullable={!field.settings?.checkRequired}
        />
      )

    case FieldIdentifier.EMAIL:
      return <EmailField {...commonProps} />

    case FieldIdentifier.JSON:
      return <JsonField {...commonProps} />

    case FieldIdentifier.MARKDOWN:
      return <MarkdownField {...commonProps} />

    case FieldIdentifier.NUMBER:
      return <NumberField {...commonProps} />

    case FieldIdentifier.PASSWORD:
      return <PasswordField {...commonProps} />

    case FieldIdentifier.RADIO:
      return <RadioField {...commonProps} />

    case FieldIdentifier.SWITCH:
      return <SwitchField {...commonProps} />

    case FieldIdentifier.TEXT:
      return <TextField {...commonProps} />

    case FieldIdentifier.DURATION:
      return <DurationField {...commonProps} />

    default:
      return null
  }
}

const renderField = (field: Partial<Field>, options?: RenderFieldOptions) => (
  <FieldComponent field={field as Field} options={options} />
)

const ErrorFallback = () => (
  <Text>
    An unexpected error occurred, our engineering team is looking into it
  </Text>
)

const GenericContentEditor = ({
  fieldsList, fieldPrefix, currentLocale, defaultLocale, installationId
}: GenericContentEditorProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
    {fieldsList.map((field, index) => {
      if (field.isArray
          && !FieldModel.hasCustomRepeatedBehavior(field.fieldType as FieldIdentifier)) {
        return (
          <RepeatedField
            key={field.id}
            field={field}
            name={getFieldName({
              fieldPrefix,
              isTranslatable: field.isTranslatable,
              currentLocale,
              defaultLocale,
              id: field.id
            })}
            shouldValidate
            renderField={renderField}
          />
        )
      }

      return (
        <FieldComponent
          key={field.id}
          field={field}
          options={{
            fieldPrefix,
            currentLocale,
            defaultLocale,
            isFirstField: index === 0,
            installationId
          }}
        />
      )
    })}
  </ErrorBoundary>
)

export { renderField }

export type { Field }

export default GenericContentEditor
