import React, { useCallback, useRef } from 'react'
import { number, NumberSchema } from 'yup'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Icon from 'components/icons/Icon'
import isNullOrUndefined from 'lib/isNullOrUndefined'
import TextInput from 'components/inputs/TextInput'
import { fieldProps, NUMBER_MODE, NUMBER_RANGE } from 'components/contentEditors/generic/fields/fieldProps'
import { styled } from 'styles/stitches'
import type { TextInputProps } from 'components/inputs/TextInput'

type NumberFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'number'>

const StyledFlex = styled(Flex, {
  '& [data-caret-icon]': {
    ...mixins.transition('fluid'),
    userSelect: 'none',
    cursor: 'pointer',
    color: 'dark100'
  },

  '&[data-disabled] [data-caret-icon]': {
    pointerEvents: 'none'
  },

  variants: {
    variant: {
      light: {
        '&:not([data-disabled]) [data-caret-icon]:hover': {
          color: 'dark300'
        },
        '&:not([data-disabled]) [data-caret-icon]:focus': {
          color: 'dark300'
        }
      },
      dark: {
        '&:not([data-disabled]) [data-caret-icon]:hover': {
          color: 'dark300'
        },
        '&:not([data-disabled]) [data-caret-icon]:focus': {
          color: 'dark300'
        }
      }
    }
  }
})

StyledFlex.defaultProps = {
  variant: 'light'
}

const getNumberFieldSchema = (settings: NumberFieldProps['settings'] = {}, isNullable: boolean, value: any) => {
  let schema: NumberSchema<number | null | undefined> = number().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) schema = schema.required()

  if (settings.mode === NUMBER_MODE.DECIMAL && settings.decimalPlaces) {
    schema = schema
      .test(
        'precision',
        `maximum ${settings.decimalPlaces} digits after decimal`,
        (val) => (String(val).split('.')[1]?.length || 0) <= (settings.decimalPlaces || 0)
      )
  }

  if (settings.checkValue) {
    const greaterThanOrEqualExists = !isNullOrUndefined(settings.valueGreaterThanOrEqualTo)
    const lessThanOrEqualExists = !isNullOrUndefined(settings.valueLessThanOrEqualTo)
    const greaterThanExists = !isNullOrUndefined(settings.valueGreaterThan)
    const lessThanExists = !isNullOrUndefined(settings.valueLessThan)
    const equalToExists = !isNullOrUndefined(settings.valueEqualTo)
    const notEqualToExists = !isNullOrUndefined(settings.valueNotEqualTo)

    if (!settings.checkRequired && isNullOrUndefined(value)) {
      return schema
    }

    switch (settings.valueComparator) {
      case NUMBER_RANGE.BETWEEN:
        if (greaterThanOrEqualExists && lessThanOrEqualExists) {
          schema = schema.between(
            settings.valueGreaterThanOrEqualTo!, settings.valueLessThanOrEqualTo!
          )
        }
        break

      case NUMBER_RANGE.NOT_BETWEEN:
        if (greaterThanOrEqualExists && lessThanOrEqualExists) {
          schema = schema.notBetween(
            settings.valueGreaterThanOrEqualTo!, settings.valueLessThanOrEqualTo!
          )
        }
        break

      case NUMBER_RANGE.EQUAL:
        if (equalToExists) {
          schema = schema.equalTo(settings.valueEqualTo!)
        }

        break

      case NUMBER_RANGE.NOT_EQUAL:
        if (notEqualToExists) {
          schema = schema.notEqualTo(settings.valueNotEqualTo!)
        }
        break

      case NUMBER_RANGE.GREATER_THAN:
        if (greaterThanExists) {
          schema = schema.moreThan(settings.valueGreaterThan!)
        }
        break

      case NUMBER_RANGE.LESS_THAN:
        if (lessThanExists) {
          schema = schema.lessThan(settings.valueLessThan!)
        }
        break

      case NUMBER_RANGE.GREATER_THAN_OR_EQUAL:
        if (greaterThanOrEqualExists) {
          schema = schema.min(settings.valueGreaterThanOrEqualTo!)
        }
        break

      case NUMBER_RANGE.LESS_THAN_OR_EQUAL:
        if (lessThanOrEqualExists) {
          schema = schema.max(settings.valueLessThanOrEqualTo!)
        }
        break

      default:
        break
    }
  }

  return schema
}

const NumberField = ({
  name,
  settings,
  isNullable = false,
  shouldValidate,
  ...others
}: NumberFieldProps) => {
  const validate = useCallback((value) => getNumberFieldSchema(settings, isNullable, value)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, isNullable ])

  const ref = useRef<HTMLInputElement>(null)

  return (
    <FormField
      component={undefined}
      name={name}
      type="number"
      parse={(x) => {
        if (x && !Number.isNaN(Number(x))) {
          if (settings?.saveFormat === 'string') {
            return Number(x).toString()
          }
          return Number(x)
        }
        return isNullable ? null : undefined
      }}
      render={({ input, meta }) => (
        <TextInput
          input={input}
          meta={meta}
          ref={ref}
          step={settings?.step || 1}
          max={settings?.max}
          min={settings?.min}
          type="number"
          {...({
            checkRequired: settings?.checkRequired,
            placeholder: settings?.placeholder,
            helpText: settings?.helpText
          })}
          appendNode={(
            <StyledFlex
              data-disabled={others.disabled}
              direction="column"
              justifyContent="center"
              variant={others.variant}
            >
              <Icon
                data-caret-icon
                name="caret-up"
                size={8}
                onClick={() => {
                  ref.current?.stepUp()
                  input?.onChange(ref.current?.value)
                }}
              />
              <Icon
                data-caret-icon
                name="caret-down"
                size={8}
                onClick={() => {
                  ref.current?.stepDown()
                  input?.onChange(ref.current?.value)
                }}
              />
            </StyledFlex>
        )}
          {...others}
        />
      )}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}

export type { NumberFieldProps }
export { getNumberFieldSchema }
export default NumberField
