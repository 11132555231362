import pluralize from 'pluralize'
import React, { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { string } from 'yup'
import { useForm, useField } from 'react-final-form'
import type { FieldInputProps, FieldMetaState } from 'react-final-form'

import Card from 'components/card/Card'
import ContentModel from 'models/Content'
import Disabler from 'components/disabler/Disabler'
import Divider from 'components/divider/Divider'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldError from 'components/form/FieldError'
import FieldWrapper from 'components/contentEditors/generic/fields/FieldWrapper'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import ReferenceContentView, { Action } from 'components/views/cms/ReferenceContentView'
import SimpleLoader from 'components/loaders/SimpleLoader'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import { styled } from 'styles/stitches'
import { useContentQuery, useFieldsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { FieldsListQuery } from 'generated/schema'

type Field = FieldsListQuery['fieldsList'][number]
type FieldData = { id: string }

type ReferenceInputProps = {
  placeholder?: string,
  helpText?: string,
  input: FieldInputProps<string, HTMLInputElement>,
  meta: FieldMetaState<any>,
  currentLocale?: string,
  removeField?: () => void
}

type ReferenceFieldProps = Omit<ReferenceInputProps, 'input' | 'meta'> & fieldProps<''> & {
  disabled?: boolean,
  field?: Partial<Field>,
  restrictions: Field['fieldRestrictions'][number]['contentType'][]
}

type ReferenceFieldContextProps = Pick<ReferenceFieldProps, 'field' | 'restrictions' | 'disabled'>
type ReferenceFieldProviderProps = PropsWithChildren<ReferenceFieldContextProps>

type ReferenceFieldActionsProps = {
  input?: ReferenceInputProps['input'],
  push?: (value: FieldData) => void,
  name: string
}

const ReferenceFieldContainer = styled(Flex, {
  position: 'relative',
  marginTop: 5,

  '& [data-name]': {
    color: 'dark500',
    marginBottom: 10
  }
})

const ReferenceFieldContext = createContext<ReferenceFieldContextProps>({ restrictions: [] })
ReferenceFieldContext.displayName = 'ReferenceFieldContext'

const DRAGGABLE_GAP = 2
const CONTENT_NOT_FOUND_MESSAGE = 'Could not fetch content'
const StyledDraggableWrapper = styled(Flex, {
  marginBottom: -1 * DRAGGABLE_GAP
})

const StyledCard = styled(Card, {
  height: 55,
  paddingX: 20,
  paddingY: 0
})

const StyledPlaceholder = styled(Text, {
  paddingX: 12,
  paddingY: 4,
  fontSize: 14,
  color: 'dark500'
})

function ReferenceInput({
  input, meta, helpText, placeholder, currentLocale, removeField
}: ReferenceInputProps) {
  const { openView } = useViewDispatch()
  const { field, restrictions } = useContext(ReferenceFieldContext)
  const error = FieldError.getError(meta)

  const referencedContentId = input.value
  const {
    data,
    loading: contentLoading
  } = useContentQuery({
    variables: { id: referencedContentId },
    skip: !referencedContentId,
    fetchPolicy: 'cache-first'
  })

  const content = data?.content
  const contentType = restrictions?.find((c) => c?.id === content?.contentTypeId)

  const {
    data: { fieldsList } = {},
    loading: fieldsLoading,
    error: fieldsError
  } = useFieldsListQuery({
    fetchPolicy: 'cache-first',
    skip: !contentType?.id,
    variables: {
      filter: {
        contentTypeId: { eq: contentType?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: FIELDS_LIST_LIMIT
    }
  })

  if ((contentLoading && !content) || (fieldsLoading && !fieldsList)) {
    return (
      <Card alignItems="center" width="100%">
        <SimpleLoader size="small" />
      </Card>
    )
  }

  if (fieldsError) {
    return (
      <Card alignItems="center" width="100%">
        There was an error
      </Card>
    )
  }

  const openEditReferenceContentView = () => {
    openView({
      title: 'Edit Reference Content',
      component: ReferenceContentView,
      style: 'PANEL',
      params: {
        action: Action.EDIT,
        restrictions,
        content,
        onAction: (contentId) => {
          input?.onChange(contentId)
        }
      }
    })
  }

  const cardActionProps = (input.value && content) && {
    onClick: openEditReferenceContentView
  }

  const clearField = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (removeField) removeField()
    else input.onChange(undefined)
  }

  const titlePrimary = contentType?.name
  const titleSecondary = ContentModel.resolveTitle(fieldsList || [], content?.data, contentType, {
    activeLocale: currentLocale
  })

  const renderContent = () => {
    if (input.value) {
      return (
        <StyledCard
          direction="row"
          width="100%"
          height={55}
          alignItems="center"
          justifyContent="space-between"
          gap={24}
          {...cardActionProps}
        >
          {content ? (
            <Flex gap={12} grow={1} style={{ width: 0 }}>
              <Text
                alignSelf="center"
                as="div"
                fontSize={14}
                fontWeight="bold"
                truncate
              >
                {titlePrimary}
              </Text>
              {titleSecondary && (
                <>
                  <Flex alignSelf="stretch">
                    <Divider orientation="vertical" />
                  </Flex>
                  <Text
                    alignSelf="center"
                    as="div"
                    fontSize={14}
                    fontWeight="regular"
                    truncate
                  >
                    {titleSecondary}
                  </Text>
                </>
              )}
            </Flex>
          ) : (
            <Text
              alignSelf="center"
              as={Flex}
              fontSize={14}
              color="dark500"
              alignItems="center"
              gap={8}
            >
              <Icon name="alert" />
              <span>{CONTENT_NOT_FOUND_MESSAGE}</span>
            </Text>
          )}
          <IconButton
            variant="dark"
            description="remove"
            name="trash"
            size={16}
            onClick={clearField}
          />
        </StyledCard>
      )
    }

    if (placeholder) {
      return (
        <StyledPlaceholder>
          {placeholder}
        </StyledPlaceholder>
      )
    }

    return null
  }

  return (
    <ReferenceFieldContainer grow={1} direction="column" gap={10}>
      {renderContent()}
      {!!error && <FieldError error={error} />}
      {!field?.isArray && (
        <>
          {helpText && <InputHelpText helpText={helpText} />}
          <ReferenceFieldActions name={input.name} input={input} />
        </>
      )}
    </ReferenceFieldContainer>
  )
}

function ReferenceFieldProvider({
  children,
  disabled,
  field,
  restrictions = []
}: ReferenceFieldProviderProps) {
  return (
    <ReferenceFieldContext.Provider value={{
      disabled,
      field,
      restrictions
    }}
    >
      {children}
    </ReferenceFieldContext.Provider>
  )
}

const getReferenceFieldSchema = (settings: ReferenceFieldProps['settings'] = {}) => {
  let schema = string()

  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const SingleReferenceField = ({
  name,
  shouldValidate,
  settings,
  ...others
}: ReferenceFieldProps) => {
  const { meta } = useField(name)
  const validate = useCallback((value) => ((getReferenceFieldSchema(settings)
    .validate(value)) as Promise<any>)
    .then(() => { })
    .catch((e) => e.message),
  [ settings ])

  return (
    <FormField
      name={name}
      component={ReferenceInput}
      meta={meta}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}
function ReferenceField(props: ReferenceFieldProps) {
  const {
    disabled = false,
    label,
    name,
    field,
    restrictions = [],
    isTranslatable,
    shouldValidate
  } = props

  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  const { change } = useForm()
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const isArray = field?.isArray

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <Flex
        alignItems="center"
        gap={14}
        grow={1}
      >
        <IconButton
          hideTooltip
          description="Drag handle"
          name="drag"
          variant="dark"
          size={12}
          {...provided.dragHandleProps}
        />
        <SingleReferenceField
          {...props}
          name={`${FieldArray.getFieldName(name, rubric.source.index)}.id`}
          removeField={() => {
            // if last field in repeated set to undefined rather than []
            if (fieldsRef.current?.fields.length === 1) {
              change(name, undefined)
            } else {
              fieldsRef.current?.fields.remove(rubric.source.index)
            }
          }}
        />
      </Flex>
    </div>
  )

  const renderField = () => {
    if (isArray) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            renderClone={renderDraggableChildren}
            isDropDisabled={disabled}
            droppableId={droppableId.current}
          >
            {(droppableProvided: DroppableProvided) => (
              <StyledDraggableWrapper
                direction="column"
                gap={DRAGGABLE_GAP}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={name}
                  fieldsRef={fieldsRef}
                  settings={field?.settings}
                  shouldValidate={shouldValidate}
                  uniqueBy="id"
                >
                  {({ keys }) => {
                    if (keys.length === 0) {
                      return (
                        <SingleReferenceField
                          {...props}
                          name={`${name}[0].id`}
                        />
                      )
                    }

                    return keys.map((key, index) => (
                      <Draggable
                        disableInteractiveElementBlocking
                        draggableId={key}
                        index={index}
                        key={key}
                      >
                        {renderDraggableChildren}
                      </Draggable>
                    ))
                  }}
                </FieldArray>

                {droppableProvided.placeholder}
                <div />
              </StyledDraggableWrapper>
            )}
          </Droppable>
        </DragDropContext>
      )
    }

    return (
      <SingleReferenceField
        {...props}
        name={`${name}.id`}
        removeField={() => change(name, undefined)}
      />
    )
  }

  return (
    <ReferenceFieldProvider
      disabled={disabled}
      field={field}
      restrictions={restrictions}
    >
      <FieldWrapper label={label} isTranslatable={isTranslatable}>
        <Disabler disabled={disabled}>
          {renderField()}
        </Disabler>
        {isArray && (
          <>
            {field?.settings?.helpText && <InputHelpText helpText={field.settings.helpText} />}
            <ReferenceFieldActions name={name} push={fieldsRef.current?.fields.push} />
          </>
        )}
      </FieldWrapper>
    </ReferenceFieldProvider>
  )
}

function ReferenceFieldActions({ input, push, name }: ReferenceFieldActionsProps) {
  const { openView } = useViewDispatch()
  const { disabled, field, restrictions } = useContext(ReferenceFieldContext)

  const isArray = field?.isArray

  if (disabled) {
    return null
  }

  const openPickReferenceContentView = () => {
    openView({
      title: 'Pick Reference Content',
      component: ReferenceContentView,
      style: 'PANEL',
      params: {
        restrictions,
        action: Action.PICK,
        isArray,
        onAction: (selection) => {
          if (isArray) {
            selection.forEach((c) => {
              push?.({ id: c })
            })
          } else {
            input?.onChange(selection[0])
          }
        }
      }
    })
  }

  const fieldName = pluralize.singular(field?.name || '')

  return (
    <TextLink
      fontSize={12}
      data-name
      as="button"
      type="button"
      variant="underlined"
      mode="subtle"
      alignSelf="flex-start"
      fontWeight="bold"
      onClick={openPickReferenceContentView}
      name={name}
    >
      {isArray ? `Add ${fieldName}...` : `Select ${fieldName}`}
    </TextLink>
  )
}

export default ReferenceField
