import get from 'lodash/get'
import React from 'react'

import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'dark800',
  fontWeight: 600
})

// Please fix the logic once backed supports dueData or billingDate

function InvoiceNameRenderer({ rowData }: RendererOptions) {
  const createdAt = new Date(get(rowData, 'createdAt'))
  const previousMonth = new Date(createdAt.getFullYear(), createdAt.getMonth() - 1)
  // invoice name is createdAt month - 1 and should be in format of January 2021
  const invoiceName = `${previousMonth.toLocaleString('default', { month: 'long' })} ${previousMonth.getFullYear()}`

  return <StyledText title={invoiceName}>{invoiceName}</StyledText>
}

export default InvoiceNameRenderer
