import get from 'lodash/get'
import React, { useContext, useEffect } from 'react'
import { FormSpy } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import AddMenuHeaderView from './AddMenuHeaderView'
import AddMenuLinkView from './AddMenuLinkView'
import AddMenuMainView from './AddMenuMainView'
import AddMenuSeparatorView from './AddMenuSeparatorView'
import AddSubmenuView from './AddSubmenuView'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import InternalContext from 'components/contexts/InternalContext'
import useDashboard from 'hooks/useDashboard'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { parseAndRenderSync } from 'lib/templater'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { ViewParams, Views } from '../constants'

type FormValues = ComputedMenuElement
type Params = ViewParams[Views.ADD_MENU_ELEMENT]

const getSubtitle = ({ id, kind, target }: Pick<ComputedMenuElement, 'id' | 'kind' | 'target'>) => ({
  ITEM_VIEW: id ? 'Edit View Menu' : 'Add View Menu',
  ITEM_SUBMENU: id ? 'Edit Submenu' : 'Add Submenu',
  ITEM_URL: id ? 'Edit External Link' : 'Add External Link',
  GROUP: id ? 'Edit Header' : 'Add Header',
  SEPARATOR: id ? 'Edit Separator' : 'Add Separator'
}[[ kind, target ].filter(Boolean).join('_')])

const LiveMenuEditorOrchestrator = () => {
  const { selectMenu, selectedMenuState } = useDashboard()
  const selectedMenu = useRecoilValue(selectedMenuState)
  const { currentDashboard } = useContext(InternalContext) || {}
  const { currentWorkspace } = useContext(WorkspaceContext) || {}
  useEffect(() => () => selectMenu(null), [ selectMenu ])

  return (
    <FormSpy
      onChange={({ values, valid }) => {
        if (!valid || !values || !values.id) {
          return
        }

        const fieldsArray = Object.keys(values)

        if (fieldsArray.some((field) => get(selectedMenu, field) !== get(values, field))) {
          selectMenu({
            ...selectedMenu,
            ...values,
            renderedName: parseAndRenderSync(values.name || selectedMenu?.name || '', {
              _dashboard: currentDashboard,
              _workspace: currentWorkspace
            })
          } as ComputedMenuElement)
        }
      }}
    />
  )
}

// TODO: Refactor AddMenuElementView to combine all the views into one
function AddMenuElementView({ onClose }: ActiveViewProps) {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {}, parentView } = params! as Params
  const { id, kind, target } = initialValues as FormValues

  return (
    <>
      <DashboardEditorHeader
        subtitle={getSubtitle({ id, kind, target })}
        heading={parentView ? 'Dashboard Editor' : 'Add Menu'}
        onClose={onClose}
      />
      {kind === 'ITEM' && target === 'VIEW' && <AddMenuMainView />}
      {kind === 'ITEM' && target === 'SUBMENU' && <AddSubmenuView />}
      {kind === 'ITEM' && target === 'URL' && <AddMenuLinkView />}
      {kind === 'GROUP' && <AddMenuHeaderView />}
      {kind === 'SEPARATOR' && <AddMenuSeparatorView />}
    </>
  )
}

export { LiveMenuEditorOrchestrator }

export default AddMenuElementView
